import * as BaseApi from "./Base.api";

export const getBlockedInfo = async (param: any) => {
    const url = "/user/block";
    return await BaseApi.get(url, param);
};

export const createBlockInfo = async (params: any) => {
    const url = "/user/block";
    return await BaseApi.post(url, params);
};

export const deleteBlockInfo = async (params: any) => {
    const url = "/user/block";
    return await BaseApi.remove(url, params);
};
