import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import * as AuthApi from "api/common/Auth.api"  

import UpdatePasswordComponent from "./UpdatePassword.component";

const UpdatePasswordContainer = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isCheckedPassword, setIsCheckedPassword] = useState(false);

  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [isButtonDiabled, setIsButtonDiabled] = useState(false);

  const updatePassword = async () => {
    setIsButtonDiabled(true)
    const result = await AuthApi.updatePassword({ password, confirmedPassword });

    if (result) {
      navigate(location.pathname.replace("/update-password", ""));
    };

    setIsButtonDiabled(false)
  };

  return (
    <UpdatePasswordComponent
      isCheckedPassword={isCheckedPassword}
      setIsCheckedPassword={setIsCheckedPassword}
      password={password}
      setPassword={setPassword}
      confirmedPassword={confirmedPassword}
      setConfirmedPassword={setConfirmedPassword}
      onClickBtnUpdatePassword={updatePassword}
      isButtonDiabled={isButtonDiabled}
    />
  );
};

export default UpdatePasswordContainer;