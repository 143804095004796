import { getNewAccessToken } from "api/common/Auth.api";
import { getCookie, setCookie, removeCookie } from "./Cookie.helper";

const accessTokenKey: string = process.env.REACT_APP_COOKIE_JWT_TOKEN_KEY ?? "";
const refreshTokenKey: string = process.env.REACT_APP_COOKIE_JWT_REFRESH_TOKEN_KEY ?? "";
const agencyTokenKey: string = process.env.REACT_APP_COOKIE_AGENCY_TOKEN_KEY ?? "";

export const getAccessToken = (): string => {
    return getCookie(accessTokenKey);
};

export const getRefreshToken = (): string => {
    return getCookie(refreshTokenKey);
};

export const setAccessToken = (accessToken: string): void => {
    setCookie(accessTokenKey, accessToken, null);
};

export const setRefreshToken = (refreshToken: string): void => {
    setCookie(refreshTokenKey, refreshToken, null);
};

export const removeAuthTokens = (): void => {
    removeCookie(accessTokenKey);
    removeCookie(refreshTokenKey);
};

export const getAgencyId = (): string => {
    return getCookie(agencyTokenKey);
};

export const setAgencyId = (agencyId: string) => {
    setCookie(agencyTokenKey, agencyId, null);
};

export const removeAgencyId = (): void => {
    removeCookie(agencyTokenKey);
};

export const getNewAccessTokenValue = async () => {
    const refreshTokenParams = {
        accessToken: getAccessToken(),
        refreshToken: getRefreshToken(),
    };

    const { data }: any = await getNewAccessToken(refreshTokenParams);

    const { jwtToken, refreshToken } = data.data;

    console.log("Access token refreshed successfully");

    // 새로운 토큰 저장
    setAccessToken(jwtToken);
    setRefreshToken(refreshToken);

    return jwtToken;
}
