import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStudentInfo } from "api/school/School.api";
import { getCurrentLangName } from "helpers/String.helper";
import { getFormattedDateValue } from "helpers/Datetime.helper";
import { getUserType } from "helpers/Common.helper";
import { PAGE_TYPE } from "enums";
import { getLanguageValue } from "locales/i18n";

const StudentInfoPopupComponent = (props: any) => {
  const { setIsShowStudentInfoPopup, selectedStudnetId } = props;
  const [info, setInfo] = useState(null);

  const { info: userInfo } = useSelector((state: any) => state.user);

  const renderStudentInfoPage = () => {
    const userType = getUserType(userInfo?.accountTypeCode);
    window.open(
      `${process.env.REACT_APP_DOMAIN}/${
        userType === PAGE_TYPE.AGENCY ? `agency/wannabe` : `school/student`
      }/${selectedStudnetId}`
    );
  };

  const getStudnetInfoValue = async () => {
    const result = await getStudentInfo(selectedStudnetId);
    if (result?.info) {
      setInfo(result.info);
    }
  };
  useEffect(() => {
    getStudnetInfoValue();
  }, []);
  return (
    <div className="popup popup-student-info">
      <div
        className="close-btn"
        onClick={(e: React.UIEvent<HTMLDivElement>) => {
          setIsShowStudentInfoPopup(false);
        }}></div>
      {info ? (
        <div className="con">
          <h2>
            {info?.firstName} {info?.lastName}
          </h2>
          <div className="info-wrap">
            <div className="wrap">
              <div className="student-img">
                <img
                  src={
                    info?.profileImageInfo
                      ? info?.profileImageInfo.url + "?w=500&h=588"
                      : "imgs/dummy/basic-img.png"
                  }
                  alt={`${info?.firstName} ${info?.lastName}`}
                />
              </div>
              <div className="student-info">
                <div className="info-1">
                  <p>{getCurrentLangName(info?.countryCodeInfo.names)}</p>
                  <p className="bold">
                    {info?.firstName} {info?.lastName}{" "}
                    <span>
                      ({getCurrentLangName(info?.genderTypeCodeInfo?.names)})
                    </span>
                  </p>
                  <p>{getFormattedDateValue(info?.birthday, "YYYY.MM.DD")}</p>
                  {info?.specialtyCodeInfoList
                    ? info?.specialtyCodeInfoList.map(
                        (item: any, index: number) => (
                          <p key={`lang-${index}`}>{getCurrentLangName(item?.names)}</p>
                        )
                      )
                    : null}
                </div>
                <div className="info-2">
                  {info?.height ? (
                    <p>
                      {getLanguageValue(
                        PAGE_TYPE.SCHOOL,
                        "studentDetail.txtHeight"
                      )}{" "}
                      : {info?.height}cm
                    </p>
                  ) : null}
                  {info?.weight ? (
                    <p>
                      {getLanguageValue(
                        PAGE_TYPE.SCHOOL,
                        "studentDetail.txtWeight"
                      )}{" "}
                      : {info?.weight}kg
                    </p>
                  ) : null}
                  {info?.shoeSize ? (
                    <p>
                      {getLanguageValue(
                        PAGE_TYPE.SCHOOL,
                        "studentDetail.txtShoeSize"
                      )}{" "}
                      : {info?.shoeSize}mm
                    </p>
                  ) : null}
                  {info?.bloodTypeCodeInfo ? (
                    <p>
                      {getLanguageValue(
                        PAGE_TYPE.SCHOOL,
                        "studentDetail.txtBloodType"
                      )}{" "}
                      : {getCurrentLangName(info?.bloodTypeCodeInfo.names)}
                    </p>
                  ) : null}
                  {/* <!-- <p>email : washing@phillippines.com</p> --> */}
                </div>
              </div>
            </div>
            <div className="btn">
              <button onClick={renderStudentInfoPage}>
                {getLanguageValue(
                  PAGE_TYPE.AGENCY,
                  "studetInfoPopup.btnDetailInfo"
                )}
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default StudentInfoPopupComponent;
