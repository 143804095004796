import StudentInfoPopupComponent from "commons/popup/StudentInfoPopup.component";
import StudentSearchPopupComponent from "commons/popup/StudentSearchPopup.component";
import { PAGE_TYPE } from "enums";
import { getFormattedDateValue } from "helpers/Datetime.helper";
import { getLanguageValue } from "locales/i18n";
import LoadingComponent from "commons/loading/Loading.component";
import React from "react";
import { censorText, isBadWord, isFWord } from "helpers/String.helper";

const MessageDetailComponent = (props: any) => {
    const {
        wsSubject,
        messages,
        userInfo,
        id,
        schoolName,
        studentName,
        navigate,
        chatRef,
        fileInputRef,
        messageReportReasonCode,
        onChangeMessageReportReasonCode,
        isOnNetworking,
        onClickBtnSave,
        reportPopupOpen,
        openReportPopup,
        closeReportPopup,
        messageReportReason,
        onChangeMessageReportReason,
        message,
        setMessage,
        sendMessage,
        handleFileChange,
        reportReason,
        handleButtonClick,
        handleScroll,
        closeChannelUser,
        setIsShowStudentInfoPopup,
        setSelectedStudnetId,
        isShowStudentInfoPopup,
        isShowStudentSearchPopup,
        setIsShowStudentSearchPopup,
        isShowLoading,
        isShowWarnPopup,
        setIsShowWarnPopup,
        isShowFeedbackPopup,
        closeFeedbackPopup,
        sendFeedback,
        feedbackMessage,
        setFeedbackMessage,
        messageStatusCode,
        isButtonDisabled,
        isShowUserInfoPopup,
        setIsShowUserInfoPopup,
        userImageUrl,
        isUserBlocked,
        isUserBlockedNetworking,
        clickBlockInfoButton,
        isUserBlockedLoading,
    } = props;

    const renderImageMessage = (message: any) => {
        try {
            const obj = JSON.parse(message);

            return (
                <>
                    <div
                        className="student-img"
                        style={{ cursor: "pointer" }}
                        onClick={(e: any) => {
                            e.preventDefault();
                            setIsShowStudentInfoPopup(true);
                            setSelectedStudnetId(obj.userStudentId);
                        }}>
                        <div
                            className="img"
                            style={{
                                backgroundImage: `url("${obj.imageUrl}?w=112&h=112")`,
                            }}></div>
                        <span className="text">{obj.userStudentName}</span>
                    </div>
                </>
            );
        } catch {
            return (
                <div className="student-img" style={{ cursor: "pointer" }}>
                    <div
                        className="img"
                        style={{
                            backgroundImage: `url("${message}?w=112&h=112")`,
                        }}></div>
                </div>
            );
        }
    };

    return (
        <main className="">
            <div className="school-message-detail">
                <div className="con">
                    <div className="btn-wrap">
                        <div
                            className="back-btn"
                            onClick={() => {
                                navigate("/agency/message");
                            }}></div>
                        <div className="btn">
                            <button className="report" onClick={openReportPopup}>
                                <img src="./imgs/icons/report.png" alt="report button" />
                                {getLanguageValue(PAGE_TYPE.AGENCY, "messageDetail.txtReport")}
                            </button>
                            {messageStatusCode && messageStatusCode !== "messageType:close" ? (
                                <button className="close" disabled={isButtonDisabled} onClick={closeChannelUser}>
                                    <img src="./imgs/icons/close.png" alt-="close button" />
                                    {getLanguageValue(PAGE_TYPE.AGENCY, "messageDetail.txtClose")}
                                </button>
                            ) : null}
                        </div>
                    </div>
                    <h2>
                        <a
                            href="#"
                            onClick={(e: any) => {
                                e.preventDefault();
                                setIsShowUserInfoPopup(true);
                            }}>
                            {schoolName ? schoolName : studentName ? studentName : null}
                        </a>
                    </h2>
                    <div className="chat-container">
                        <div className="chat-messages" ref={chatRef} onScroll={handleScroll}>
                            {isShowLoading ? (
                                <LoadingComponent />
                            ) : messages ? (
                                messages.map((message: any, index: number) => {
                                    const messageData = message;
                                    const userId = messageData.uid;
                                    const userType = messageData.userType;
                                    let text = message.message;
                                    const createdAt = message.createdAt ? message.createdAt : messageData.createdAt;
                                    const isSentByCurrentUser = parseInt(userId) === parseInt(userInfo.userAgencyId) && userType === userInfo.accountTypeCode;

                                    let isBadWordIncluded = false;
                                    let isFWordIncluded = false;

                                    if (isSentByCurrentUser && isBadWord(text)) {
                                        isBadWordIncluded = true;
                                    }

                                    if (isFWord(text)) {
                                        text = censorText(text);
                                        if (isSentByCurrentUser) {
                                            isFWordIncluded = true;
                                        }
                                    }

                                    if (message.messageType === "close") {
                                        wsSubject.current?.unsubscribe();
                                    }
                                    return (
                                        <div
                                            key={index}
                                            className={`message ${isSentByCurrentUser ? "sent" : "received"} ${
                                                isBadWordIncluded || isFWordIncluded ? "warning" : ""
                                            }`}>
                                            {message.messageType === "image" ? (
                                                renderImageMessage(text)
                                            ) : (
                                                <>
                                                    <span className="text">{text}</span>
                                                </>
                                            )}
                                            <p className="date">{getFormattedDateValue(createdAt, "YYYY.MM.DD HH:mm")}</p>
                                            {isFWordIncluded || isBadWordIncluded ? (
                                                <>
                                                    <br />
                                                    <p className="date">
                                                        {getLanguageValue(
                                                            PAGE_TYPE.AGENCY,
                                                            `messageDetail.${isFWordIncluded ? "txtFWordWarning" : "txtWarning"}`
                                                        )}
                                                    </p>
                                                </>
                                            ) : null}
                                        </div>
                                    );
                                })
                            ) : null}
                        </div>
                        <div className="chat-input">
                            <div className="wrap">
                                <input ref={fileInputRef} type="file" accept="image/*" onChange={handleFileChange} style={{ display: "none" }} />
                                <input
                                    placeholder={getLanguageValue(PAGE_TYPE.AGENCY, "messageDetail.txtTypeYourMessage")}
                                    className="message-input"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            if (message.trim() !== "") {
                                                const newMessage = {
                                                    channelId: id,
                                                    userId: userInfo.userAgencyId,
                                                    userType: userInfo.accountTypeCode,
                                                    messageType: "text",
                                                    message: message,
                                                    createdAt: new Date().toISOString(),
                                                };

                                                sendMessage(newMessage);
                                                setMessage("");
                                            }
                                        }
                                    }}
                                />
                                {schoolName ? (
                                    <button
                                        className="popup-btn"
                                        onClick={(e: any) => {
                                            e.preventDefault();
                                            setIsShowStudentSearchPopup(true);
                                        }}></button>
                                ) : null}

                                <button
                                    className="send-button"
                                    disabled={isButtonDisabled}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (message.trim() !== "") {
                                            const newMessage = {
                                                channelId: id,
                                                userId: userInfo.userAgencyId,
                                                userType: userInfo.accountTypeCode,
                                                messageType: "text",
                                                message: message,
                                                createdAt: new Date().toISOString(),
                                            };

                                            sendMessage(newMessage);
                                            setMessage("");
                                        }
                                    }}></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isShowStudentInfoPopup ? <StudentInfoPopupComponent {...props} /> : null}
            {isShowStudentSearchPopup ? <StudentSearchPopupComponent {...props} /> : null}

            {reportPopupOpen ? (
                <div className="popup popup-report-message">
                    <div className="con">
                        <div className="wrap">
                            <div className="close-btn" onClick={closeReportPopup}></div>
                            <h2>{getLanguageValue(PAGE_TYPE.AGENCY, "messageDetail.txtReport")}</h2>
                            <form>
                                {/* {reportReason.map((reason: any) => (
                                    <div className="input-box" key={reason.code}>
                                        <input
                                            type="radio"
                                            id={reason.code}
                                            name={reason.parentCode}
                                            value={reason.code}
                                            onChange={(evt) => onChangeMessageReportReasonCode(evt)}
                                            checked={reason.code === messageReportReasonCode}
                                        />
                                        <label htmlFor={reason.code}>{reason.names.en}</label>
                                    </div>
                                ))} */}
                                <div className="input-box"></div>
                                <textarea
                                    placeholder={getLanguageValue(PAGE_TYPE.AGENCY, "messageDetail.txtInputReason")}
                                    name="messageReportReason"
                                    value={messageReportReason}
                                    onChange={(evt) => onChangeMessageReportReason(evt)}></textarea>

                                <div className="btn">
                                    <button className="cancel" onClick={closeReportPopup}>
                                        {getLanguageValue(PAGE_TYPE.AGENCY, "messageDetail.txtCancel")}
                                    </button>
                                    <button className="reporting" disabled={isOnNetworking} onClick={onClickBtnSave}>
                                        {getLanguageValue(PAGE_TYPE.AGENCY, "messageDetail.txtReporting")}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            ) : null}

            {isShowFeedbackPopup ? (
                <div className="popup popup-report-message">
                    <div className="con">
                        <div className="wrap">
                            <h2>{getLanguageValue(PAGE_TYPE.AGENCY, "messageDetail.txtFeedback")}</h2>
                            <h3>{getLanguageValue(PAGE_TYPE.AGENCY, "messageDetail.txtFeedbackDesc")}</h3>
                            <form>
                                <textarea
                                    placeholder={getLanguageValue(PAGE_TYPE.AGENCY, "messageDetail.txtInputFeedback")}
                                    value={feedbackMessage}
                                    onChange={(evt) => setFeedbackMessage(evt.target.value)}></textarea>
                                <div className="btn">
                                    <button
                                        className="cancel"
                                        onClick={(e: any) => {
                                            e.preventDefault();
                                            closeFeedbackPopup();
                                        }}>
                                        {getLanguageValue(PAGE_TYPE.AGENCY, "messageDetail.txtClose")}
                                    </button>
                                    <button
                                        className="reporting"
                                        disabled={isOnNetworking}
                                        onClick={(e: any) => {
                                            e.preventDefault();
                                            sendFeedback();
                                        }}>
                                        {getLanguageValue(PAGE_TYPE.COMMON, "save")}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            ) : null}
            {isShowUserInfoPopup ? (
                <div className="popup popup-request-receive">
                    <div className="con">
                        <div className="wrap">
                            <div className="close-btn" onClick={() => setIsShowUserInfoPopup(false)}></div>
                            <h2>{schoolName ? schoolName : studentName ? studentName : null}</h2>
                            <div className="info agency-info">
                                <div className="item">
                                    <p className="date-time"></p>
                                    <h2 className="agency-name"></h2>
                                    <div
                                        className="wannabe-img"
                                        style={{
                                            backgroundImage: `url(${userImageUrl}?w=300&h=300)`,
                                        }}></div>
                                </div>
                            </div>
                            <div className="cancel-btn">
                                {!isUserBlockedLoading ? (
                                    <button
                                    className="w-large"
                                        onClick={() => {
                                            clickBlockInfoButton();
                                        }}
                                        disabled={isUserBlockedNetworking}>
                                        {isUserBlocked ? getLanguageValue(PAGE_TYPE.AGENCY, "messageDetail.txtUserUnBlock") : getLanguageValue(PAGE_TYPE.AGENCY, "messageDetail.txtUserBlock")}
                                    </button>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}

            {isShowWarnPopup ? (
                <div className="popup popup-report-message">
                    <div className="con">
                        <div className="wrap">
                            <div
                                className="close-btn"
                                onClick={(e: any) => {
                                    setIsShowWarnPopup(false);
                                }}></div>
                            <h2>{getLanguageValue(PAGE_TYPE.AGENCY, "messageDetail.txtWarnTitle")}</h2>
                            <form>
                                <div className="input-box">
                                    <p>
                                        {getLanguageValue(PAGE_TYPE.AGENCY, "messageDetail.txtWarnDesc1")}
                                        <br />
                                        <br />
                                        {getLanguageValue(PAGE_TYPE.AGENCY, "messageDetail.txtWarnDesc3")}
                                        <br />
                                        {getLanguageValue(PAGE_TYPE.AGENCY, "messageDetail.txtWarnDesc4")}
                                        <br />
                                        <br />
                                        {getLanguageValue(PAGE_TYPE.AGENCY, "messageDetail.txtWarnDesc5")}
                                        <br />
                                    </p>
                                </div>

                                <div className="btn">
                                    <button
                                        className="reporting"
                                        onClick={() => {
                                            setIsShowWarnPopup(false);
                                        }}>
                                        {getLanguageValue(PAGE_TYPE.AGENCY, "messageDetail.txtConfirm")}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            ) : null}
        </main>
    );
};

export default MessageDetailComponent;
