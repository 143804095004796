import { BAD_WORDS, F_WORDS } from "data/badWords.data";
import { getCurrentLanguage } from "locales/i18n";

// 세자리 마다 comma를 붙인 숫자 리턴
export const getNumberWithComma = (value: number | string) => {
    if (!value) {
        return value;
    }

    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getCurrentLangName = (value: any) => {
    if (!value) {
        return "";
    }
    const currenetLang = getCurrentLanguage();

    return value[currenetLang] ? value[currenetLang] : value["en"];
};

export const getEnName = (value: any) => (value["en"] ? value["en"] : "");

export const getOnlyNumberValue = (value: string) => value.replace(/[^0-9]/g, "");

export const isBadWord = (value: string) => {
    if (!value) {
        return false;
    }

    return BAD_WORDS.some((word: string) => value.toLowerCase().includes(word.toLowerCase()));
};

export const isFWord = (value: string) => {
    if (!value) {
        return false;
    }

    return F_WORDS.some((word: string) => value.toLowerCase().includes(word.toLowerCase()));
};

export function censorText(inputText: string) {
    if (!inputText) {
        return inputText
    }
    F_WORDS.forEach((word: string) => {
        const escapedWord = word.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // 정규식 특수문자 이스케이프
        const regex = new RegExp(escapedWord, "gi"); // 대소문자 구분 없이 치환
        inputText = inputText.replace(regex, "ㅁㅁㅁ"); // 금칙어를 'xxx'로 치환
    });
    return inputText;
}
