import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import * as ImageHelper from "helpers/Image.helper";

import * as AgencyApi from "api/agency/Agency.api";
import * as DataApi from "api/common/Data.api";

import UpdateMyComponent from "./UpdateMy.component";

const UpdateMyContainer = (props: any) => {
  const navigate = useNavigate();

  const { getInfo, agencyInfo, setAgencyInfo, updateType, setUpdateType } =
    props;

  const [businessCardFrontImageInfo, setBusinessCardFrontImageInfo] =
    useState<any>();
  const [businessCardBackImageInfo, setBusinessCardBackImageInfo] =
    useState<any>();
  const [agencyParams, setAgencyParams] = useState<any>({});

  const [isOnNetworking, setIsOnNetworking] = useState(false);

  const onChangeInput = (componentName: string, value: any) => {
    console.log("UpdateMyContainer:onChangeInput", componentName, value);

    // 기본 정보
    if (componentName === "name") {
      setAgencyParams({ ...agencyParams, name: value });
    } else if (componentName === "cellphone") {
      setAgencyParams({ ...agencyParams, cellphone: value });
    }
  };

  const updateInfo = async () => {
    const isDisabled =
      (updateType === "agency" || updateType === "caster") &&
      !(businessCardFrontImageInfo?.id && businessCardBackImageInfo?.id);

    if (isDisabled) {
      updateType === "agency"
        ? alert(getLanguageValue(PAGE_TYPE.AGENCY, "my.errLogoUpload"))
        : alert(getLanguageValue(PAGE_TYPE.AGENCY, "my.errCasterUpload"));

      return;
    }

    setIsOnNetworking(true)

    if (updateType === "agency") {
      agencyParams.businessCardFrontImageId = businessCardFrontImageInfo.id;
      agencyParams.businessCardBackImageId = businessCardBackImageInfo.id;
    }

    const result: any = await AgencyApi.updateInfo(agencyInfo.id, agencyParams);
    if (result.data.data) {
      setAgencyInfo({});
      getInfo();
      cancelUpdate();
    } else {
      alert(result.data.message);
    }
    
    setIsOnNetworking(false)
  };

  const cancelUpdate = () => {
    setUpdateType("");
    navigate("/agency/my");
  };

  const uploadImage = async (type: string, evt: any) => {
    setIsOnNetworking(true);

    if (!evt) {
      return false;
    }

    const file = evt.target.files[0];
    const imageInfo = await ImageHelper.getImageInfo(file);
    if (!imageInfo) {
      alert("Please choose image file");
      return false;
    }

    const result = await DataApi.uploadImage(file);
    if (type === "front") {
      setBusinessCardFrontImageInfo({ id: result.id, url: imageInfo.url });
    } else if (type === "back") {
      setBusinessCardBackImageInfo({ id: result.id, url: imageInfo.url });
    }

    setIsOnNetworking(false);
  };

  useEffect(() => {
    if (agencyInfo?.id) {
      if (updateType === "agency") {
        setBusinessCardFrontImageInfo(agencyInfo.businessCardFrontImageInfo);
        setBusinessCardBackImageInfo(agencyInfo.businessCardBackImageInfo);

        setAgencyParams({
          businessCardFrontImageId: agencyInfo.businessCardFrontImageInfo?.id,
          businessCardBackImageId: agencyInfo.businessCardBackImageInfo?.id,
          name: agencyInfo.name,
          cellphone: agencyInfo.cellphone,
        });
      }
    }
  }, [agencyInfo]);

  useEffect(() => {
    if (!agencyInfo.id) {
      getInfo();
    }
  }, []);

  return (
    <UpdateMyComponent
      id={agencyInfo.id}
      updateType={updateType}
      agencyParams={agencyParams}
      isOnNetworking={isOnNetworking}
      uploadImage={uploadImage}
      onChangeInput={onChangeInput}
      onClickBtnCancelUpdate={cancelUpdate}
      onClickBtnUpdateInfo={updateInfo}
      businessCardFrontImageInfo={businessCardFrontImageInfo}
      businessCardBackImageInfo={businessCardBackImageInfo}
    />
  );
};

export default UpdateMyContainer;
