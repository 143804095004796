import React, { useEffect, useRef, useState } from "react";
import StudentDetailComponent from "./StudentDetail.component";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getStudentInfo } from "api/school/School.api";
import { getListWithStudentId } from "api/school/Portfolio.api";
import { getCommonCodeListByParent } from "data/common.data";
import * as StudentApi from "api/student/Student.api";
import * as EvaluationApi from "../../../api/school/Evaluation.api";
import * as AuthApi from "api/common/Auth.api";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

const StudentDetailContainer = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [studentInfo, setStudentInfo] = useState<any>({});
  const page = location?.state ? location.state : 1;
  const [studentPortfolioList, setStudentPortfolioList] = useState([]);
  const [evaluationCategory, setEvaluationCategory] = useState([]);
  const [evaluationList, setEvaluationList] = useState([]);
  const categoryResult = getCommonCodeListByParent("evaluationCategory");
  const [initialSlideIdx, setInitialSlideIdx] = useState(0);
  const sliderRef = useRef<any>(null);
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [isStudentShowLoading, setIsStudentShowLoading] = useState(true);
  const [isPortfolioShowLoading, setIsPortfolioShowLoading] = useState(true);
  const [isRecentlyEvaluationShowLoading, setIsRecentlyEvaluationShowLoading] =
    useState(true);
  const pageParam = location?.state ? location.state.page : 1;
  const searchParam = location?.state ? location.state.search : "";
  const genderParam = location?.state ? location.state.gender : "";
  const verifiedParam = location?.state ? location.state.verified : true;

  const [isShowTaggedStudentPopup, setIsShowTaggedStudentPopup] =
    useState(false);
  const [taggedStudentPopupInfo, setTaggedStudentPopupInfo] = useState({});

  const getEvaluationListValues = async (id: number) => {
    const listResult = await EvaluationApi.getList({
      studentId: id,
    });

    if (listResult?.list) {
      setEvaluationList(listResult.list);
    }
    setIsRecentlyEvaluationShowLoading(false);
  };

  const getStudentInfoValues = async (id: number) => {
    const result = await getStudentInfo(id);
    if (result?.info) {
      setStudentInfo(result.info);
    }
    setIsStudentShowLoading(false);
    const portfolioResult = await getListWithStudentId(id);
    if (portfolioResult?.list) {
      setStudentPortfolioList(portfolioResult.list);
    }
    setIsPortfolioShowLoading(false);
  };

  const handleCertificationChange = async (isVerified: number) => {
    const updatedItem = {
      ...studentInfo,
      isVerified: isVerified === 1 ? 1 : 0,
    };
    setStudentInfo(updatedItem);

    await StudentApi.updateVerify(studentInfo.id, { isVerified });
  };

  const showImagePopup = (idx: number) => {
    setIsShowPopup(true);
    setInitialSlideIdx(idx);
  };

  const init = async () => {
    setEvaluationCategory(categoryResult);
    await getEvaluationListValues(id ? parseInt(id) : 0);
    await getStudentInfoValues(id ? parseInt(id) : 0);
  };

  const deleteAccount = async () => {
    if (
      window.confirm(
        getLanguageValue(PAGE_TYPE.SCHOOL, "studentDetail.txtConfirm")
      )
    ) {
      const result = await AuthApi.deleteAccountBySchool({
        userStudentId: id,
        accountTypeCode: "accountType:student",
      });

      if (result?.status === 200) {
        navigate("/school/student");
      }
    }
  };

  useEffect(() => {
    if (id) {
      init();
    } else {
      navigate("/school/student");
    }
  }, []);

  return (
    <StudentDetailComponent
      {...props}
      id={id}
      pageParam={pageParam}
      searchParam={searchParam}
      genderParam={genderParam}
      verifiedParam={verifiedParam}
      studentInfo={studentInfo}
      studentPortfolioList={studentPortfolioList}
      evaluationCategory={evaluationCategory}
      evaluationList={evaluationList}
      initialSlideIdx={initialSlideIdx}
      isShowPopup={isShowPopup}
      sliderRef={sliderRef}
      navigate={navigate}
      setIsShowPopup={setIsShowPopup}
      showImagePopup={showImagePopup}
      handleCertificationChange={handleCertificationChange}
      deleteAccount={deleteAccount}
      //
      isStudentShowLoading={isStudentShowLoading}
      isPortfolioShowLoading={isPortfolioShowLoading}
      isRecentlyEvaluationShowLoading={isRecentlyEvaluationShowLoading}
      //
      isShowTaggedStudentPopup={isShowTaggedStudentPopup}
      setIsShowTaggedStudentPopup={setIsShowTaggedStudentPopup}
      taggedStudentPopupInfo={taggedStudentPopupInfo}
      setTaggedStudentPopupInfo={setTaggedStudentPopupInfo}
      setStudentPortfolioList={setStudentPortfolioList}
    />
  );
};

export default StudentDetailContainer;
