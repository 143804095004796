import React, { useEffect, useState } from "react";
import StudentNewRegisterComponent from "./StudentNewRegister.component";
import * as AuthApi from "../../../api/common/Auth.api";
import { checkIsEmail } from "helpers/Reg.helper";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const StudentNewRegisterContainer = (props: any) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState(""); // 이메일 상태
  const { info: myInfo } = useSelector((state: any) => state.user);
  const { id, goToNextStep, studentInfo } = props;
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  const location = useLocation();
  const pageParam = location?.state ? location.state.page : 1;
  const searchParam = location?.state ? location.state.search : "";
  const genderParam = location?.state ? location.state.gender : "";
  const verifiedParam = location?.state ? location.state.verified : true;
  const handleSendLink = () => {
    setIsButtonDisabled(true)
    if (checkIsEmail(email)) {
      const username = email;
      const schoolId = myInfo.userSchoolId;
      AuthApi.sendEmailSignUp({ username, schoolId });
      setEmail("");
    }
    setIsButtonDisabled(false)
  };

  const handleDirectRegister = () => {
    goToNextStep();
  };

  const setStudentInfo = () => {
    setEmail(studentInfo.username);
  };
  useEffect(() => {
    if (id) {
      setStudentInfo();
    }
  }, []);

  return (
    <StudentNewRegisterComponent
      {...props}
      id={id}
      pageParam={pageParam}
      searchParam={searchParam}
      genderParam={genderParam}
      verifiedParam={verifiedParam}
      email={email}
      navigate={navigate}
      setEmail={setEmail}
      handleSendLink={handleSendLink}
      handleDirectRegister={handleDirectRegister}
      isButtonDisabled={isButtonDisabled}
    />
  );
};

export default StudentNewRegisterContainer;
