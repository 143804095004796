import React, { useEffect, useState, useRef } from "react";
import MessageDetailComponent from "./MessageDetailComponent";
import {
    getChannelMessages,
    getChannelRecentlyMessages,
    closeChannelByAgecny,
    createNotification,
    alarmBadWord,
    alarmFWord,
} from "../../../api/common/Chat.api";
import { createFeedbackInfo, createReportMessageInfo } from "../../../api/message/Message.api";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { WebSocketSubject } from "rxjs/webSocket";
import { getCommonCodeListByParent } from "../../../data/common.data";
import * as ImageHelper from "../../../helpers/Image.helper";
import * as DataApi from "../../../api/common/Data.api";
import { getAccessToken } from "helpers/Auth.helper";
import { PAGE_TYPE } from "enums";
import { getLanguageValue } from "locales/i18n";
import { getAgencyChannel } from "api/common/Chat.api";
import { isBadWord, isFWord } from "helpers/String.helper";
import { getBlockedInfo, createBlockInfo, deleteBlockInfo } from "api/User.api";

const MessageDetailContainer = (props: any) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { info: userInfo } = useSelector((state: any) => state.user);
    const { id } = useParams();
    const [schoolName, setSchoolName] = useState(location.state ? location.state.schoolName : null);
    const [studentName, setStudentName] = useState(location.state ? location.state.studentName : null);
    const { userStudentId } = location.state ? location.state : { userStudentId: false };
    const [userImageUrl, setUserImageUrl] = useState("");
    const limit: number = 30;
    const order: string = "desc";
    const [messages, setMessages] = useState([]);
    const [createdAt, setCreatedAt] = useState("");
    const [lastCreatedAt, setLastCreatedAt] = useState("");
    const [isPrev, setIsPrev] = useState(true);
    const [messageReportReasonCode, setMessageReportReasonCode] = useState("");
    const [messageReportReason, setMessageReportReason] = useState("");
    const [isOnNetworking, setIsOnNetworking] = useState(false);
    const [isOnNetworkingMore, setIsOnNetworkingMore] = useState(false);
    const [reportPopupOpen, setReportPopupOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [webSocketConnected, setWebSocketConnected] = useState(false);
    const wsSubject = useRef<WebSocketSubject<any>>();
    const [isShowStudentInfoPopup, setIsShowStudentInfoPopup] = useState<boolean>(false);
    const [isShowStudentSearchPopup, setIsShowStudentSearchPopup] = useState<boolean>(false);
    const [selectedStudnetId, setSelectedStudnetId] = useState<number>(0);
    const websocketUrl = `${process.env.REACT_APP_WS_URL}?channelId=${id}&userId=${userInfo.userAgencyId}&userType=${userInfo.accountTypeCode}`;
    const reportReason = getCommonCodeListByParent("messageReportReason");
    const fileInputRef = useRef<HTMLInputElement>(null);
    const chatRef = useRef(null);
    const firstMessageSent = useRef(false);
    const [isShowLoading, setIsShowLoading] = useState(true);
    const [isShowWarnPopup, setIsShowWarnPopup] = useState(false);
    const [isShowFeedbackPopup, setIsShowFeedbackPopup] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState("");
    const [messageStatusCode, setMessageStatusCode] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const [isError, setIsError] = useState(true);
    const [isShowUserInfoPopup, setIsShowUserInfoPopup] = useState(false);
    const [isUserBlocked, setIsUserBlocked] = useState(false);
    const [isUserBlockedNetworking, setIsUserBlockedNetworking] = useState(false);
    const [isUserBlockedLoading, setIsUserBlockedLoading] = useState(false);
    const [userBlockParam, setUserBlockParam] = useState({});

    const scrollToBottom = () => {
        if (chatRef.current) {
            const lastMessage = chatRef.current.lastElementChild;

            chatRef.current.scrollTop = lastMessage ? chatRef.current.scrollHeight + lastMessage.offsetHeight : chatRef.current.scrollHeight;
        }
    };

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = async (evt: any) => {
        const message = await uploadImageFile(evt);

        const newMessage = {
            channelId: id,
            userId: userInfo.userAgencyId,
            userType: userInfo.accountTypeCode,
            messageType: "image",
            message: JSON.stringify({
                userStudentId: userStudentId,
                imageUrl: `${process.env.REACT_APP_PUBLIC_RESOURCE_URL_PREFIX}${message}`,
            }),
            createdAt: new Date().toISOString(),
        };

        sendMessage(newMessage);
    };

    const sendMessage = async (message: any) => {
        setIsButtonDisabled(true);
        if (!wsSubject.current) {
            if (!isError) {
                await handleWebSocketReConnect();
            } else {
                setWebSocketConnected(false); // 웹소켓 연결이 끊어졌음을 표시
                return;
            }
        }

        // if (!firstMessageSent.current) {
        //     createNotification({ channelId: id });
        //     firstMessageSent.current = true;
        // }
        createNotification({ channelId: id });

        if (message?.messageType === "text") {
            // 전송된 메시지가 다른 채널 유도 단어가 포함된 경우
            if (isBadWord(message?.message)) {
                alarmBadWord({ channelId: id });
            }

            // 전송된 메시지가 불쾌한 단어가 포함된 경우
            if (isFWord(message?.message)) {
                alarmFWord({ channelId: id });
            }
        }

        wsSubject.current.next(message);
        setIsButtonDisabled(false);
    };

    const sendFirstMessage = async (message: any) => {
        if (!wsSubject.current) {
            if (!isError) {
                await handleWebSocketReConnect();
            } else {
                setWebSocketConnected(false); // 웹소켓 연결이 끊어졌음을 표시
                return;
            }
        }

        wsSubject.current.next(message);
    };

    const handleWebSocketConnect = () => {
        const protocols = ["myprotocol", process.env.REACT_APP_WS_ACCESS_KEY ?? "", process.env.REACT_APP_WS_SECRET_KEY ?? "", getAccessToken()];

        wsSubject.current = new WebSocketSubject({
            url: websocketUrl,
            protocol: protocols,
            openObserver: {
                next: () => {
                    console.log("WebSocket connection established.");
                    setWebSocketConnected(true);
                    setIsError(false);
                },
            },
            closeObserver: {
                next: () => {
                    console.log("WebSocket connection closed.");
                    // 연결 종료 시 상태 업데이트
                    wsSubject.current = null;
                    setWebSocketConnected(false);
                },
            },
        });

        // 웹소켓 메시지를 받았을 때 처리
        wsSubject.current.subscribe(
            (message: any) => {
                console.log("Received message:", message);
                setMessages((prevMessages: any) => [...prevMessages, message]);
                setTimeout(() => {
                    scrollToBottom();
                }, 100);
            },
            (error: any) => {
                console.error("WebSocket error:", error);
                setWebSocketConnected(false);
            }
        );
    };

    const handleWebSocketReConnect = async () => {
        setIsShowLoading(true);
        await loadRecentlyMessages();
        handleWebSocketConnect();
        setIsShowLoading(false);
    };

    const onClickBtnSave = async () => {
        if (!messageReportReason) {
            alert(getLanguageValue(PAGE_TYPE.AGENCY, "messageDetail.errEmptyReason"));
            return;
        }
        setIsOnNetworking(true);
        await doSave();
        setIsOnNetworking(false);
    };

    const doSave = async () => {
        console.log("channelId", id);
        console.log("messageReportReason", messageReportReason);
        console.log("messageReportReasonCode", messageReportReasonCode);
        if (window.confirm(getLanguageValue(PAGE_TYPE.AGENCY, "messageDetail.txtConfirmReport"))) {
            await createReportMessageInfo({
                channelId: id,
                messageReportReason,
                messageReportReasonCode: "messageReportReason:etc",
            });
            setMessageReportReasonCode("");
            closeReportPopup();
            await closeChannel();
            wsSubject.current?.unsubscribe();
            navigate("/agency/message");
        }
    };

    const sendStudentImageMessage = async (userStudentId: string, userStudentName: string, imageUrl: string) => {
        const newMessage = {
            channelId: id,
            userId: userInfo.userAgencyId,
            userType: userInfo.accountTypeCode,
            messageType: "image",
            message: JSON.stringify({
                userStudentId,
                userStudentName,
                imageUrl,
            }),
            createdAt: new Date().toISOString(),
        };

        sendMessage(newMessage);
    };

    const openReportPopup = () => {
        setReportPopupOpen(true);
    };

    const closeReportPopup = () => {
        setReportPopupOpen(false);
    };

    const onChangeMessageReportReasonCode = (evt: any) => {
        setMessageReportReasonCode(evt.target.value);
    };

    const onChangeMessageReportReason = (evt: any) => {
        setMessageReportReason(evt.target.value);
    };

    const uploadImageFile = async (e: any) => {
        const result = await DataApi.uploadImage(e.target.files[0]);

        if (result) {
            await DataApi.makeImageActivate(result?.id);
            await DataApi.getImageInfo(result?.id);
        }
        return result?.key;
    };

    const getChannelMessageValues = async () => {
        try {
            const result = await getChannelMessages(id, {
                limit,
                order,
                createdAt,
            });
            if (result?.list) {
                console.log(result.list.length);
                if (result.list.length === 0 || result.list.length < limit) {
                    setIsPrev(false);
                }
                setMessages((prevList: any) => [...result.list, ...prevList]);

                if (result.list.length > 0) {
                    setCreatedAt(result.list[0].createdAt);
                }
                if (messages[messages.length - 1]) {
                    setLastCreatedAt(messages[messages.length - 1].createdAt);
                } else {
                    if (result.list.length > 0) {
                        setLastCreatedAt(result.list[result.list.length - 1].createdAt);
                    }
                }
            }
        } catch (error) {
            console.error("Error fetching channel messages:", error);
        }
    };

    const getChannelMessageRecentlyValues = async () => {
        try {
            const result = await getChannelRecentlyMessages(id, {
                order,
                createdAt: lastCreatedAt,
            });
            if (result?.list) {
                setMessages((prevList: any) => [...prevList, ...result.list]);
                if (result.list[result.list.length - 1]) {
                    setLastCreatedAt(result.list[result.list.length - 1].createdAt);
                }
            }
        } catch (error) {
            console.error("Error fetching channel messages:", error);
        }
    };

    const loadMoreMessages = async () => {
        if (isPrev && !isOnNetworkingMore) {
            setIsOnNetworkingMore(true);
            await getChannelMessageValues();
            setIsOnNetworkingMore(false);
        }
    };

    const loadRecentlyMessages = async () => {
        await getChannelMessageRecentlyValues();
    };

    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const { scrollTop } = event.currentTarget;
        if (scrollTop === 0) {
            loadMoreMessages();
        }
    };

    const closeChannelUser = async () => {
        setIsButtonDisabled(true);
        if (window.confirm(getLanguageValue(PAGE_TYPE.AGENCY, "messageDetail.txtConfirmClose"))) {
            await closeChannel();

            // 피드백 팝업 노출
            setIsShowFeedbackPopup(true);

            //navigate("/agency/message");
        }
        setIsButtonDisabled(false);
    };

    const closeChannel = async () => {
        await closeChannelByAgecny(id);

        const newMessage = {
            channelId: id,
            userId: userInfo.userAgencyId,
            userType: userInfo.accountTypeCode,
            messageType: "close",
            message: getLanguageValue(PAGE_TYPE.AGENCY, "messageDetail.txtCloseMessage"),
            createdAt: new Date().toISOString(),
        };

        await sendFirstMessage(newMessage);
        setIsError(true);
    };

    const sendFeedback = async () => {
        if (!feedbackMessage) {
            alert(getLanguageValue(PAGE_TYPE.AGENCY, "messageDetail.errEmptyFeedback"));
            return;
        }

        setIsOnNetworking(true);

        await createFeedbackInfo({
            channelId: id,
            message: feedbackMessage,
        });

        setIsOnNetworking(false);
        closeFeedbackPopup();
    };

    const closeFeedbackPopup = () => {
        setIsShowFeedbackPopup(false);
        navigate("/agency/message");
    };

    const init = async () => {
        const channelResult = await getAgencyChannel(id);
        if (channelResult.requestInfo) {
            await getChannelMessageValues();

            console.log(channelResult.requestInfo);

            setIsShowWarnPopup(channelResult.requestInfo.isReadAgency ? false : true);
            setSchoolName(channelResult.requestInfo.schoolName);

            setStudentName(
                channelResult.requestInfo.userStudentNickName
                    ? channelResult.requestInfo.userStudentNickName
                    : `${channelResult.requestInfo.userStudentFirstName} ${channelResult.requestInfo.userStudentLastName}`
            );
            setMessageStatusCode(channelResult.requestInfo.messageStatusCode);

            if (channelResult.requestInfo.schoolName) {
                const imageUrl = channelResult.requestInfo.userSchoolLogo
                    ? `${process.env.REACT_APP_PUBLIC_RESOURCE_URL_PREFIX}${channelResult.requestInfo.userSchoolLogo}`
                    : "./imgs/dummy/basic-img-2.png";
                setUserImageUrl(imageUrl);
                setUserBlockParam({ blockedUserAccountId: channelResult.requestInfo.userSchoolId, accountTypeCode: "accountType:school" });
            } else {
                setUserImageUrl(`${process.env.REACT_APP_PUBLIC_RESOURCE_URL_PREFIX}${channelResult.requestInfo.userStudentProfileImage}`);
                setUserBlockParam({ blockedUserAccountId: channelResult.requestInfo.userStudentId, accountTypeCode: "accountType:student" });
            }
        } else {
            navigate("/agency/message");
        }

        setIsShowLoading(false);
        setTimeout(() => {
            scrollToBottom();
        }, 100);
    };

    const getUserBlockedInfo = async () => {
        const result = await getBlockedInfo(userBlockParam);

        if (result) {
            setIsUserBlocked(result?.result);
        }
    };

    const clickBlockInfoButton = async () => {
        setIsUserBlockedNetworking(true);

        if (isUserBlocked) {
            await deleteBlockInfo(userBlockParam);
        } else {
            if (messageStatusCode !== "messageType:close") {
                if (window.confirm(getLanguageValue(PAGE_TYPE.AGENCY, "messageDetail.txtConfirmBlock"))) {
                    await createBlockInfo(userBlockParam);
                    await closeChannel();
                    navigate("/agency/message");
                }
            } else {
                await createBlockInfo(userBlockParam);
            }
        }

        await getUserBlockedInfo();
        setIsUserBlockedNetworking(false);
    };

    useEffect(() => {
        init();
        handleWebSocketConnect();
        return () => {
            wsSubject.current?.unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (isShowUserInfoPopup) {
            setIsUserBlockedLoading(true);
            getUserBlockedInfo();
            setIsUserBlockedLoading(false);
        }
    }, [isShowUserInfoPopup]);

    useEffect(() => {
        const handleVisibilityChange = async () => {
            // foreground로 돌아 왔을 때 ws 재연결
            if (document.visibilityState === "visible") {
                if (!wsSubject.current) {
                    if (!isError) {
                        await handleWebSocketReConnect();
                    }
                }
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);

        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, []);

    return (
        <MessageDetailComponent
            id={id}
            isPrev={isPrev}
            chatRef={chatRef}
            wsSubject={wsSubject}
            message={message}
            messages={messages}
            userInfo={userInfo}
            schoolName={schoolName}
            studentName={studentName}
            fileInputRef={fileInputRef}
            reportReason={reportReason}
            isOnNetworking={isOnNetworking}
            reportPopupOpen={reportPopupOpen}
            messageReportReason={messageReportReason}
            webSocketConnected={webSocketConnected}
            messageReportReasonCode={messageReportReasonCode}
            navigate={navigate}
            setMessage={setMessage}
            sendMessage={sendMessage}
            setMessages={setMessages}
            openReportPopup={openReportPopup}
            closeReportPopup={closeReportPopup}
            onClickBtnSave={onClickBtnSave}
            handleButtonClick={handleButtonClick}
            handleFileChange={handleFileChange}
            handleWebSocketReConnect={handleWebSocketReConnect}
            uploadImageFile={uploadImageFile}
            loadMoreMessages={loadMoreMessages}
            loadRecentlyMessages={loadRecentlyMessages}
            onChangeMessageReportReason={onChangeMessageReportReason}
            onChangeMessageReportReasonCode={onChangeMessageReportReasonCode}
            handleScroll={handleScroll}
            closeChannelUser={closeChannelUser}
            isShowStudentInfoPopup={isShowStudentInfoPopup}
            setIsShowStudentInfoPopup={setIsShowStudentInfoPopup}
            isShowStudentSearchPopup={isShowStudentSearchPopup}
            setIsShowStudentSearchPopup={setIsShowStudentSearchPopup}
            selectedStudnetId={selectedStudnetId}
            setSelectedStudnetId={setSelectedStudnetId}
            sendStudentImageMessage={sendStudentImageMessage}
            isShowLoading={isShowLoading}
            isShowWarnPopup={isShowWarnPopup}
            setIsShowWarnPopup={setIsShowWarnPopup}
            isShowFeedbackPopup={isShowFeedbackPopup}
            closeFeedbackPopup={closeFeedbackPopup}
            sendFeedback={sendFeedback}
            feedbackMessage={feedbackMessage}
            setFeedbackMessage={setFeedbackMessage}
            messageStatusCode={messageStatusCode}
            isButtonDisabled={isButtonDisabled}
            isShowUserInfoPopup={isShowUserInfoPopup}
            setIsShowUserInfoPopup={setIsShowUserInfoPopup}
            userImageUrl={userImageUrl}
            isUserBlocked={isUserBlocked}
            isUserBlockedNetworking={isUserBlockedNetworking}
            clickBlockInfoButton={clickBlockInfoButton}
            isUserBlockedLoading={isUserBlockedLoading}
            {...props}
        />
    );
};

export default MessageDetailContainer;
