import { PAGE_TYPE } from "enums";
import { getLanguageValue } from "locales/i18n";
import LoadingComponent from "commons/loading/Loading.component";
import { censorText, getCurrentLangName } from "helpers/String.helper";
import { getFormattedDateValue } from "helpers/Datetime.helper";

const MessageListComponent = (props: any) => {
    const {
        request,
        channels,
        navigate,
        denyRequest,
        acceptRequest,
        handleLiClick,
        isPopup,
        popupItem,
        isRequestShowLoading,
        isChannelShowLoading,
        isButtonDisabled,
    } = props;

    const lastMessageValue = (message: any) => {
        try {
            const obj = JSON.parse(message);

            return `(IMAGE) ${obj.userStudentName}`;
        } catch {
            return censorText(message) ;
        }
    };

    return (
        <main className="message-home">
            <div className="con">
                <h2>MESSAGE</h2>

                <div className="request-list">
                    {/* <!-- 학생일 경우 --> */}
                    <div className="title">
                        <img src="./imgs/icons/message.png" alt="message icon" />
                        <p>
                            RECEIVD CHAT REQUEST
                            <em>({request ? request.length : 0})</em>
                        </p>
                    </div>

                    {isRequestShowLoading ? (
                        <LoadingComponent />
                    ) : request && request.length === 0 ? (
                        <div className="no-content">
                            <p>{getLanguageValue(PAGE_TYPE.STUDENT, "messageList.txtNoReceivedRequest")}</p>
                        </div>
                    ) : (
                        <ul className="list">
                            {request
                                ? request.map((item: any) => (
                                      <li key={item.id} onClick={() => handleLiClick(item)}>
                                          <div
                                              className="agency-logo"
                                              style={{
                                                  backgroundImage: `url(${
                                                      item.userAgencyLogo
                                                          ? `${process.env.REACT_APP_PUBLIC_RESOURCE_URL_PREFIX}${item.userAgencyLogo.replace(/ /g, "%20")}`
                                                          : "./imgs/icons/agency-logo-default.png"
                                                  }?w=220&h=220)`,
                                              }}></div>
                                          <div className="agency-name ellipsis">{item.userAgencyName}</div>

                                          {/* <!-- 학생 메시지 홈 - 기획사가 소속된 국가, 요청날짜 --> */}
                                          <div className="country">({getCurrentLangName(item?.countryCodeInfo?.names)})</div>
                                          <div className="date">
                                              <img src="./imgs/icons/clock-icon.png" alt="date" />
                                              {getFormattedDateValue(item?.createdAt, "YYYY.MM.DD")}
                                          </div>
                                      </li>
                                  ))
                                : null}
                        </ul>
                    )}
                </div>

                <div className="message-list">
                    <div className="title">
                        <p>
                            LIST
                            <em>({channels ? channels.length : 0})</em>
                        </p>
                    </div>

                    {isChannelShowLoading ? (
                        <LoadingComponent />
                    ) : channels && channels.length === 0 ? (
                        <div className="no-filter">
                            <p>{getLanguageValue(PAGE_TYPE.STUDENT, "messageList.txtNoChatList")}</p>
                        </div>
                    ) : (
                        channels.map((channel: any, index: number) => (
                            <div
                                key={index}
                                className={`list list-${index + 1}`}
                                onClick={(e: any) => {
                                    e.preventDefault();
                                    navigate(`/student/message/${channel.channelId}`, {
                                        state: {
                                            companyName: channel.userAgencyName,
                                            userAgencyId: channel.userAgencyId,
                                        },
                                    });
                                }}>
                                <p className="school-name ellipsis">
                                    {channel.userAgencyName} <span>{channel.countryCodeInfo ? getCurrentLangName(channel.countryCodeInfo.names) : ""}</span>
                                </p>
                                {channel.lastMessageStatus === "read" || channel.lastMessageStatus === "sent" ? (
                                    <p className="situation">{channel.lastMessageStatus}</p>
                                ) : (
                                    <p className="situation not-read">
                                        {channel.lastMessageStatus}
                                        <span>({channel.lastMessageUnReadCount})</span>
                                    </p>
                                )}

                                <div className="text ellipsis">{lastMessageValue(channel.lastMessage)}</div>
                                <p className="date-time">{getFormattedDateValue(channel.lastMessageCreatedAt, "YYYY.MM.DD HH:mm:ss")}</p>
                            </div>
                        ))
                    )}
                </div>
            </div>

            {/* <!-- 학생일 경우 대화요청 팝업 --> */}
            {isPopup ? (
                <div className="popup popup-chat-request">
                    <div className="con">
                        <div className="wrap">
                            <div className="close-btn" onClick={() => handleLiClick(popupItem)}></div>
                            <h2>CHAT REQUEST</h2>
                            <div className="info agency-info">
                                <div className="item">
                                    <p className="date-time">{getFormattedDateValue(popupItem.createdAt, "YYYY.MM.DD HH:mm")}</p>
                                    <div
                                        className="agency-logo"
                                        style={{
                                            backgroundImage: `url(
                        
                      ${
                          popupItem.userAgencyLogo
                              ? process.env.REACT_APP_PUBLIC_RESOURCE_URL_PREFIX + popupItem.userAgencyLogo.replace(/ /g, "%20")
                              : "./imgs/icons/agency-logo-default.png"
                      }?w=220&h=220)`,
                                        }}></div>
                                </div>
                            </div>
                            <div className="info-text">
                                <p className="agency-name">{popupItem.castingManagerName}</p>
                                <p className="country">({popupItem.userAgencyName})</p>
                            </div>

                            <div className="message-text">
                                <p>
                                    {popupItem.castingManagerName} ({popupItem.userAgencyName})
                                    {getLanguageValue(PAGE_TYPE.STUDENT, "messageList.txtRequestMessage")}
                                </p>
                            </div>

                            <div className="btn">
                                <div>
                                    <button
                                        className="deny-btn"
                                        onClick={() => {
                                            denyRequest(popupItem);
                                        }}
                                        disabled={isButtonDisabled}>
                                        {getLanguageValue(PAGE_TYPE.STUDENT, "messageList.txtDenyRequest")}
                                    </button>
                                </div>
                                <div>
                                    <button
                                        onClick={() => {
                                            acceptRequest(popupItem);
                                        }}
                                        disabled={isButtonDisabled}>
                                        {getLanguageValue(PAGE_TYPE.STUDENT, "messageList.txtAceeptRequest")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </main>
    );
};

export default MessageListComponent;
