import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import CheckPassword from "../checkPassword/CheckPassword.container";

const UpdatePasswordComponent = (props: any) => {
    const navigate = useNavigate();
    const location = useLocation();

    const {
        isCheckedPassword,
        setIsCheckedPassword,
        password,
        setPassword,
        confirmedPassword,
        setConfirmedPassword,
        onClickBtnUpdatePassword,
        isButtonDiabled,
    } = props;

    return !isCheckedPassword ? (
        <CheckPassword setIsCheckedPassword={setIsCheckedPassword} />
    ) : (
        <main className="password-update">
            <div className="con">
                <h2>PASSWORD UPDATE</h2>
                <div className="form">
                    <div className="con input">
                        <div className="input-box">
                            <label>*{getLanguageValue(PAGE_TYPE.COMMON, "passwordUpdate.txtNewPassword")}</label>
                            <input type="password" value={password} onChange={(evt) => setPassword(evt.target.value)} placeholder="" />
                        </div>
                    </div>
                    <div className="con input">
                        <div className="input-box">
                            <label>*{getLanguageValue(PAGE_TYPE.COMMON, "passwordUpdate.txtNewPasswordConfirm")}</label>
                            <input type="password" value={confirmedPassword} onChange={(evt) => setConfirmedPassword(evt.target.value)} placeholder="" />
                        </div>
                    </div>
                </div>
                <div className="btn">
                    <div className="cancel-btn">
                        <button
                            onClick={(evt) => {
                                navigate(location.pathname.replace("/update-password", ""));
                            }}>
                            {getLanguageValue(PAGE_TYPE.COMMON, "passwordUpdate.btnCancel")}
                        </button>
                    </div>
                    <div className="update-btn">
                        <button onClick={(evt) => onClickBtnUpdatePassword()} disabled={isButtonDiabled}>
                            {getLanguageValue(PAGE_TYPE.COMMON, "passwordUpdate.btnUpdate")}
                        </button>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default UpdatePasswordComponent;
