export const BAD_WORDS: string[] = [
    "인스타그램",
    "instagram",
    "インスタグラム",
    "อินสตาแกรม",
    "instakraem",
    "ig",
    "페이스북",
    "facebook",
    "フェイスブック",
    "เฟซบุ๊ก",
    "fesbuk",
    "왓츠앱",
    "워츠앱",
    "와츠앱",
    "whatsapp",
    "ワッツアップ",
    "วอตส์แอป",
    "wotsæ̀p",
    "라인",
    "line",
    "ライン",
    "ไลน์",
    "lai",
    "위챗",
    "wechat",
    "ウィーチャット",
    "微信",
    "weixin",
    "วีแชท",
    "wīchæt",
    "구글 챗",
    "구글챗",
    "구글 쳇",
    "구글쳇",
    "google chat",
    "googlechat",
    "グーグルチャット",
    "google 聊天",
    "google Liáotiān",
    "กูเกิลแชท",
    "kūkel chæt",
    "구글 메시지",
    "구글메시지",
    "구글 메세지",
    "구글메세지",
    "google message",
    "googlemessage",
    "グーグルメッセージ",
    "google 消息",
    "google 訊息",
    "กูเกิลเมสเสจ",
    "Kūkel Mèssech",
    "메신저",
    "messenger",
    "メッセンジャー",
    "信使",
    "xìnshǐ",
    "เมสเซนเจอร์",
    "mètsenjœ̄",
    "다이렉트 메세지",
    "다이렉트 메시지",
    "다이렉트메세지",
    "다이렉트메시지",
    "direct message",
    "directmessage",
    "ダイレクトメッセージ",
    "私信",
    "sīxìn",
    "私訊",
    "sīxùn",
    "ข้อความโดยตรง",
    "kh̀xkhwām doy trng",
    "tin nhắn trực tiếp",
    "pesan langsung",
    "dm",
    "아이 메세지",
    "아이메세지",
    "아이 메시지",
    "아이메시지",
    "i 메세지",
    "i메세지",
    "i 메시지",
    "i메시지",
    "imessage",
    "i message",
    "ไอเมสเสจ",
    "스냅 챗",
    "스냅챗",
    "스냅 쳇",
    "스냅 쳇",
    "스넵 챗",
    "스넵챗",
    "스넵 쳇",
    "스넵쳇",
    "스냅 chat",
    "스냅chat",
    "스넵 chat",
    "스넵chat",
    "snap 챗",
    "snap챗",
    "snap 쳇",
    "snap쳇",
    "snap chat",
    "snapchat",
    "スナップチャット",
    "สแนปแชท",
    "sanæp chæt",
    "시그널",
    "시그날",
    "signal",
    "シグナル",
    "สัญญาณ",
    "S̄ảỵỵāṇ",
    "위커",
    "wickr",
    "ウィッカー",
    "วิกเกอร์",
    "Wik̄kexr̒",
    "디스코드",
    "discord",
    "ディスコード",
    "ดิสคอร์ด",
    "diskhxd",
    "잘로",
    "zalo",
    "ザロ",
    "ซาโล",
    "Sālo",
    "텔레 그램",
    "텔레그램",
    "텔레 그람",
    "텔레그람",
    "테레 그램",
    "테레그램",
    "테레 그람",
    "테레그람",
    "텔레 글램",
    "텔레글램",
    "테레 글램",
    "테레글램",
    "telegram",
    "テレグラム",
    "เทเลแกรม",
    "thelekræm",
];

export const F_WORDS: string[] = [
    "\$h!t",
    "\$h1t",
    "\$hit",
    "( . )( . )",
    "(@)ss",
    "(ock",
    "*李*洪*志*阿扁",
    "*法*轮*功*",
    ".fuck",
    ".penis",
    "@!@",
    "@rse",
    "@rsehol",
    "@unt",
    "[ock",
    "[unt",
    "< unt",
    "<***s",
    "<**t",
    "<**ts",
    "<**t's",
    "<*nt",
    "<.unt",
    "<loth head",
    "<lothhead",
    "<nuts",
    "<o(k",
    "<o< k",
    "<o<ksu<ka",
    "<o<ksu<ker",
    "<oon",
    "<u nt",
    "<u nts",
    "<u*t",
    "<unt",
    "<unt's",
    "<vnt",
    "<vnts",
    "08憲章",
    "08宪章",
    "08县长",
    "0八宪章",
    "0捌宪章",
    "0八宪政",
    "0捌宪政",
    "1 8 년",
    "1 8 놈",
    "1 8 새끼",
    "1090tv",
    "10새끼",
    "10새야",
    "10펄년",
    "1234成人",
    "14跳",
    "16dy-图库",
    "18 년",
    "18 놈",
    "18 새 끼",
    "18 새끼",
    "18nom",
    "18것",
    "18禁",
    "18년",
    "18년아",
    "18놈",
    "18뇬",
    "18새끼",
    "18歲淫亂",
    "1989年流血事件",
    "1989年真相",
    "1999亚太新新闻",
    "1닥;쳐",
    "1시발",
    "2010年中国将会爆发",
    "2012年山东临沂附近",
    "20年前挡坦克",
    "20周年网络大会",
    "222se图片",
    "2穴中出",
    "30杂志",
    "33bbb走光",
    "360雄起",
    "3P",
    "3P体验",
    "3P炮图",
    "4r5e",
    "5000汉人",
    "5252播",
    "55sss偷拍区",
    "5h1t",
    "5hit",
    "610办公室",
    "613佛山一号文件",
    "613新乡一号文件",
    "613熊岳一号文件",
    "613兴平一号文件",
    "64事件",
    "64式销售QQ",
    "64學潮",
    "64学潮",
    "69式",
    "6四事件",
    "6合彩",
    "715暴行",
    "75纪念",
    "75事件",
    "75事件一周年",
    "75暴行",
    "7月5日纪念",
    "7月5日晚上",
    "8 仙",
    "8===D",
    "8023部队",
    "89年",
    "89民運",
    "89民运",
    "89事件",
    "89學運",
    "89学运",
    "89學潮",
    "89学潮",
    "8仙",
    "8섹히",
    "92式销售QQ",
    "97干",
    "999日本妹",
    "9爱吧",
    "a\$\$",
    "a\$\$hole",
    "a\$\$hole\$",
    "a\$\$holes",
    "a.rse",
    "a_s_s",
    "a+*hole",
    "a2m",
    "a55",
    "a55hole",
    "Abruti",
    "abuse",
    "ac발",
    "adm",
    "admin",
    "administrator",
    "adminster",
    "adminstor",
    "adult",
    "adultsexshop",
    "adultvideo",
    "adultzone",
    "aeolus",
    "ahole",
    "AIDS",
    "alba",
    "anal",
    "analprobe",
    "Analwichser",
    "anilingus",
    "anus",
    "aquaball",
    "ar\$ehole",
    "ar\$hole",
    "ar\$holes",
    "ar5e",
    "ar5h0le",
    "ar5h0les",
    "arb구함",
    "areola",
    "areole",
    "arian",
    "arrse",
    "ars3",
    "Arsch",
    "Arschgeburt",
    "arschgefickt",
    "Arschgeige",
    "Arschkriecher",
    "Arschlecker",
    "Arschloch",
    "Arschratte",
    "arse",
    "arse hole",
    "arseh0le",
    "arseh0les",
    "arseho",
    "arsehol",
    "arsehole",
    "arseholes",
    "arsewipe",
    "arsh0le",
    "arshole",
    "arsholes",
    "aryan",
    "ashole",
    "asiangirl",
    "ass",
    "àss",
    "ass h0le",
    "ass hole",
    "assbang",
    "assbanged",
    "assbangs",
    "asses",
    "assfuck",
    "assfucker",
    "ass-fucker",
    "assfukka",
    "assh0le",
    "assh0les",
    "asshat",
    "assho1e",
    "asshole",
    "assholes",
    "assmaster",
    "assmucus",
    "assmunch",
    "asswhole",
    "asswholes",
    "asswipe",
    "asswipes",
    "auction",
    "aunty",
    "autoerotic",
    "AV女",
    "AV女優",
    "AV女优",
    "av小电影",
    "AV淫女",
    "AV电影下载",
    "av貼圖",
    "AV片",
    "awoodong",
    "azazel",
    "azz",
    "A聊电影",
    "A型肉毒素",
    "b!tch",
    "b***ocks",
    "b***ocks.",
    "b*ll*cks",
    "b.astard",
    "b.ollocks",
    "b.ugger",
    "b@st@rd",
    "b@st@rds",
    "b00b",
    "b00bs",
    "b00tha",
    "b00thas",
    "b0ll0cks",
    "b0llocks",
    "b17ch",
    "b1tch",
    "b3llend",
    "bacastv",
    "backbojytv",
    "ballbag",
    "ballsack",
    "baltringue",
    "bang",
    "bangbros",
    "banger",
    "bareback",
    "barf",
    "bastard",
    "bastards",
    "basterd",
    "basyard",
    "basyards",
    "batard",
    "bâtard",
    "batty boi",
    "batty boy",
    "batty boy",
    "bawdy",
    "bbagoori",
    "bbagury",
    "bdsm",
    "beaber",
    "beaner",
    "beardedclam",
    "beastial",
    "beastiality",
    "beatch",
    "beater",
    "beef curtains",
    "beefcurtain",
    "beeyotch",
    "beheading",
    "belend",
    "bell end",
    "bell.end",
    "bellend",
    "bell-end",
    "beotch",
    "bestbozi",
    "bestial",
    "bestiality",
    "bi+ch",
    "biatch",
    "bigtits",
    "bilau",
    "bimbo",
    "bimbos",
    "binya",
    "birdlock",
    "bitch",
    "bitched",
    "bitcher",
    "bitchers",
    "bitches",
    "bitchin",
    "bitching",
    "bitchy",
    "bite",
    "bitte",
    "blob",
    "blow",
    "blow job",
    "blow me",
    "Blowjob",
    "blow-job",
    "blowjobs",
    "bo****ks",
    "bo11ocks",
    "boceta",
    "bogy",
    "boji",
    "bojy",
    "bojyty",
    "boll0cks",
    "bollock",
    "bollocks",
    "bollok",
    "bollox",
    "bolocks",
    "bolox",
    "boludo",
    "boned",
    "boner",
    "boners",
    "boob",
    "boobies",
    "boobs",
    "booby",
    "booger",
    "bookie",
    "boong",
    "booobs",
    "boooobs",
    "booooobs",
    "boooooobs",
    "booooooobs",
    "bootha",
    "boothas",
    "boquete",
    "bordel",
    "bosta",
    "Bouffon",
    "bougnoul",
    "bougnoule",
    "bozi",
    "ⓑⓞⓩⓘ",
    "bozicam",
    "bozy",
    "bo지",
    "branle",
    "Branleur",
    "Brazzers",
    "bt",
    "buceta",
    "bugger",
    "buIIshit",
    "buIIshits",
    "bukkake",
    "bullshit",
    "bullshits",
    "bullshitted",
    "bullturds",
    "bum hole",
    "bumbandit",
    "bum-bandit",
    "bumh0l3",
    "bumh0le",
    "bumhol3",
    "bumhole",
    "bung",
    "bunghole",
    "bunsec",
    "bunsek",
    "buttfuck",
    "buttfucker",
    "butthole",
    "buttmuch",
    "buttmunch",
    "buttplug",
    "buttsex",
    "byuntae",
    "byuntaesex",
    "B样",
    "c *nt!",
    "c *nts!",
    "c a o",
    "c u n t",
    "c u n t.",
    "c u n t",
    "c#nt",
    "c* nt",
    "c***s",
    "c**k",
    "c**t",
    "c**ts",
    "c**t's",
    "c*nt",
    "c.0.c.k",
    "c.o.c.k.",
    "c.u.n.t",
    "c.unt",
    "c.untyb.ollocks",
    "c_u_n_t",
    "c00n",
    "c0ck",
    "c-0-c-k",
    "c0cksucka",
    "c0cksucker",
    "C4的制作方法",
    "c8",
    "c818",
    "c8c8",
    "cabaço",
    "cacete",
    "cagar",
    "cagarruta",
    "cagate",
    "cahnt",
    "cahnts",
    "cameltoe",
    "candy-ass",
    "CAO",
    "carajo",
    "caralho",
    "carpetmuncher",
    "carporno",
    "carsex",
    "cawk",
    "ccipal",
    "ccival",
    "cervix",
    "ce발",
    "CFX气枪销售",
    "chana",
    "chatte",
    "chinc",
    "chincs",
    "chink",
    "chiottes",
    "chloroform",
    "chlorophorm",
    "choade",
    "chocho",
    "chode",
    "chodes",
    "chotabags",
    "chuffnuts",
    "cibal",
    "cipa",
    "cipal",
    "cival",
    "cl!t",
    "cl1t",
    "climax",
    "clit",
    "clit licker",
    "clitoris",
    "clitorus",
    "clits",
    "clitty",
    "clubero",
    "clud",
    "clunge",
    "clusterfuck",
    "cnD刊物和论坛",
    "cnut",
    "cnuts",
    "cn夫妻交友",
    "co(k",
    "coc k",
    "cocain",
    "cocaine",
    "cock",
    "c-o-c-k",
    "cockblock",
    "cockface",
    "cockhead",
    "cockholster",
    "cockknocker",
    "cockmunch",
    "cockmuncher",
    "cocks",
    "cocksmoker",
    "cocksuck",
    "cocksucka",
    "cocksucked",
    "cocksucker",
    "cock-sucker",
    "cocksuckers",
    "cocksuckers.",
    "cocksucking",
    "cocksucks",
    "cocksuka",
    "cocksukka",
    "coital",
    "coito",
    "coitus",
    "cojer",
    "cojones",
    "cok",
    "cokmuncher",
    "coksucka",
    "com2us",
    "comesek",
    "commie",
    "comsek",
    "concha",
    "condom",
    "connard",
    "connasse",
    "conne",
    "coño",
    "coon",
    "coonnass",
    "copulate",
    "corksucker",
    "cornhole",
    "corno",
    "cosex",
    "cosex.net",
    "cosexnet",
    "cossor ali",
    "couille",
    "crackwhore",
    "crap",
    "crappy",
    "creampie",
    "cretin",
    "cripple",
    "critest",
    "cu nt",
    "cu nts",
    "cu*t",
    "culia",
    "culiao",
    "culio",
    "culo",
    "cum",
    "cumdump",
    "cummer",
    "cummers",
    "cummin",
    "cumming",
    "cums",
    "cumshot",
    "cumshots",
    "cumslut",
    "cumstain",
    "cunilingus",
    "cunillingus",
    "cunnilingus",
    "cunny",
    "cunt",
    "c-u-n-t",
    "cuntbag",
    "cuntface",
    "cunthunter",
    "cunting",
    "cuntlick",
    "cuntlicker",
    "cuntlicking",
    "cunts",
    "cunt's",
    "cuntsicle",
    "cunt-struck",
    "cuzao",
    "cuzão",
    "cvnt",
    "cvnts",
    "cyberfuc",
    "cyberfuck",
    "cyberfucked",
    "cyberfucker",
    "cyberfuckers",
    "cyberfucking",
    "c댕",
    "c발",
    "c팔",
    "d**khead",
    "d0ng",
    "d0uch3",
    "d0uche",
    "d1ck",
    "d1ck!",
    "d1ck5",
    "d1ckh@ed",
    "d1ld0",
    "d1ldo",
    "dabang",
    "dago",
    "dagos",
    "damn",
    "damoimsex",
    "darkie",
    "darky",
    "darn",
    "daterape",
    "dawgie-style",
    "dduk",
    "Débile",
    "deng小pin",
    "descabaçada",
    "dick",
    "dick head",
    "dickbag",
    "dickdipper",
    "dickface",
    "dickflipper",
    "dickhead",
    "dickheads",
    "dickhole",
    "dickish",
    "dick-ish",
    "dickripper",
    "dickshy",
    "dicksipper",
    "dickweed",
    "dickwhipper",
    "dickzipper",
    "diddle",
    "dike",
    "dildo",
    "dildos",
    "DILF",
    "diligaf",
    "dillweed",
    "dingle",
    "dink",
    "dinks",
    "dipship",
    "dipstick",
    "dirsa",
    "dlck",
    "dogbaby",
    "dog-fucker",
    "doggiestyle",
    "doggie-style",
    "doggin",
    "dogging",
    "doggy-style",
    "dog새",
    "dog새끼",
    "dong",
    "donkeyribber",
    "doosh",
    "douch3",
    "douche",
    "douchebag",
    "douchebags",
    "douchey",
    "doumi",
    "Drecksau",
    "Dreckstück",
    "drunk",
    "duche",
    "ducon",
    "dumass",
    "dumbass",
    "dumbasses",
    "dumbfuck",
    "dumbfucker",
    "dummy",
    "dungeondragononline",
    "dxxkhead",
    "dyke",
    "dykes",
    "d랄",
    "ecstasy",
    "ejaculate",
    "ejaculated",
    "ejaculates",
    "ejaculating",
    "ejaculatings",
    "ejaculation",
    "ejakulate",
    "Emmerdeur",
    "encash",
    "encul",
    "enculé",
    "enculer",
    "enflure",
    "enfoiré",
    "Engadget中文网",
    "erection",
    "ero2030",
    "ero69",
    "eroasia",
    "erocine",
    "erosasia",
    "erosian",
    "erostyle",
    "erotic",
    "escroto",
    "eselsgefickt",
    "essohbee",
    "estupido",
    "ethnics",
    "extacy",
    "extasy",
    "ⓔ반",
    "f cked",
    "f o a d",
    "f u c k",
    "f u c k e r",
    "f u c ked",
    "f uc k",
    "f uc king",
    "f uck",
    "f ucker",
    "f ucking",
    "f u c k",
    "f uck",
    "f###",
    "f##k",
    "f##king",
    "f#cked",
    "f\$cks",
    "f()()k",
    "f()()ker",
    "f()()king",
    "f*#kin'",
    "f*&k",
    "f*&k!ng",
    "f***",
    "f*****",
    "f******",
    "f*****g",
    "f****d",
    "f***ed",
    "f***in",
    "f***ing",
    "f**k",
    "f**ked",
    "f**ker",
    "f**kin",
    "f**king",
    "f**ks",
    "f*c*",
    "f*ck",
    "f*ckin",
    "f*ked",
    "f*ker",
    "f*ks",
    "f*uck",
    "f*uk",
    "f.o.a.d",
    "f.o.a.d.",
    "f.u.c.k",
    "f.u.c.k.",
    "f.u.c.k.e.r",
    "f.uck",
    "f@@@in",
    "f@@@ing",
    "f@ck",
    "f@g",
    "f@gs",
    "f[_]cker",
    "f[_]cking",
    "f^^k",
    "f^^ked",
    "f^^ker",
    "f^^king",
    "f^ck",
    "f^cker",
    "f^cking",
    "f__kin",
    "f__king",
    "f_u_c_k",
    "f<uk",
    "f>>k",
    "f00k",
    "f00ked",
    "f0oked",
    "f4nny",
    "facefuck",
    "facho",
    "facial",
    "fack",
    "fackin",
    "facking",
    "fæg",
    "fag",
    "fág",
    "fàg",
    "fâg",
    "fäg",
    "fāg",
    "fãg",
    "fåg",
    "fagg",
    "fagg0t",
    "fagged",
    "fagging",
    "faggit",
    "faggits",
    "faggitt",
    "faggot",
    "faggs",
    "fagits",
    "fagot",
    "fagots",
    "fags",
    "faig",
    "faigt",
    "fak",
    "falun",
    "fangong",
    "fanny",
    "fannybandit",
    "fannyflaps",
    "fannyfucker",
    "fanri",
    "fanrilianmeng",
    "fantadream",
    "fanyy",
    "fart",
    "fat",
    "fatass",
    "fa轮",
    "fc*king",
    "fck",
    "f'ck",
    "fck ing",
    "fck1ng",
    "fckeud",
    "fckin",
    "fcking",
    "fcks",
    "fckw1t",
    "fckwit",
    "fcuk",
    "fcuked",
    "fcuker",
    "fcukin",
    "fcuking",
    "fcuks",
    "fdp",
    "feallatio",
    "feck",
    "fecker",
    "feckin",
    "fecking",
    "f---ed",
    "fekking",
    "felch",
    "felched",
    "felcher",
    "felching",
    "fellate",
    "fellatio",
    "feltch",
    "feltcher",
    "feltching",
    "femsub",
    "fetish",
    "fetish7",
    "fetishwoman",
    "f-----g",
    "fick dich",
    "ficken",
    "Ficker",
    "f---ing",
    "fingerfuck",
    "fingerfucked",
    "fingerfucker",
    "fingerfuckers",
    "fingerfucking",
    "fingerfucks",
    "fiotte",
    "fistfuck",
    "fistfucked",
    "fistfucker",
    "fistfuckers",
    "fistfucking",
    "fistfuckings",
    "fistfucks",
    "fisting",
    "fisty",
    "fk",
    "f--k",
    "fker",
    "fkin",
    "fking",
    "flange",
    "flucknuts",
    "fo0ked",
    "foad",
    "f-o-a-d",
    "foda",
    "foder",
    "foobar",
    "fook",
    "fookd",
    "fooked",
    "fooker",
    "fookin",
    "fookin'",
    "fooking",
    "foreskin",
    "forfun",
    "forro",
    "Fotze",
    "Freetibet",
    "freex",
    "Fresse",
    "frig",
    "frigg",
    "frigga",
    "frigging",
    "frigin",
    "friging",
    "fu <k",
    "fu ck",
    "fu cked",
    "fu cker",
    "fu cking",
    "fu&kin",
    "fu&king",
    "fu(k",
    "fu(ker",
    "fu(kers",
    "fu*k",
    "fu.ck",
    "fu@k",
    "fu@ker",
    "fu^k",
    "fu^ker",
    "fu^king",
    "fu< kin",
    "fu<k",
    "f-u-<-k",
    "fu<ked",
    "fu<ker",
    "fu<kin",
    "fu<king",
    "fu<kker",
    "fu<kr",
    "fu<ks",
    "fubar",
    "fuc ked",
    "fuc ker",
    "fuc king",
    "fuck",
    "f-uck",
    "f-u-c-k",
    "fúck",
    "fúćk",
    "fúčk",
    "fúçk",
    "fùck",
    "fùćk",
    "fùčk",
    "fùçk",
    "fûck",
    "fûćk",
    "fûčk",
    "fûçk",
    "fück",
    "füćk",
    "füčk",
    "füçk",
    "fūck",
    "fūćk",
    "fūčk",
    "fūçk",
    "fuck ed",
    "fuck ing",
    "fucka",
    "fuckass",
    "fuck-ass",
    "fuck-bitch",
    "fuckboi",
    "fuckboy",
    "fucke d",
    "fucked",
    "fucker",
    "fuckers",
    "fuckface",
    "fuckhead",
    "fuckheads",
    "fucki ng",
    "fuckin",
    "fucking",
    "fúcking",
    "fuckinghell",
    "fuckings",
    "fuckingshitmotherfucker",
    "fuckk",
    "fuckme",
    "fuckmeat",
    "fucknugget",
    "fucknut",
    "fuckoff",
    "fucks",
    "fuckshit",
    "fucktard",
    "fuck-tard",
    "fucktoy",
    "fuckup",
    "fuckw1t",
    "fuckwad",
    "fuckwhit",
    "fuckwit",
    "fuck-wit",
    "fuckwits",
    "fuckyou",
    "fucw1t",
    "fucwit",
    "fuder",
    "fudge packer",
    "fudgep@cker",
    "fudge-p@cker",
    "fudgepacker",
    "fudge-packer",
    "fudgpacker",
    "fuk",
    "fukced",
    "fuked",
    "fuker",
    "fukin",
    "fuking",
    "fukk",
    "fukked",
    "fukker",
    "fukkin",
    "fukking",
    "fuks",
    "fukwhit",
    "fukwit",
    "furbox",
    "furburger",
    "fuukn",
    "fux",
    "fux0r",
    "fvck",
    "fvckup",
    "fvck-up",
    "fvckw1t",
    "fvckwit",
    "fxck",
    "g.m",
    "g1m",
    "gabozi",
    "gae",
    "gaebozi",
    "gagasexy",
    "gai",
    "gameguard",
    "gamemaster",
    "gamin",
    "gangbang",
    "gang-bang",
    "gangbanged",
    "gangbangs",
    "gangshaq",
    "gash",
    "gay",
    "gayboi",
    "gayboy",
    "gayers",
    "gayhole",
    "gaylord",
    "gays",
    "gaysex",
    "geforce",
    "gey",
    "gfy",
    "ghay",
    "ghey",
    "gigolo",
    "GILF",
    "gilipollas",
    "gim",
    "gimp",
    "ginch",
    "Girl5女性贴图网",
    "Glandeur",
    "glans",
    "glm",
    "gnikcuf",
    "Goatfucker",
    "goatse",
    "gocinepia",
    "God damn",
    "goddam",
    "Goddamn",
    "gojasex",
    "goldenshower",
    "gonad",
    "gonads",
    "gongchandang",
    "gonorrhea",
    "gook",
    "gooks",
    "goris",
    "gouine",
    "gozar",
    "grelo",
    "greluda",
    "gringo",
    "grognasse",
    "gspot",
    "g-spot",
    "gtfo",
    "guido",
    "gypo",
    "gypos",
    "gyppo",
    "gyppos",
    "g랄",
    "g롤",
    "g앰",
    "g点",
    "G点刺激棒",
    "g통신",
    "g片",
    "h0m0",
    "h0mo",
    "hanaro인터넷",
    "handjob",
    "hardcore",
    "hardcoreporno",
    "hardcoresex",
    "hardon",
    "harlot",
    "he11",
    "hebe",
    "heeb",
    "hell",
    "hellbreaker",
    "hemp",
    "hentai",
    "heroin",
    "herp",
    "herpes",
    "herpy",
    "heshe",
    "hijadeputa",
    "hijaeputa",
    "hijodeputa",
    "hijoeputa",
    "Hitler",
    "hiv",
    "ho.mos",
    "hoar",
    "hoare",
    "hobag",
    "höe",
    "hoer",
    "hom0",
    "homey",
    "homo",
    "homoerotic",
    "homoey",
    "homosexuality",
    "hompy",
    "Hong Kong Herald. 台湾报纸",
    "honkers",
    "honky",
    "hookah",
    "hooker",
    "hoor",
    "hooter",
    "hooters",
    "hore",
    "horniest",
    "horny",
    "Hosefucker",
    "hostmaster",
    "hotbojy",
    "hotsex",
    "hotsexkorea",
    "http://7942cam.na.st",
    "http://joychat.wo.to",
    "http://meeting.na.st",
    "http://say77.wo.to",
    "http://seven.na.st",
    "http://www.fam.co.kr",
    "http://www.gsting.co.kr/",
    "http:7942camnast",
    "http:joychatwoto",
    "http:meetingnast",
    "http:say77woto",
    "http:sevennast",
    "http:wwwfamcokr",
    "http:wwwgstingcokr",
    "hump",
    "humped",
    "humping",
    "Hundesohn",
    "Hure",
    "Hurensohn",
    "hussy",
    "hymen",
    "H动漫",
    "H漫联盟",
    "H漫画",
    "h站",
    "H片",
    "i3!tch",
    "i300i3s",
    "i31+ch",
    "i317ch",
    "i31tch",
    "i3astard",
    "i3i+ch",
    "i3iowjoi3",
    "i3itch",
    "i3l7ch",
    "i3lowjoi3",
    "i3ltch",
    "i3oioias",
    "i3oiolas",
    "i3ooi3s",
    "i3uceta",
    "i3ukk4k3",
    "i3ukk4ke",
    "i3ukkak3",
    "i3ukkake",
    "i644",
    "ibtl",
    "Idiot",
    "idiota",
    "ie5bian",
    "Iesb0",
    "iesb1an",
    "iesbi4n",
    "iesbian",
    "iesi3ian",
    "ijsex",
    "ij에로쇼",
    "ilovesex",
    "imbecil",
    "imbécile",
    "inbred",
    "incest",
    "injun",
    "isil",
    "isis",
    "ISO服务",
    "israelians",
    "ities",
    "j1sm",
    "j1zz",
    "j3rk0ff",
    "j3w",
    "j4ck0ff",
    "j4ckoff",
    "J8",
    "jack0ff",
    "jackass",
    "jackhole",
    "jackoff",
    "jack-off",
    "jaeputa",
    "jaji",
    "jamji",
    "jap",
    "jap5",
    "jappo",
    "japs",
    "jasal",
    "jawe",
    "jawi",
    "jazi",
    "jazy",
    "ja위",
    "ja지",
    "JB",
    "jerk",
    "jerk0ff",
    "jerked",
    "jerkme",
    "jerkoff",
    "jerk-off",
    "Jesus",
    "jew",
    "j'ew",
    "jfantasy",
    "jgirls",
    "ji5m",
    "jiral",
    "jism",
    "jiz",
    "jizm",
    "jizz",
    "jizzed",
    "jizzes",
    "jizzing",
    "ji랄",
    "jjtv",
    "jl5m",
    "jlsm",
    "jlzz",
    "joeputa",
    "jot",
    "jo까9in네",
    "junglebunny",
    "junkie",
    "junky",
    "k**t",
    "k@ffir",
    "k@ffirs",
    "k@fir",
    "k@firs",
    "k00n",
    "k00ter",
    "k0oter",
    "k4wk",
    "kaf1r",
    "kaff1r",
    "kaffir",
    "kaffirs",
    "kafir",
    "kafirs",
    "kafr",
    "Kanacke",
    "kangrilianmeng",
    "kants",
    "kao",
    "kawk",
    "kcid",
    "kcuf",
    "kenga",
    "kgirlmolca",
    "kgirls",
    "khunt",
    "kiddie-fiddler",
    "kiddie-fiddling",
    "kiddyfiddler",
    "kiddy-fiddler",
    "kikes",
    "kill",
    "Kinderficker",
    "kingsex",
    "kinky",
    "kinky Jesus",
    "kiss.cg.to",
    "kkk",
    "klan",
    "kmovie",
    "kn0b",
    "kneegrow",
    "knob",
    "knob end",
    "knob head",
    "knobber",
    "knobead",
    "knobed",
    "knobend",
    "knobhead",
    "knobjocky",
    "knobjokey",
    "ko0n",
    "kock",
    "kondum",
    "kondums",
    "kooch",
    "kooches",
    "koon",
    "kootch",
    "kooter",
    "korea포르노",
    "krtv",
    "ksex",
    "ksex.net",
    "ksexnet",
    "kuffar",
    "kum",
    "kummer",
    "kumming",
    "kums",
    "kunilingus",
    "kunt",
    "kuter",
    "kwif",
    "kyke",
    "k粉",
    "K他命",
    "l3!tch",
    "l300l3s",
    "l31+ch",
    "l317ch",
    "l31tch",
    "l3astard",
    "l3i+ch",
    "l3itch",
    "l3l+ch",
    "l3lowjol3",
    "l3ltch",
    "l3ololas",
    "l3ool3s",
    "l3uceta",
    "l3ukk4k3",
    "l3ukk4ke",
    "l3ukkak3",
    "l3ukkake",
    "labia",
    "labias",
    "lactoids",
    "Laden",
    "Ladin",
    "le5bian",
    "le5blan",
    "lech",
    "lee2us",
    "leper",
    "lesb0",
    "lesb1an",
    "lesbi4n",
    "lesbian",
    "lesbians",
    "lesbica/lésbica",
    "lesbien",
    "lesbl4n",
    "lesblan",
    "lesbo",
    "lesbos",
    "lesbyterian",
    "lesi3ian",
    "lesl3lan",
    "lez",
    "lezbo",
    "lezbos",
    "lezzie",
    "lezzies",
    "lezzo",
    "lezzy",
    "libtard",
    "live10tv",
    "live69tv",
    "lmfa0",
    "lmfao",
    "loin",
    "loins",
    "Loli",
    "Lolita",
    "loli控",
    "lolsale.org",
    "lotrogold",
    "lube",
    "Lutscher",
    "m.inge",
    "m.otherf.ucker",
    "m0f0",
    "m0fo",
    "m0thafkr",
    "m0therfucker",
    "m1erd4",
    "m1erda",
    "m1nge",
    "m1ss10nary",
    "m3rd3",
    "m3rde",
    "m45terbate",
    "m4sturb4te",
    "ma5terb8",
    "ma5terbate",
    "ma5turbate",
    "madreputa",
    "mafugly",
    "mamaguevo",
    "mammilla",
    "mams",
    "manabozi",
    "mange-merde",
    "man-root",
    "Maricon",
    "mas+urba+e",
    "masochist",
    "massa",
    "mastabate",
    "mastarbate",
    "masterb8",
    "masterbat",
    "masterbat*",
    "masterbat3",
    "masterbate",
    "master-bate",
    "masterbating",
    "masterbation",
    "masterbations",
    "masturbador",
    "masturbar",
    "masturbate",
    "masturbates",
    "masturbating",
    "masturbation",
    "masturi3ate",
    "masturl3ate",
    "matriz",
    "MC무현",
    "meatspin.com",
    "meatspin.net",
    "meatspin.org",
    "mecago",
    "megamassmedia",
    "menses",
    "menstruate",
    "menstruation",
    "merda",
    "merde",
    "Merdeux",
    "metamorphosis",
    "meth",
    "m-fucking",
    "midget",
    "mierd4",
    "mierda",
    "MILF",
    "Minable",
    "minge",
    "Missgeburt",
    "Miststück",
    "ML",
    "mlerd4",
    "mlerda",
    "MM公寓",
    "mm美图",
    "MM屄",
    "MM一夜情",
    "mo+herfucker",
    "mof**ker",
    "mof**kers",
    "mof0",
    "mofo",
    "mo-fo",
    "mofucc",
    "mofuccer",
    "mofucker",
    "mofuckers",
    "mofucking",
    "mofukcer",
    "mohterf**ker",
    "mohterf**kers",
    "mohterf*kcer",
    "mohterfuccer",
    "mohterfuccers",
    "mohterfuck",
    "mohterfucker",
    "mohterfuckers",
    "mohterfucking",
    "mohterfucks",
    "mohterfuk",
    "mohterfukcer",
    "mohterfukcers",
    "mohterfuking",
    "mohterfuks",
    "molca",
    "molcaav",
    "molcacd",
    "molcanara",
    "molcaparty",
    "molcapornotv",
    "molcasex",
    "molcasexnipon",
    "molcatv",
    "molca섹스",
    "molca섹스코리아",
    "molca테이프",
    "molca포르노",
    "molca포르노소라가이드",
    "molest",
    "molester",
    "moolie",
    "moron",
    "Möse",
    "moterf**ker",
    "moterfuccer",
    "moterfuck",
    "moterfucker",
    "moterfuckers",
    "moterfucking",
    "moterfucks",
    "mothaf**k",
    "mothaf**ker",
    "mothaf**kers",
    "mothaf**king",
    "mothaf**ks",
    "mothafuc",
    "mothafuccer",
    "mothafuck",
    "mothafucka",
    "motha-fucka",
    "mothafuckas",
    "mothafuckaz",
    "mothafucked",
    "mothafucker",
    "mothafuckers",
    "mothafuckin",
    "mothafucking",
    "mothafuckings",
    "mothafucks",
    "mother f---ers",
    "mother fucker",
    "motherf**ked",
    "motherf**ker",
    "motherf**kers",
    "motherfckr",
    "motherfk",
    "motherfker",
    "motherfking",
    "motherfuccer",
    "motherfuccers",
    "motherfuck",
    "motherfucka",
    "motherfucked",
    "motherfucker",
    "motherfuckers",
    "motherfuckin",
    "motherfucking",
    "motherfuckings",
    "motherfuckka",
    "motherfucks",
    "motherfukkker",
    "mrcondom",
    "mthaf**ka",
    "mthafucca",
    "mthafuccas",
    "mthafucka",
    "mthafuckas",
    "mthafukca",
    "mthafukcas",
    "mtherfucker",
    "mthrfucker",
    "mthrfucking",
    "muff",
    "muff-diver",
    "muff-diving",
    "muffs",
    "mulyosex",
    "Muschi",
    "muth@fucker",
    "mutha",
    "muthaf**k",
    "muthaf**ker",
    "muthaf**kers",
    "muthaf**king",
    "muthaf**ks",
    "muthafecker",
    "muthafuccer",
    "muthafuck",
    "muthafuck@",
    "muthafucka",
    "muthafuckaz",
    "muthafucker",
    "muthafuckers",
    "muthafucking",
    "muthafuckker",
    "muthafucks",
    "muthafukas",
    "mutherfucker",
    "mutherfucking",
    "muthrfucking",
    "mytit",
    "n_gger",
    "n00tz",
    "n0otz",
    "n1gga",
    "n1gger",
    "n1ggk3r",
    "n1ggk4r",
    "n1ggkar",
    "n1ggker",
    "n1gk3r",
    "n1gk4r",
    "n1gkar",
    "n1gker",
    "n1p",
    "n4zi",
    "n4zl",
    "nacisse",
    "nad",
    "nads",
    "naggar",
    "nagger",
    "nallari",
    "nappy",
    "narutard",
    "natsee",
    "natsi",
    "natzee",
    "natzi",
    "naz1",
    "nazi",
    "nazism",
    "nazl",
    "neegar",
    "Neger",
    "negger",
    "neggur",
    "Negre",
    "negro",
    "Negroid",
    "nestlecock",
    "netbux",
    "n'gger",
    "nggr",
    "nichons",
    "nig",
    "nig nog",
    "nig.ga",
    "niga",
    "nigar",
    "niger",
    "nigg",
    "nigg3r",
    "nigg4",
    "nigg4h",
    "nigga",
    "ńigga",
    "ńiggá",
    "ńiggà",
    "ńiggâ",
    "ńiggä",
    "ńiggā",
    "ńiggã",
    "ńiggå",
    "ńígga",
    "ńíggá",
    "ńíggà",
    "ńíggâ",
    "ńíggä",
    "ńíggā",
    "ńíggã",
    "ńíggå",
    "ńìgga",
    "ńìggá",
    "ńìggà",
    "ńìggâ",
    "ńìggä",
    "ńìggā",
    "ńìggã",
    "ńìggå",
    "ńîgga",
    "ńîggá",
    "ńîggà",
    "ńîggâ",
    "ńîggä",
    "ńîggā",
    "ńîggã",
    "ńîggå",
    "ńïgga",
    "ńïggá",
    "ńïggà",
    "ńïggâ",
    "ńïggä",
    "ńïggā",
    "ńïggã",
    "ńïggå",
    "ńīgga",
    "ńīggá",
    "ńīggà",
    "ńīggâ",
    "ńīggä",
    "ńīggā",
    "ńīggã",
    "ńīggå",
    "ńįgga",
    "ńįggá",
    "ńįggà",
    "ńįggâ",
    "ńįggä",
    "ńįggā",
    "ńįggã",
    "ńįggå",
    "ñigga",
    "ñiggá",
    "ñiggà",
    "ñiggâ",
    "ñiggä",
    "ñiggā",
    "ñiggã",
    "ñiggå",
    "ñígga",
    "ñíggá",
    "ñíggà",
    "ñíggâ",
    "ñíggä",
    "ñíggā",
    "ñíggã",
    "ñíggå",
    "ñìgga",
    "ñìggá",
    "ñìggà",
    "ñìggâ",
    "ñìggä",
    "ñìggā",
    "ñìggã",
    "ñìggå",
    "ñîgga",
    "ñîggá",
    "ñîggà",
    "ñîggâ",
    "ñîggä",
    "ñîggā",
    "ñîggã",
    "ñîggå",
    "ñïgga",
    "ñïggá",
    "ñïggà",
    "ñïggâ",
    "ñïggä",
    "ñïggā",
    "ñïggã",
    "ñïggå",
    "ñīgga",
    "ñīggá",
    "ñīggà",
    "ñīggâ",
    "ñīggä",
    "ñīggā",
    "ñīggã",
    "ñīggå",
    "ñįgga",
    "ñįggá",
    "ñįggà",
    "ñįggâ",
    "ñįggä",
    "ñįggā",
    "ñįggã",
    "ñįggå",
    "ńiggæ",
    "ńíggæ",
    "ńìggæ",
    "ńîggæ",
    "ńïggæ",
    "ńīggæ",
    "ńįggæ",
    "ñiggæ",
    "ñíggæ",
    "ñìggæ",
    "ñîggæ",
    "ñïggæ",
    "ñīggæ",
    "ñįggæ",
    "niggah",
    "niggar",
    "niggas",
    "niggaz",
    "nigger",
    "n'igger",
    "niggers",
    "niggir",
    "niggk3r",
    "niggk4r",
    "niggkar",
    "niggker",
    "niggle",
    "niggr",
    "niggs",
    "nigguh",
    "niggur",
    "n'iggur",
    "niggz",
    "nigimi",
    "nigk3r",
    "nigk4r",
    "nigkar",
    "nigker",
    "niglet",
    "nignog",
    "nig-nog",
    "nigro",
    "nigrow",
    "nikka",
    "nikkahs",
    "nimi",
    "ninny",
    "nip",
    "nipple",
    "niqqa",
    "niqqas",
    "niqqaz",
    "niqqer",
    "niqqers",
    "niqqerz",
    "nique",
    "niquer",
    "nlgg4",
    "nlgga",
    "nlgger",
    "NMD",
    "NND",
    "no0ts",
    "nob head",
    "nobhead",
    "nobjocky",
    "nobjokey",
    "noc",
    "nomo",
    "nonce",
    "noode",
    "nooky",
    "noots",
    "nootz",
    "nopants",
    "notsi",
    "notzi",
    "no예",
    "NPC",
    "nprotect",
    "nu+sack",
    "nudcouple",
    "nude",
    "nudes",
    "nudlnude",
    "Nul",
    "nut5ack",
    "nuts4ck",
    "nutsack",
    "nutsackfcuk",
    "Nutte",
    "nvidia",
    "nympho",
    "n조e",
    "o|\/|fg",
    "oJ\/|fg",
    "oj\/lfg",
    "omfg",
    "onecllckpay",
    "opiate",
    "opium",
    "oral",
    "oralsextv",
    "Ordure",
    "orgasim",
    "orgasims",
    "orgasm",
    "orgasmic",
    "orgasms",
    "orgies",
    "orgy",
    "Osama",
    "ov4rles",
    "ov4rlez",
    "ov4ry",
    "ovar1es",
    "ovaries",
    "ovarle5",
    "ovarles",
    "ovarlez",
    "ovary",
    "ovum",
    "ovums",
    "p**i",
    "p*ki",
    "p.enis",
    "ⓟ.ⓗ.ⓞ",
    "p.iss-flaps",
    "p.u.s.s.y.",
    "p.ussy",
    "p@ki",
    "p@kis",
    "p00f",
    "p00fs",
    "p00fter",
    "p00fters",
    "p0of",
    "p0rn",
    "P3d0ph1135",
    "P3d0ph113s",
    "P3d0ph1l35",
    "P3d0ph1l3s",
    "P3d0phii35",
    "P3d0phii3s",
    "P3d0phiie5",
    "P3d0phiies",
    "P3d0phil35",
    "P3d0phil3s",
    "P3d0phli35",
    "P3d0phli3s",
    "P3d0phlie5",
    "P3d0phlies",
    "P3dophii35",
    "P3dophii3s",
    "P3dophil35",
    "P3dophil3s",
    "P3dophli35",
    "P3dophli3s",
    "paddy",
    "paedo",
    "paedophile",
    "paedophiles",
    "painolympics",
    "pajearse",
    "pajero",
    "pak1",
    "paki",
    "pakis",
    "pantie",
    "panties",
    "panty",
    "pastie",
    "pawn",
    "pcp",
    "pe.nis",
    "peado",
    "peadofile",
    "peadofiles",
    "pecker",
    "ped0phii35",
    "ped0phii3s",
    "Ped0phiie5",
    "Ped0phiies",
    "ped0phli35",
    "ped0phli3s",
    "Ped0phlie5",
    "Ped0phlies",
    "pédale",
    "pede",
    "pédé",
    "pedes",
    "pédés",
    "pedo",
    "Pedophii35",
    "Pedophii3s",
    "pedophile",
    "Pedophile5",
    "Pedophiles",
    "pedophiless",
    "pedophilia",
    "pedophiliac",
    "Pedophli35",
    "pédoque",
    "peedo",
    "peedofile",
    "peedofiles",
    "peedophile",
    "peedophiles",
    "peedos",
    "peehole",
    "pelotudo",
    "pen.is",
    "pen15",
    "pench0d",
    "pench0ds",
    "penchod",
    "penchods",
    "penetrate",
    "penetration",
    "peni.s",
    "penial",
    "peniiss",
    "penile",
    "penis",
    "Pénis",
    "penis.",
    "penises",
    "penisfucker",
    "Penner",
    "pennis",
    "perversion",
    "pétasse",
    "petish",
    "ph4g",
    "phagg",
    "phalli",
    "phallic",
    "phanny",
    "phanny.",
    "phck",
    "phcuk",
    "pheck",
    "phecking",
    "phegs",
    "phelching",
    "pheque",
    "phequing",
    "phonesec",
    "phonesex",
    "phone색",
    "phonsec",
    "phonsek",
    "phuck",
    "phucker",
    "phuckin",
    "phucking",
    "phucks",
    "phuk",
    "phuked",
    "phuking",
    "phukked",
    "phukking",
    "phuks",
    "phuq",
    "phuque",
    "pigfucker",
    "pihb",
    "pija",
    "pikey",
    "pillowbiter",
    "pillow-biter",
    "Pimmel",
    "pimp",
    "pimpis",
    "pinga",
    "pinko",
    "pinto",
    "piroca",
    "piss",
    "piss off",
    "pissed",
    "Pisser",
    "pissers",
    "pisses",
    "pissflaps",
    "pissin",
    "pissing",
    "pissoff",
    "piss-off",
    "playbog",
    "playboy",
    "playbozi",
    "pleybog",
    "pleyboy",
    "pms",
    "pnnang",
    "po0f",
    "poff",
    "polack",
    "pollock",
    "ponce",
    "pondo",
    "pons",
    "ponsec",
    "ponsek",
    "ponz",
    "poof",
    "poofs",
    "poofter",
    "poon",
    "poontang",
    "poop",
    "porchmonkey",
    "porn",
    "porner",
    "PornHub",
    "porno",
    "pornô",
    "pornobozi",
    "pornography",
    "pornomana",
    "pornoplayboy",
    "pornos",
    "porno바다",
    "porno애니",
    "pornsex",
    "porra",
    "posture",
    "pouffiasse",
    "poufiasse",
    "pourriture",
    "powercom",
    "pr!ck",
    "pr!ck.",
    "pr0n",
    "pr1ck",
    "pr1ck!",
    "pr1cks",
    "pr1cks!",
    "precum",
    "prick",
    "prîck",
    "pricks",
    "prig",
    "prik",
    "prno",
    "pron",
    "prostitute",
    "pu\$\$y",
    "pu.ssy",
    "pu551e",
    "pu553",
    "pu55e",
    "pu55i3",
    "pu55l3",
    "pu55y",
    "pube",
    "pubic",
    "pubis",
    "punheta",
    "punkass",
    "punky",
    "pus.s.y",
    "pus.sy",
    "puss",
    "puss.y",
    "puss13",
    "puss3",
    "pusse",
    "pussejew",
    "pussi",
    "pussi3",
    "pussie",
    "pussied",
    "pussies",
    "pussle",
    "pussy",
    "pussylicker",
    "pussypounder",
    "pussys",
    "pusy",
    "puta",
    "putaiada",
    "putain",
    "putamadre",
    "Pute",
    "putinha",
    "putinho",
    "puto",
    "QB自动",
    "queaf",
    "queef",
    "queer",
    "queero",
    "queers",
    "quenga",
    "quicky",
    "quim",
    "qveer",
    "Q币销售",
    "Q币自动",
    "Q币专",
    "Q播伦理",
    "r3ct1m",
    "r3ctim",
    "r3ctlm",
    "r3ctum",
    "r4p3",
    "r4pe",
    "rabo de peixe",
    "racaille",
    "racy",
    "raghead",
    "ragheads",
    "ramera",
    "rap3",
    "rapariga",
    "rape",
    "rapeboys",
    "raped",
    "rapegirls",
    "rapeher",
    "rapehim",
    "rapemen",
    "raper",
    "rapes",
    "rapewomen",
    "raping",
    "rapist",
    "raunch",
    "realxx",
    "rectal",
    "rectim",
    "rectlm",
    "rectum",
    "rectus",
    "redtube",
    "reefer",
    "reetard",
    "reich",
    "renob",
    "republicunt",
    "ret@rd",
    "retard",
    "rètard",
    "retarded",
    "rètardèd",
    "retardin",
    "retart",
    "revue",
    "rewardhits",
    "rim job",
    "rimjaw",
    "rimjob",
    "rimming",
    "ringtones",
    "ritard",
    "rivals",
    "RMB收",
    "ⓡⓞⓞⓜ",
    "rotten.com",
    "rotten.net",
    "rotten.org",
    "rtard",
    "r-tard",
    "rubmy",
    "rump",
    "rumprammer",
    "runsex",
    "ruski",
    "s hit",
    "s.0.b.",
    "s.h.i.t",
    "s.h.i.t.",
    "s.hit",
    "s.o.b.",
    "s.o.i3.",
    "s.o.l3.",
    "s_h_i_t",
    "s0b",
    "s1ut",
    "s2x",
    "s3x",
    "Sackgesicht",
    "Sackratte",
    "sadism",
    "sadist",
    "Salaud",
    "sale porc",
    "salop",
    "salope",
    "sandbar",
    "sapatão",
    "sappho",
    "sausage jockey",
    "sb",
    "scag",
    "scantily",
    "schizo",
    "Schlampe",
    "schlong",
    "Schwanz",
    "Schwanzlutscher",
    "scr0tum",
    "scro+um",
    "scroat",
    "scrog",
    "scrot",
    "scrote",
    "scrotum",
    "scrud",
    "scum",
    "scumbag",
    "scumber",
    "seaman",
    "seamen",
    "secma",
    "seduce",
    "sekma",
    "semen",
    "sendnudes",
    "sex",
    "sèx",
    "sⓔx",
    "ⓢex",
    "⒮⒠⒳",
    "sex123",
    "sex18",
    "sex1818",
    "sex4969",
    "sexbbs",
    "sexboard",
    "sexbuin",
    "sexcorea",
    "sexeros",
    "sexfreak",
    "sexgoods",
    "sexhangame",
    "sexjapan",
    "sexjj",
    "sexkorea",
    "sexkorea,net",
    "sexkorea.com",
    "sexkorea.net",
    "sexkorea21",
    "sexkoreacom",
    "sexkoreanet",
    "sexmachine",
    "sexmaxx",
    "sexmolca",
    "sexmolka",
    "sexmovie",
    "sexmusa",
    "sexnipon",
    "sexpds",
    "sexpia",
    "sexporno",
    "sexpot",
    "sexsexy",
    "sexshop",
    "sextv",
    "sexual",
    "sexualintercourse",
    "sexwal",
    "sexwall.com",
    "sexwallcom",
    "sexwarez",
    "sexwith",
    "sexxmolcatv",
    "sexyadong",
    "sexyclik",
    "sexyjapan",
    "sexymap",
    "sexynmall",
    "sexysoul",
    "sexyx",
    "sexy화상채팅",
    "sex로리타",
    "sex聊天室",
    "sex하자",
    "se엑스",
    "SE窝窝",
    "sh!+",
    "sh!t",
    "sh!te",
    "sh!tes",
    "sh1+",
    "sh1t",
    "s-h-1-t",
    "sh1te",
    "shag",
    "shagbucket",
    "shagger",
    "shaggin",
    "shagging",
    "shagstress",
    "shamedame",
    "sharted",
    "shat",
    "sheeny",
    "Sheepfucker",
    "shem4ie",
    "shem4le",
    "shemaie",
    "shemale",
    "shi+",
    "shibal",
    "shiiiiiit",
    "shit",
    "s'hit",
    "s-h-i-t",
    "shitdick",
    "shite",
    "shiteater",
    "shited",
    "shitey",
    "shitface",
    "shitfuck",
    "shitfull",
    "shithead",
    "shitheads",
    "shithole",
    "shithouse",
    "shiting",
    "shitings",
    "shits",
    "shitstorm",
    "shitstorms",
    "shitt",
    "shitted",
    "shitter",
    "shitters",
    "shitting",
    "shittings",
    "shitty",
    "shiz",
    "shl+",
    "shlt",
    "sht",
    "s'ht",
    "shyt",
    "sibal",
    "sipal",
    "siririca",
    "sissy",
    "siu+",
    "siut",
    "sival",
    "skag",
    "skank",
    "slantyeye",
    "slanty-eye",
    "slantyeyed",
    "slanty-eyed",
    "slave",
    "sleaze",
    "sleazy",
    "slope",
    "slu+",
    "slut",
    "slutdumper",
    "slutkiss",
    "sluts",
    "slutty",
    "SM",
    "smegma",
    "smu+wank",
    "smut",
    "smutty",
    "SM女王",
    "SM美图",
    "SM性虐",
    "SM用品",
    "SM援交",
    "sm调教",
    "SM舔穴",
    "snatch",
    "snuff",
    "s-o-b",
    "sodom",
    "sodomite",
    "sodomy",
    "son-of-a-bitch",
    "SooperAtomBo",
    "soraguide",
    "sorasex",
    "sorasguide",
    "sora'sguide",
    "sorasguide.com",
    "sorasguidecom",
    "sorassguide",
    "sossex",
    "sp00g3",
    "sp00ge",
    "sp0og3",
    "sp0oge",
    "sp1c",
    "spac",
    "spank",
    "spanking",
    "Spankwire",
    "spatona",
    "spearchucker",
    "sperm",
    "sph1nct3r",
    "sph1ncter",
    "sph1nkter",
    "sphinc7er",
    "sphinct3r",
    "sphincter",
    "sphinkter",
    "sphlncter",
    "spic",
    "s'pic",
    "spick",
    "spik",
    "spiks",
    "splc",
    "spo0g3",
    "spo0ge",
    "spooge",
    "spum",
    "spunk",
    "srilankasex",
    "ß0ll0çk5",
    "ssh1t",
    "sshit",
    "sshlt",
    "ssibal",
    "ssipal",
    "ssival",
    "stfu",
    "stiffy",
    "stimulant",
    "streetgirl",
    "street-girl",
    "strider",
    "strip",
    "stroke",
    "strollop",
    "Suce",
    "suck",
    "sucked",
    "sucking",
    "suckme",
    "suckmy",
    "sucks",
    "SUCKSEX",
    "suckster",
    "suicide",
    "sumofabiatch",
    "sunginsite",
    "sux",
    "swaoing",
    "swap",
    "swaping",
    "sylivasaint",
    "sysoo",
    "s파트너",
    "s하e자x",
    "s하고e싶다x",
    "t w a t",
    "t w a t s",
    "t*w*a*t",
    "T.M.D",
    "t.wat",
    "t’emmerde",
    "t0\$\$er",
    "t0sser",
    "t0ssers",
    "t1t",
    "t1ts",
    "t1tt",
    "t1tt1e5",
    "t1tt1es",
    "t1tties",
    "t35t3s",
    "t35t3z",
    "t3st3s",
    "t3stes",
    "t3stez",
    "ta gueule",
    "ta mere",
    "tachy",
    "tampon",
    "taobao",
    "tapette",
    "tard",
    "tarlouze",
    "te5te5",
    "te5tes",
    "te5tez",
    "te5ticai",
    "te5tical",
    "te5ticie",
    "te5ticle",
    "te5tlcal",
    "te5tlcle",
    "teabagging",
    "tea-bagging",
    "teat",
    "teet5",
    "teets",
    "teez",
    "tema",
    "terd",
    "test1cai",
    "test1cal",
    "test1cie",
    "test1cle",
    "test1s",
    "test3s",
    "test3z",
    "teste",
    "testee",
    "testes",
    "t'estes",
    "testez",
    "t'estez",
    "testic4i",
    "testic4l",
    "testicai",
    "testical",
    "testicie",
    "testicle",
    "t'esticle",
    "testicles",
    "t'esticles",
    "t'esticlez",
    "testis",
    "testlc4l",
    "testlcal",
    "testlcle",
    "testls",
    "tesuda",
    "teta",
    "thot",
    "TILF",
    "tit",
    "t'it",
    "tit5",
    "titfuck",
    "titi",
    "titjob",
    "titjobs",
    "tits",
    "t'its",
    "tits.",
    "titt",
    "tittie5",
    "tittiefucker",
    "titties",
    "titties.",
    "titty",
    "tittyfuck",
    "tittyfucker",
    "tittywank",
    "titwank",
    "t'itz",
    "tlt5",
    "tlts",
    "tltt",
    "tltty",
    "TMD",
    "TM单双头",
    "tnnd",
    "tnuc",
    "to55er",
    "to55ers",
    "toggaf",
    "tonto",
    "tosser",
    "tossers",
    "tossurs",
    "towelhead",
    "tramp",
    "transexual",
    "traveco",
    "tribadist",
    "trou de balle",
    "Trou du cul",
    "trou duc",
    "trouduk",
    "tubgirl",
    "turd",
    "tush",
    "tvbozi",
    "tvvat",
    "tvvats",
    "tv플레이보이",
    "tw at",
    "tw@t",
    "tw@ts",
    "tw_t",
    "tw4+",
    "tw4t",
    "twa t",
    "twa+",
    "twat",
    "t'wat",
    "twathead",
    "twats",
    "twatt",
    "twattish",
    "twatty",
    "Twatwaffle",
    "Twink",
    "twinkie",
    "twistkim",
    "twistkim.com",
    "twistkimcom",
    "twunt",
    "twunter",
    "twunts",
    "u r",
    "U/R",
    "uncle fucker",
    "unwed",
    "UR",
    "U-R",
    "urban",
    "urban-rivals",
    "urinal",
    "urine",
    "usenet",
    "utereo",
    "utero",
    "uterus",
    "uucp",
    "UU片",
    "U香阁",
    "v14gra",
    "v1gra",
    "v4g1n4",
    "vag",
    "vag.",
    "vagabunda",
    "vagina",
    "vagina.",
    "vaginas",
    "valium",
    "veado",
    "venereal",
    "verga",
    "viadinho",
    "viado",
    "viagra",
    "virgin",
    "viva포르노",
    "voyeur",
    "voyeurism",
    "vulgar",
    "vulva",
    "w#nker",
    "w#nkers",
    "w***er",
    "w*nkers",
    "w.ank",
    "w@nk",
    "w@nker",
    "w@nkers",
    "w@nks",
    "w+f",
    "w005e",
    "w00se",
    "w0g",
    "w0gs",
    "w4nk",
    "w4nker",
    "w4nker!",
    "w4nkers!",
    "w7f",
    "wa nker",
    "wackoff",
    "wack-off",
    "waigua",
    "wang",
    "wank",
    "wanka",
    "wanked",
    "wanker",
    "wankers",
    "wanking",
    "wanks",
    "wank's",
    "wanky",
    "wazoo",
    "webgame.com.cn",
    "webxxx",
    "WEB战牌",
    "WEB牌戰",
    "weelaa",
    "weenie",
    "weewee",
    "weiner",
    "wench",
    "wetback",
    "wetpussy",
    "wh0ar",
    "wh0re",
    "wh0reface",
    "Whalefucker",
    "whanker",
    "whankers",
    "whatthefukk",
    "whitey",
    "whiz",
    "who4r",
    "whoar",
    "whoralicious",
    "whore",
    "w'hore",
    "whorealicious",
    "whored",
    "whoreface",
    "whorehopper",
    "whorehouse",
    "whores",
    "w'hores",
    "w'horez",
    "whoring",
    "Wichsarsch",
    "wigga",
    "wigger",
    "wiitard",
    "willies",
    "willy",
    "wiseass",
    "wizzer",
    "WLQ下台",
    "wog",
    "womb",
    "wonjosex",
    "woody",
    "wop",
    "worldsex",
    "wowomg.com",
    "wtf",
    "wtfs",
    "wtfz",
    "www.camsee.co.kr",
    "www.gsting.co.kr",
    "www.pic-k.com/tmp/51.html",
    "www.porno",
    "www.sex123.co.kr",
    "www.sexkorea.net",
    "www.sexwal.com",
    "www.twistkim.com",
    "wwwcamseecokr",
    "wwwgstingcokr",
    "wwwpickcomtmp51html",
    "wwwporno",
    "wwwsex123cokr",
    "wwwsexkoreanet",
    "wwwsexwalcom",
    "wwwtwistkimcom",
    "x386",
    "xana",
    "xgf",
    "xhamster",
    "xota",
    "xoxota",
    "xpeed",
    "xrated",
    "x-rated",
    "xrse",
    "xrseh",
    "xrsehol",
    "xrsehole",
    "xxx",
    "xxxhole",
    "xxx비디오",
    "xxx포르노",
    "xx할",
    "x대가리",
    "Ｘ到噴屎尿",
    "x동",
    "x등급",
    "x물",
    "x섹x스x해x",
    "x섹스",
    "x위",
    "x지",
    "x털",
    "y!ddo!",
    "y!ddo!!",
    "y*d",
    "y0urt1t",
    "y0urtit",
    "y0urtlt",
    "yadong",
    "yadongclub",
    "yadongmolca",
    "yahanbozi",
    "yahannom",
    "yaoi",
    "ya동",
    "yesmolca",
    "yessex",
    "yid",
    "yido",
    "yobbo",
    "youngsex",
    "youporn",
    "yourtit",
    "z1pp3rhead",
    "z1pperh3ad",
    "zachariah bishop",
    "zaji",
    "zazi",
    "zipperhead",
    "zoophile",
    "zot",
    "zotte",
    "балабол",
    "Баран",
    "Бля",
    "Блядь",
    "блять",
    "бляха муха",
    "в пизду",
    "выебон",
    "Выебываться",
    "гавно",
    "гандон",
    "дебил",
    "дегенерат",
    "дерьмо",
    "доебался",
    "доебываться",
    "дурак",
    "ёб твою мать",
    "Ебало",
    "ебальник",
    "ебан",
    "ебанат",
    "ебанулся",
    "ебанушка",
    "ебать",
    "еблан",
    "ебло",
    "Жопа",
    "заебал",
    "заебись",
    "Идиот",
    "кастрат",
    "Козел",
    "козёл",
    "лох",
    "лошара",
    "Мразь",
    "мудак",
    "наебал",
    "Не ебет",
    "не еби",
    "Не еби мозги!",
    "не пизди",
    "ничтожество",
    "нищеброд",
    "Овца",
    "Отсоси",
    "Отъебись",
    "Педераст",
    "педрило",
    "перди",
    "пидер",
    "пидор",
    "пидорас",
    "Пизда",
    "пиздабол",
    "пиздатый",
    "пиздел",
    "Пиздец",
    "пизди",
    "пиздит",
    "пиздун",
    "писька",
    "Похуй",
    "Придурок",
    "сволочь",
    "скотина",
    "спиздил",
    "ссать",
    "ссы",
    "ссыкун",
    "Сука",
    "сучара",
    "Съебись",
    "Твою мать!",
    "тупой",
    "ублюдок",
    "убожество",
    "уебать",
    "Уебище",
    "урод",
    "хер",
    "херня",
    "Хрен",
    "хуёво",
    "хуесос",
    "хуета",
    "Хуй",
    "Хуйня",
    "хули",
    "член",
    "чмо",
    "чмошник",
    "шалава",
    "ябвдул",
    "あいのこ",
    "アオカン",
    "あきめくら",
    "アクメ",
    "あて馬",
    "アナル",
    "あなる",
    "あほ",
    "あらめん",
    "イカサマ",
    "いざり",
    "いちゃもんをつける",
    "イマラチオ",
    "イモ",
    "イラマチオ",
    "インチキ",
    "ウェノム",
    "ウンコ",
    "うんこ",
    "うんち",
    "オーラル",
    "おし",
    "オッパイ",
    "おっぱい",
    "おとしまえ",
    "オナニ",
    "おむつプレー",
    "おめこ",
    "がき",
    "ガサ",
    "かさっかき",
    "かたわ",
    "がちゃ目",
    "キチガイ",
    "きちがい",
    "きちがいに刃物",
    "きちがい沙汰",
    "キムチ虫",
    "ギャグ",
    "くさっ",
    "クスコ",
    "クソ",
    "くそ",
    "グリセリン",
    "クリトリス",
    "くろんぼ",
    "くわえこむ",
    "げい",
    "ゲンナマ",
    "こけ",
    "コプロラグニスト",
    "ゴム",
    "ころし",
    "ころす",
    "ザギン",
    "サツ",
    "サック",
    "サッフィズム",
    "サディスト",
    "サディズム",
    "シマ",
    "ジャリ",
    "ジョッパリ",
    "しらっこ",
    "しりぬぐい",
    "ズージャー",
    "スカトロ",
    "スカトロジー",
    "すけこまし",
    "ずらかる",
    "セックス",
    "セフレ",
    "せむし",
    "せんずり",
    "ソフトSM",
    "たれ流し",
    "チクビ",
    "ちくび",
    "チャリンコ",
    "チャンコロ",
    "チョン",
    "ちょん",
    "チョンコ",
    "チンカス",
    "チンコ",
    "ちんこ",
    "ちんば",
    "チンポ",
    "ちんぽ",
    "チン毛",
    "ちん毛",
    "つんぼ",
    "つんぼ桟敷",
    "デリバリーヘルス",
    "てんぼう",
    "どもり",
    "ドヤ",
    "トルコ嬢",
    "トルコ風呂",
    "ナオン",
    "なかだし",
    "なめたい",
    "ニグロ",
    "ネコババ",
    "のうまくえん",
    "パクる",
    "ピーピング",
    "ビザール",
    "びっこ",
    "ヒモ",
    "ファック",
    "ふぐり",
    "ブス",
    "ブタ箱",
    "ブツ",
    "ぶっ殺す",
    "ペイ患",
    "ペニス",
    "ﾍﾟﾆｽ",
    "へのこ",
    "ヘルス嬢",
    "ホーデン",
    "ポコペン",
    "ポルノ",
    "ほんぼし",
    "まえつき",
    "マンカス",
    "マンコ",
    "まんこ",
    "マン毛",
    "まん毛",
    "みつくち",
    "めくら",
    "めくら滅法",
    "めくら蛇におじず",
    "めくら判",
    "めくら縞",
    "ヤー様",
    "やさぐれ",
    "ライ病",
    "ロンパリ",
    "ॣ",
    "ㄅ",
    "ㄆ",
    "ㄇ",
    "ㄇㄉ",
    "ㄈ",
    "ㄉ",
    "ㄊ",
    "ㄋ",
    "ㄌ",
    "ㄍ",
    "ㄎ",
    "ㄏ",
    "ㄐ",
    "ㄐ掰",
    "ㄐ巴",
    "ㄐ八",
    "ㄑ",
    "ㄒ",
    "ㄓ",
    "ㄔ",
    "ㄕ",
    "ㄖ",
    "ㄗ",
    "ㄘ",
    "ㄙ",
    "ㄙㄞ你公",
    "ㄙㄞ你娘",
    "ㄙㄞ你老母",
    "ㄙㄞ你老師",
    "ㄙㄞ你媽",
    "ㄙㄞ你母",
    "ㄙㄞ你爸",
    "ㄚ",
    "ㄛ",
    "ㄜ",
    "ㄝ",
    "ㄞ",
    "ㄟ",
    "ㄠ",
    "ㄡ",
    "ㄢ",
    "ㄣ",
    "ㄤ",
    "ㄥ",
    "ㄦ",
    "ㄧ",
    "ㄨ",
    "ㄩ",
    "ㄱㅐ 넘",
    "ㄱㅐ 년",
    "ㄱㅐ 놈",
    "ㄱㅐ 눔",
    "ㄱㅐ 늠",
    "ㄱㅐ ㅅㅑ",
    "ㄱㅐ 조 옷",
    "ㄱㅐ 조옷",
    "ㄱㅐ넘",
    "ㄱㅐ년",
    "ㄱㅐ놈",
    "ㄱㅐ눔",
    "ㄱㅐ늠",
    "ㄱㅐㅅㅑ",
    "ㄱㅐ새",
    "ㄱㅐ조 옷",
    "ㄱㅐ조옷",
    "ㄱㅐ지 랄",
    "ㄱㅐ지랄",
    "ㄱㅣ 집 애",
    "ㄱㅣ집애",
    "㉮ 숨",
    "柯建銘",
    "假庆林",
    "贾庆林",
    "歌功頌德",
    "加拿大国际广播电台",
    "可待因",
    "可待因",
    "街头对抗",
    "街头扒衣",
    "可怜的维吾尔人",
    "街垒",
    "假文凭",
    "假博士",
    "咖啡因",
    "柯賜海",
    "㉮숨",
    "가슴",
    "가슴 만져",
    "가슴 빨아",
    "가슴 빨어",
    "가슴 쪼물딱",
    "가슴 쪼물락",
    "가슴 핧아",
    "가슴 핧어",
    "가슴만져",
    "가슴빨아",
    "가슴빨어",
    "가슴쪼물딱",
    "가슴쪼물락",
    "가슴핧아",
    "가슴핧어",
    "加央多吉",
    "假阳具插穴",
    "賈育台",
    "假币",
    "可卡",
    "可卡葉",
    "可卡因",
    "可卡叶",
    "賈廷安",
    "假青林",
    "瘸腿幫",
    "瘸腿帮",
    "가튼년",
    "가튼놈",
    "假学历",
    "愨",
    "脚交",
    "各类票据",
    "奸",
    "奸",
    "姦",
    "干",
    "幹",
    "幹 你 娘",
    "幹bi",
    "干b爽",
    "干GM",
    "干X",
    "幹家",
    "干干",
    "幹幹幹",
    "干鸡",
    "干机",
    "幹機掰",
    "奸你",
    "奸你",
    "姦你",
    "姦妳",
    "干尼",
    "干你",
    "干妳",
    "干您",
    "幹你",
    "幹你",
    "幹妳",
    "干你娘",
    "干妳娘",
    "幹你娘",
    "幹你娘",
    "幹妳娘",
    "趕你娘",
    "幹你娘老芝麻",
    "干妳老母",
    "幹你老母",
    "幹你老母",
    "幹妳老母",
    "幹你老比",
    "幹你老師",
    "幹妳老師",
    "干你妈",
    "干妳妈",
    "幹你媽",
    "幹妳媽",
    "干你妈b",
    "幹你媽b",
    "幹你媽啦屄",
    "幹你媽啦逼",
    "干你妈逼",
    "幹你媽逼",
    "看大秀",
    "干到",
    "干到高潮",
    "干到射",
    "幹到死",
    "干到爽",
    "干啦",
    "干拎娘",
    "幹拎娘",
    "干勒",
    "干林",
    "幹本RING",
    "幹本鬼子",
    "奸夫淫妇",
    "幹比",
    "幹屁股",
    "干死",
    "幹死",
    "幹死",
    "干死你",
    "幹死你",
    "幹死你",
    "奸殺",
    "奸杀",
    "幹三小",
    "干爽",
    "干小姐",
    "看秀群",
    "奸屍",
    "干柴烈火",
    "干柴烈火美图",
    "看什么都可以",
    "干我",
    "羟亚胺",
    "干汝",
    "姦染",
    "姦汙",
    "奸幼",
    "奸淫",
    "姦淫",
    "奸淫电车",
    "幹一家",
    "幹一家",
    "奸一奸",
    "干一干",
    "干入",
    "奸她",
    "干她",
    "幹她",
    "幹她",
    "干她妈",
    "幹的你",
    "干的爽",
    "幹全家",
    "幹全家",
    "奸情",
    "羟丁酸",
    "簡肇棟",
    "看中國",
    "看中国专栏",
    "간철수",
    "干七八",
    "幹七八",
    "奸他",
    "干他",
    "干它",
    "干牠",
    "幹他",
    "幹他",
    "幹他娘",
    "干他妈",
    "姦通",
    "幹砲",
    "幹炮",
    "奸暴",
    "干爆",
    "幹逼",
    "看下",
    "干穴",
    "갈보",
    "갈보자식",
    "监禁陵辱",
    "甘丹寺",
    "砍翻一條街",
    "監視塔",
    "監視塔哨兵",
    "監視塔哨兵隊",
    "監視塔哨兵隊長",
    "监听器",
    "监听宝",
    "甘孜",
    "监察院",
    "甲庆林",
    "甲基安菲他明",
    "岬崎香子",
    "갓카",
    "강 간",
    "강,간",
    "강.간",
    "江core",
    "江ze民",
    "江Ｚ民",
    "江家帮",
    "강간",
    "强奸",
    "強奸",
    "強姦",
    "強姦",
    "強姦你日你",
    "强奸犯",
    "強姦犯",
    "强奸处女",
    "강간한다",
    "强硬发言",
    "江系",
    "钢管秀",
    "江狗",
    "江鬼完蛋",
    "疆独",
    "江独裁",
    "江羅",
    "江罗集团",
    "江老贼",
    "江楼绮梦",
    "江流氓",
    "江魔气死",
    "岡巒",
    "江綿恒",
    "講法",
    "剛比",
    "剛比樣子",
    "冈山空军官学校",
    "强卫",
    "江宰民",
    "江猪",
    "降低命中的圖騰",
    "江猪媳",
    "江賊",
    "江贼",
    "僵賊",
    "江賊民",
    "江贼民",
    "僵賊民",
    "僵贼民",
    "江折民",
    "强制浣肠",
    "江主席",
    "钢珠枪",
    "江青",
    "崗哨士兵",
    "冈村秀树",
    "冈村宁次",
    "薑春雲",
    "江則民",
    "江则民",
    "强拆当地居民房",
    "江澤民",
    "江择民",
    "江泽民",
    "僵泽民",
    "江澤慧",
    "江泽慧",
    "江派",
    "江八",
    "江八点",
    "江八點",
    "强暴",
    "強暴",
    "强暴幼女",
    "江蛤蟆翘辫子了",
    "江蛤蟆死了",
    "江核心",
    "强行拖入宿舍多人",
    "江湖淫娘",
    "江胡之争",
    "江昏君",
    "江祸心",
    "强效失意药",
    "江戲子",
    "江戏子",
    "같은년",
    "같이 자자",
    "같이자자",
    "개,같,은,년,,",
    "개,세,끼야",
    "개.같",
    "개.같.은.년",
    "개.새",
    "개.세",
    "개;새",
    "개18놈",
    "개1새",
    "개1세",
    "개가튼년",
    "개가튼뇬",
    "개갈보",
    "개같은",
    "개같은년",
    "개같은놈",
    "개거지",
    "개걸래",
    "개걸레",
    "개고추",
    "개고치",
    "개꼬추",
    "개넌",
    "개넘",
    "개년",
    "개놈",
    "개눔",
    "开放网",
    "開放雜志",
    "开放杂志",
    "개보지털",
    "개부랄",
    "개불랄",
    "개붕알",
    "개빠구리",
    "개새",
    "개새 끼",
    "개새끼",
    "개\'새\'끼",
    "개\'\'새\'\'끼",
    "개새년",
    "개새야",
    "개색",
    "개색갸",
    "개섹",
    "개쉐키",
    "개쉑아",
    "开心色情网",
    "개십보지야",
    "개싼년",
    "개쌍도",
    "개쌍영",
    "개쌔끼",
    "개쌴년",
    "개썅",
    "개썅년",
    "개썅놈",
    "개쎅",
    "개쒜끼",
    "개씁자지",
    "개씨발",
    "개씨발넘",
    "개씨발놈",
    "개씨발자슥",
    "개씨부랄",
    "개씹팔",
    "개씹헐놈",
    "개애걸래",
    "개양아새끼",
    "凱奧勒尼什",
    "凱爾雷斯",
    "凱爾本",
    "개자식",
    "개자슥",
    "개자지",
    "개잡년",
    "개잡지랄",
    "개저가튼",
    "개저엇",
    "개젓",
    "개젓가튼넘",
    "개젖",
    "개젖가튼넘",
    "改制",
    "개존만아",
    "개좃",
    "개좆",
    "개좆같은넘",
    "개좆같은놈",
    "개좇",
    "개지랄",
    "개지랄뇬",
    "개찌질이",
    "开枪",
    "凱特切爾",
    "开波",
    "開苞",
    "開苞",
    "开苞",
    "개허접",
    "개호로",
    "개호로들",
    "개호로새끼",
    "개후라",
    "개후라년",
    "개후라뇬",
    "개후라들놈",
    "개후라들새끼",
    "개후라릴년",
    "개후라릴놈",
    "개후라새끼",
    "개후라셰리",
    "개후라쉐리",
    "개후랄",
    "개후랄년",
    "개후랴새끼",
    "개후장",
    "客家电视台",
    "갸슴",
    "巨チン",
    "居家美眉",
    "巨奶",
    "去你妈",
    "去妳妈",
    "去你媽的",
    "去你妈的",
    "去你的",
    "去妳的",
    "耟繴纍",
    "去死",
    "巨骚",
    "巨乳",
    "巨乳内射",
    "巨乳女",
    "巨乳肥腚",
    "巨乳俏女医",
    "巨乳素人",
    "巨乳颜射",
    "去她妈",
    "거지쉐뀌",
    "거지쉑이",
    "거지쎄끼",
    "거지쒜리",
    "去车仑工力",
    "巨鐵角哈克",
    "巨屌",
    "鋸齒通道骷髏",
    "鋸齒通道被遺",
    "鋸齒通道被遺弃的骷髏",
    "去他妈",
    "巨槌騎兵",
    "巨波",
    "巨炮兵团",
    "建國黨",
    "걸래",
    "걸래가튼뇬",
    "걸래같은년",
    "걸래넘",
    "걸래년",
    "걸래놈",
    "걸레",
    "걸레가튼넘",
    "걸레가튼뇸",
    "걸레같은",
    "걸레같은년",
    "걸레같은놈",
    "걸레년",
    "걸레핀년",
    "鈐",
    "极上素人",
    "겁탈",
    "极品MM图片网",
    "极品兼职女",
    "极品奶妹",
    "极品美穴",
    "极品白虎",
    "极品屄",
    "极品骚妇",
    "极品素人",
    "极品骚穴",
    "极品诱惑",
    "极品一波",
    "极品波神",
    "极品波霸",
    "极品片",
    "极品黑丝",
    "揭露中共",
    "揭密国家领导人的子女",
    "게부랄",
    "게부럴",
    "게부알",
    "게불알",
    "揭批書",
    "게뿔",
    "게새끼",
    "게새리",
    "게새이",
    "게색",
    "게색갸",
    "게색기",
    "게색끼",
    "게샛키",
    "게세",
    "게세꺄",
    "게세끼",
    "게세리",
    "게섹",
    "게섹놈아",
    "게셋키",
    "게셰이",
    "게쉐",
    "게쉐이",
    "게쉑아",
    "게쉑캬",
    "게쉑키",
    "게스끼",
    "게스래기",
    "게스키",
    "게시끼",
    "게시퀴",
    "게싯끼",
    "게쌔기",
    "게쌕끼",
    "게쓰레기",
    "게씨발넘",
    "게에가튼",
    "게자식",
    "게자지",
    "게잡넘",
    "게잡년",
    "게잡뇬",
    "게저엇",
    "게젓",
    "게젓가튼넘",
    "게좆",
    "게지랄",
    "게지랄놈",
    "게호로년",
    "게호로놈",
    "게호로새끼",
    "覡",
    "闃",
    "緙",
    "擊倒圖騰",
    "格魯",
    "格魯(城鎮移",
    "格魯(城鎮移動)",
    "格雷(關卡排",
    "格雷(關卡排名管理者)",
    "激流中国",
    "激插",
    "激爽性爱",
    "擊傷的圖騰",
    "格色拉的祈祷",
    "激淫",
    "激情交友",
    "激情女找男",
    "激情裸体",
    "激情聊天",
    "激情服务",
    "激情夫妻",
    "激情小说",
    "激情小电影",
    "激情视频",
    "激情一对一",
    "激情自拍",
    "激情电影",
    "激情潮喷",
    "激情贴图",
    "激情打炮",
    "激情炮图",
    "激凸走光",
    "犬",
    "羂",
    "鰹",
    "犬奴",
    "膁",
    "兼职MM",
    "兼职女",
    "兼职妹妹",
    "兼职处女",
    "兼职漂亮妹妹",
    "鯁",
    "檾",
    "茎",
    "敬国神社",
    "經文",
    "警匪一家",
    "劲射",
    "经社理事会",
    "更生日报",
    "经典炮图",
    "硬挺",
    "经济社会理事",
    "经济社会理事会",
    "经济日报",
    "輕舟快訊",
    "硬直圖騰",
    "氢弹",
    "氢弹手工制作方法",
    "莖肛",
    "經血",
    "經血",
    "慶紅",
    "莖候佳陰",
    "鸡",
    "雞8",
    "雞８",
    "鶏8",
    "雞奸",
    "雞姦",
    "鶏奸",
    "鸡奸",
    "雞雞",
    "鶏鶏",
    "鸡鸡",
    "雞女",
    "鶏女",
    "계뇬",
    "계뇽",
    "鶏毛信文匯",
    "雞扒",
    "雞掰",
    "鸡掰",
    "计生委",
    "계약동거",
    "계약애인",
    "계약앤",
    "雞歪",
    "鸡歪",
    "鶏院",
    "届中央政治局",
    "届中央政治局委員",
    "계집년",
    "鷄巴",
    "雞巴",
    "雞芭",
    "雞吧",
    "鶏巴",
    "鶏吧",
    "鸡巴",
    "鸡芭",
    "鸡吧",
    "鸡巴暴胀",
    "雞八",
    "雞叭",
    "鶏八",
    "鸡八",
    "鸡叭",
    "计牌软件",
    "尻",
    "尻",
    "睾",
    "靠",
    "靠",
    "鯝",
    "高1师傅",
    "古柯碱",
    "古柯鹼",
    "고공섹스",
    "高校群体事件",
    "高校骚乱",
    "高校暴乱",
    "高级逼",
    "搞基",
    "高洁的灵魂高耀结回忆录",
    "靠你媽",
    "靠你妈",
    "搞你媽",
    "古都光",
    "高丽朴",
    "高麗棒子",
    "高丽棒子",
    "苦劳网",
    "古龍祭壇",
    "搞栗棒",
    "裤袜",
    "靠母",
    "高文謙",
    "高文谦",
    "靠背",
    "靠北",
    "搞屁",
    "搞笑图片网",
    "高树玛丽亚",
    "高树玛莉亚",
    "考试院",
    "高薪養廉",
    "搞双飞",
    "高压气枪专卖",
    "膏藥旗",
    "膏药旗",
    "靠腰",
    "靠腰",
    "고우섹스",
    "고자",
    "고자놈",
    "高自聯",
    "高自联",
    "고자새끼",
    "告全国网民书",
    "告全国网友书",
    "搞頂",
    "高潮",
    "高潮",
    "高潮内射",
    "高祖武帝文革三年",
    "高祖武帝反右三年",
    "高潮白浆",
    "高潮集锦",
    "高俊",
    "高智晟",
    "高清无码",
    "高清性愛",
    "高清颜射",
    "고추짱",
    "高治联",
    "고투에로",
    "靠爸",
    "고패티쉬",
    "고픈녀",
    "고픈여",
    "胯下呻吟",
    "고환",
    "睾丸",
    "睾丸",
    "睾丸癌",
    "嚳",
    "轂",
    "曲吉尼玛的圆寂之地",
    "曲线消魂",
    "緄",
    "滚",
    "鯀",
    "滾那嗎",
    "滾那嗎B",
    "滾那嗎老比",
    "滾那嗎瘟比",
    "滾那嗎錯比",
    "昆圖",
    "捆绑女郎",
    "輥繻",
    "辊繻",
    "골갑",
    "圣女峰",
    "圣母峰",
    "骨獅",
    "圣战",
    "圣泉学淫",
    "골통",
    "公幹",
    "公开信",
    "公告员",
    "公教报",
    "共狗",
    "共軍",
    "空氣精靈",
    "空难黑匣子",
    "贛你",
    "贛您",
    "灨你",
    "灨您",
    "贛你娘",
    "贛您娘",
    "灨你娘",
    "灨您娘",
    "共黨",
    "共党",
    "功轮法",
    "公务员考前答案",
    "空房诱情",
    "工蜂六型多管火箭系统",
    "公仆们大大的红包是少",
    "共匪",
    "共産",
    "共产极权",
    "供铲党",
    "共産黨",
    "共产党",
    "共產黨",
    "共铲党",
    "共产无赖",
    "共产邪党",
    "共產邪黨",
    "共产邪灵",
    "共產邪靈",
    "共产王朝",
    "共产专制",
    "共産主義",
    "共产主义",
    "工商时报",
    "公媳乱",
    "公安",
    "公安局",
    "公安部",
    "贡嘎",
    "共榮圈",
    "공육공",
    "工自聯",
    "工作室",
    "共殘擋",
    "共残党",
    "共残挡",
    "空姐性交",
    "工贼",
    "工程兵",
    "空中飞人",
    "공짜버그",
    "공짜충전",
    "공짜캐쉬",
    "공짜캐시",
    "公车轮奸",
    "共慘檔",
    "共惨党",
    "共惨档",
    "公测嘉奖",
    "公投",
    "恐怖份子",
    "恐怖组织",
    "恐怖主義",
    "恐怖主義",
    "恐怖主义",
    "工行门",
    "空虛的伊坤",
    "空虛之地",
    "共和",
    "过渡政府",
    "科萊爾",
    "裹本",
    "과부촌",
    "戈瑞爾德",
    "戈揚",
    "跨下呻吟",
    "科学发展势如涛",
    "郭羅基",
    "郭伯雄",
    "郭岩華",
    "郭俊銘",
    "綰",
    "管理員",
    "管理員",
    "管理员",
    "管理人員",
    "管理人员",
    "管理者",
    "管理提示",
    "灌满精液",
    "官方",
    "官方角色",
    "官方客服",
    "官方颁奖公告",
    "官方信使",
    "官方温馨公告",
    "官方人員",
    "官方帳號",
    "官方專員",
    "官方提示",
    "官方測試",
    "官方通知",
    "官方喜讯",
    "官商勾结",
    "官商勾结我无罪",
    "冠西",
    "观世音",
    "菅野亚梨沙",
    "观音法门",
    "观察者网",
    "關卓中",
    "貫通兩極法",
    "官逼民反",
    "冠希",
    "鴰",
    "狂幹",
    "鄺錦文",
    "광년",
    "광뇨늬",
    "광뇨니",
    "광뇬",
    "光明网",
    "廣聞",
    "光环背后的周恩来",
    "纊繴",
    "光復廣州",
    "光复广州",
    "光復香港",
    "光復香港",
    "光复香港",
    "光复香港",
    "狂插",
    "狂乳激揺",
    "狂操",
    "狂操",
    "狂操你全家",
    "狂操你全家",
    "狂抽猛送",
    "咼",
    "詿",
    "拐卖",
    "嶠",
    "撟",
    "鞽",
    "矯健的馬努爾",
    "交媾",
    "翘臀",
    "翘臀嫩逼",
    "翘臀嫩穴",
    "交配",
    "姣西",
    "喬石",
    "乔石",
    "教養院",
    "教育部",
    "巧淫奸戏",
    "喬伊",
    "咬着龟头",
    "交清电子报",
    "橋侵襲兵",
    "交通部",
    "侨办",
    "교환부부",
    "交换夫妻",
    "狡猾的達夫",
    "狡猾的风水相师",
    "㈨",
    "傴",
    "屨",
    "覯",
    "韝",
    "狗b",
    "九ping",
    "狗干",
    "狗幹的",
    "勾结奸商",
    "龜公",
    "口交",
    "口交",
    "口交放尿",
    "口交颜射",
    "口交爆浆",
    "九九情色",
    "狗娘",
    "狗娘養的",
    "狗娘養的",
    "狗娘养的",
    "口内爆射",
    "龜頭",
    "龜頭",
    "舊斗篷哨兵",
    "狗卵",
    "狗卵子",
    "狗狼養的",
    "구멍에쑤실까",
    "欧美巨炮",
    "欧美大乳",
    "欧美无套",
    "欧美美女禁图",
    "欧美幼女",
    "欧美人体",
    "狗屁",
    "狗屁",
    "口射",
    "狗誠",
    "龜孫子",
    "丘垂貞",
    "狗屎",
    "狗屎",
    "鳩屎",
    "九十三运动",
    "龜兒子",
    "龜兒子",
    "狗養",
    "九月十八日",
    "口淫",
    "勾引",
    "狗日",
    "狗日的",
    "狗日的",
    "九一評",
    "九一评",
    "狗雜種",
    "狗操",
    "狗操卖逼",
    "欧洲无码",
    "龜投",
    "九評",
    "九评",
    "口暴",
    "口爆",
    "口爆颜射",
    "口爆乳交",
    "口爆吞精",
    "口爆吃精",
    "抠穴",
    "勾魂少妇",
    "口活",
    "寇晓伟",
    "瞘",
    "緱",
    "鸠",
    "龟公",
    "龟头",
    "龟孙子",
    "鸠屎",
    "龟儿子",
    "國家機密",
    "国家领导人关系网",
    "国家领导人最强组合",
    "国家民委",
    "国家发展与改革委员会",
    "国家发展和改革委员会",
    "國家安全",
    "国家安全局",
    "国家的前途和历史",
    "国家主席",
    "国家主要部委",
    "国管局",
    "國軍",
    "国企改革",
    "国内动态详情",
    "国内动态清样",
    "国务院",
    "国务委员",
    "国民党",
    "国民党万岁",
    "国民大会",
    "国防科工委",
    "国防部",
    "国产AV",
    "国产盗摄",
    "国产无码",
    "国产性交",
    "国产骚货",
    "国产淫女",
    "国产偷拍",
    "国产嫖娼",
    "国安局",
    "国语运动",
    "国语日报",
    "國賊",
    "国际法院",
    "국제성인마트",
    "国际广播的电视节目",
    "国际援藏网",
    "国耻",
    "国统会",
    "菊花洞",
    "菊花蕾",
    "菊花蚕",
    "群P",
    "群P淫虐",
    "群奸",
    "群奸乱交",
    "群奸轮射",
    "群交",
    "群交",
    "群交记",
    "群交亂舞",
    "军国主义",
    "軍妓",
    "軍妓",
    "军妓",
    "裙内偷拍",
    "群魔色舞",
    "群射",
    "军用手枪出售",
    "军委",
    "群阴会",
    "军长发威",
    "裙底盗摄",
    "军转",
    "军转干",
    "裙下风光",
    "詘",
    "굿보지",
    "굿섹스클럽",
    "弓雖",
    "弓虽女干",
    "棬",
    "綣",
    "拳交",
    "权斗",
    "权力分配",
    "蜷川香子",
    "繢",
    "撅起大白腚",
    "机掰",
    "궤쎅",
    "机要费",
    "机巴",
    "机八",
    "机叭",
    "劌",
    "귀두",
    "鬼輪姦",
    "鬼妹潮吹",
    "鬼村",
    "鬼畜轮奸",
    "嬀",
    "叫鸡",
    "叫床",
    "叫床",
    "叫小姐",
    "窥艳迷辱",
    "纠察员",
    "叫春",
    "叫春",
    "橘未稀",
    "그년",
    "그룹섹",
    "그룹섹스",
    "그즤새끼",
    "그지새끼",
    "그지새키",
    "그지좃밥",
    "그지좃빱",
    "그지좆밥",
    "郤",
    "克萊恩",
    "克萊特",
    "克勞森",
    "克林顿",
    "巹",
    "勤司领部",
    "근친",
    "근친상간",
    "近親相姦",
    "近亲相奸",
    "글빡",
    "禁忌",
    "錦濤",
    "锦涛",
    "禽流感",
    "禁书",
    "禽獸",
    "今日悉尼",
    "禁片",
    "给你爽",
    "给胡温的一封信",
    "掯",
    "肯尼特",
    "긍친",
    "妓",
    "妓",
    "機8",
    "機８",
    "技校们",
    "技校门",
    "气狗",
    "機機",
    "妓男",
    "妓女",
    "妓女",
    "記念64",
    "記念六四",
    "騎你",
    "基督",
    "基督教",
    "其美仁增",
    "气象局",
    "起诉",
    "妓院",
    "妓院",
    "奇淫宝鉴",
    "起义",
    "基地组织",
    "기집년",
    "汽车解码器",
    "气枪",
    "气枪的制造结构图",
    "幾巴",
    "幾芭",
    "機巴",
    "幾八",
    "幾叭",
    "機八",
    "기형아",
    "企劃人員",
    "企劃專員",
    "记念64",
    "记念六四",
    "骑你",
    "骑她",
    "骑他",
    "紧缚凌辱",
    "緊縛調教",
    "紧穴",
    "吉跋猫",
    "吉田美香子",
    "吉沢明步",
    "吉泽明步",
    "金戈铁马",
    "金洞寻钻",
    "金鳞岂是池中物",
    "金毛穴",
    "金猫探险",
    "金瓶梅",
    "金凤玉露",
    "金山桥",
    "金城汤池",
    "金镶玉艳",
    "金堯如",
    "金融时报",
    "金融邮报(台湾股网)",
    "金日成",
    "金正恩",
    "金正日",
    "金槍不倒",
    "金枪消魂",
    "金醉纸迷",
    "김치녀",
    "김치년",
    "金泽文子",
    "金澤辰",
    "까진년",
    "깔다구",
    "깔따구",
    "깔치",
    "깝사지",
    "깝쳐",
    "깝쵸",
    "깝치냐",
    "깝치네",
    "깝치지",
    "깝친다",
    "깝칠래",
    "깨띠올려",
    "깨쉑",
    "깨쉬얼려",
    "깨쉬올려",
    "깨시얼려",
    "깨쌔꺄",
    "깨쌔끼",
    "깽깽이",
    "꺵판",
    "꺼저",
    "꺼져",
    "꺼져??",
    "꺼추",
    "껍닥",
    "께세",
    "꼬져",
    "꼬죠",
    "꼬추",
    "꼰창",
    "꼴갑",
    "꼴값",
    "꼴갚",
    "꼴깝",
    "꼴려",
    "꼴리네",
    "꼴리는",
    "꼴리다",
    "꼴린다",
    "꼴캅",
    "꼴통",
    "꼴통새끼",
    "꼽냐",
    "끄져",
    "끄지라",
    "끈팬티",
    "儺",
    "那娘錯比",
    "나너먹을께",
    "나랑할래",
    "나를싸게",
    "나를흥분",
    "那嗎B",
    "那嗎老比",
    "那嗎瘟比",
    "那嗎錯比",
    "那嗎逼",
    "나먹어라",
    "나먹어봐",
    "나먹어줘",
    "나쁜년",
    "나체",
    "拿破仑",
    "나흥분",
    "諾姆",
    "낙태충",
    "난교",
    "난봉일기",
    "난자마셔",
    "난자먹어",
    "난자핧아",
    "捏你鶏巴",
    "捏弄",
    "날흥분",
    "南疆",
    "男根",
    "男女公关",
    "男女交欢",
    "남녀섹시속옷",
    "남녀자위기구",
    "男女自慰用品",
    "男女蒲典",
    "男奴",
    "南大自由論壇",
    "南联盟",
    "南蛮",
    "南蠻子",
    "南蛮子",
    "南部のシャケの鼻まがり",
    "南沙",
    "南鮮",
    "남성단련용품",
    "남성자위기구",
    "南洋视界",
    "男人雄风",
    "南朝鮮",
    "南早中文网",
    "南波杏",
    "南港",
    "南海",
    "南海群岛",
    "南海岛屿",
    "南海诸岛",
    "南海仲裁",
    "南华早报",
    "納粹",
    "纳粹",
    "娘姦白书",
    "娘餓比",
    "內褲",
    "내꺼빨아",
    "내꺼핧아",
    "奶娘",
    "奶大屄肥",
    "奶頭",
    "奶頭",
    "奶头",
    "内幕",
    "内幕第28期",
    "내미랄",
    "내미럴",
    "내버지",
    "내보지",
    "內射",
    "内射图",
    "内射美妇",
    "内射骚B",
    "内射片",
    "内射暴浆",
    "내씨발",
    "內衣",
    "奶子",
    "奶子",
    "奶子酥",
    "내자지",
    "내잠지",
    "奶挺臀翘",
    "奶罩",
    "내조지",
    "너거애비",
    "너검마",
    "넣게벌려",
    "넣고싶어",
    "넣고싸고",
    "넣어줘",
    "네다홍",
    "네버섹스",
    "네에미",
    "넴병",
    "女幹",
    "女犬",
    "女尻",
    "女奴",
    "女馬白勺",
    "女体拷问",
    "女屄",
    "女性丝袜网",
    "女肉馆",
    "女主人羅姬馬",
    "女主人羅姬馬莉",
    "년놈",
    "년아",
    "년의자식",
    "노노무라",
    "奴隸魔族士兵",
    "奴隷调教",
    "노무",
    "노무노무",
    "노브라",
    "노알라",
    "노운지",
    "奴畜抄",
    "노콘",
    "노팬츠",
    "노팬티",
    "놈의자식",
    "놈현",
    "浓硫酸",
    "농아",
    "农业部",
    "农业电子报",
    "儂著岡巒",
    "儂著卵拋",
    "浓精",
    "尿",
    "嫐屄",
    "闹事",
    "뇨온",
    "뇬",
    "누나강간",
    "누나몸",
    "누드",
    "누드몰카",
    "누드뮤직",
    "누드사진",
    "누드섹스",
    "누드쇼",
    "누드스케치",
    "누드집",
    "누드천사",
    "누드커플",
    "누드클럽",
    "누드필름",
    "누드화보",
    "嫩b",
    "嫩BB",
    "눈깔",
    "嫩奶",
    "嫩女",
    "嫩咪咪",
    "嫩缝",
    "嫩屄",
    "嫩骚",
    "嫩乳美鲍",
    "嫩乳鲜鲍",
    "嫩鲍",
    "嫩鲍鱼",
    "嫩逼",
    "嫩穴",
    "嫩穴内射",
    "嫩穴肉缝",
    "嫩穴中出",
    "뉘기미",
    "뉘뮈",
    "뉘미",
    "뉘미럴",
    "뉭기리",
    "뉭기미",
    "妞妞基地",
    "纽约时报",
    "느검마",
    "느그매",
    "느그애미",
    "느그애비",
    "느그엄마",
    "能樣",
    "늬//미",
    "늬;1미",
    "늬귀미",
    "늬기미",
    "늬믜",
    "늬믜창",
    "늬미",
    "禰",
    "你敢上吗",
    "니귀미",
    "尼克松",
    "你今天三退了",
    "你給狗幹",
    "니기미",
    "你娘",
    "你娘",
    "你娘可好",
    "你娘卡好",
    "妳娘的",
    "妳娘的",
    "你奶奶的",
    "你大爷",
    "你老母",
    "妳老母",
    "妳老母的",
    "妳老母的",
    "你老味",
    "伱妈",
    "你媽",
    "你妈",
    "你媽了妹",
    "你妈了妹",
    "你媽比",
    "你馬的",
    "你媽的",
    "你媽的",
    "你妈的",
    "妳馬的",
    "妳妈的",
    "妳马的",
    "妳媽的幹",
    "你媽逼",
    "你妈逼",
    "니메랄",
    "你姥",
    "你姥",
    "你姥姥的",
    "你姥姥的",
    "你母卡好",
    "妳母親",
    "니뮈",
    "니믜",
    "니미",
    "니미기",
    "니미랄",
    "니미럴",
    "니미롤",
    "니미를",
    "니보지",
    "你色吗",
    "你說我說論壇",
    "니씨브랄",
    "니ㅇㅐ미",
    "니아배",
    "니아범",
    "니아범?",
    "니아베",
    "니아비",
    "니애미",
    "니애비",
    "니앰",
    "你爺",
    "你爷爷的",
    "니어매",
    "니어메",
    "니어미",
    "니엄",
    "니에미",
    "니에비",
    "니엠",
    "尼奧夫",
    "你二大爷",
    "니이미",
    "你祖宗",
    "니좆",
    "니죠때",
    "니죶데",
    "니주가리",
    "你爸",
    "니할애비",
    "溺れ死ぬ",
    "님이랄",
    "님이럴",
    "닝그미",
    "닝기럴",
    "닝기미",
    "다리벌려",
    "다리벌리",
    "다리사이",
    "다보자성인영화관",
    "다음섹스",
    "다이섹스",
    "多P轮奸",
    "多P杂交",
    "多毛屄",
    "多维",
    "多维网",
    "多维新闻",
    "多维新闻网",
    "多人轮",
    "多人性愛",
    "多人淫乱",
    "多汁嫩鲍",
    "닥;쳐",
    "닥1쳐",
    "닥쳐",
    "닥쳐라",
    "닥쵸",
    "닥치라",
    "닥치셔",
    "닦쳐",
    "닦쵸",
    "摶",
    "단란주점",
    "團員馬爾汀",
    "團長戈登",
    "丹增嘉措",
    "团派",
    "闥",
    "달달이",
    "달딸이",
    "達癩",
    "達賴喇嘛萬歲",
    "達賴喇嘛復興之路",
    "達夫警衛兵",
    "達夫侍從",
    "닭쳐",
    "닭쵸",
    "닳은년",
    "谭力情妇",
    "谭笑笑",
    "答复口径",
    "氹",
    "唐家璇",
    "党禁",
    "当今八大谎言",
    "唐柏橋",
    "唐山大地震老伤兵",
    "档案局",
    "当雄",
    "党主席",
    "党中央",
    "唐达献",
    "大b",
    "大家論壇",
    "대가리",
    "대가리이",
    "대가빡",
    "대갈",
    "대갈빡",
    "대갈치",
    "대갈통",
    "代开",
    "代开建筑发票",
    "代开国税发票",
    "代开发票",
    "代开普通发票",
    "代开服务发票",
    "代开商品发票",
    "代开安装发票",
    "代开广告发票",
    "代开业务",
    "代开运输发票",
    "代开维修发票",
    "代开租赁发票",
    "代开地税发票",
    "代开餐饮发票",
    "大雞歪",
    "大雞巴",
    "大鶏巴",
    "大鸡巴",
    "代考",
    "大纪园",
    "大紀元",
    "大紀園",
    "大纪元",
    "大纪元网",
    "大纪元时报",
    "大紀元新聞網",
    "大纪元新闻网",
    "大起义",
    "大奶鸡",
    "大奶嫩逼",
    "大奶头",
    "大奶媽",
    "大奶妹",
    "大奶美逼",
    "大奶骚女",
    "大奶骚妹",
    "大嫩逼",
    "大胆少女",
    "大胆出位",
    "大東亞",
    "大東亞共榮",
    "대딸",
    "大亂交",
    "大乱交",
    "大乱性",
    "大力抽送",
    "代練",
    "代炼",
    "代练",
    "待练",
    "玳炼",
    "玳练",
    "贷炼",
    "大陆高干子弟名单",
    "代理各种证件",
    "代理开发票",
    "大麻",
    "大麻",
    "大麻树脂",
    "大麻油",
    "臺灣建國運動",
    "臺灣建國運動組織",
    "臺灣共産黨",
    "臺灣狗",
    "臺灣獨",
    "臺灣獨立",
    "臺灣民國",
    "臺灣自由聯盟",
    "臺灣政論區",
    "臺灣帝國",
    "臺灣青年獨立",
    "臺灣青年獨立聯盟",
    "臺盟",
    "대박성인토탈몰",
    "大法",
    "大比",
    "大肥逼",
    "大师",
    "大事件",
    "大史紀",
    "大史記",
    "戴相龍",
    "大色哥",
    "大召寺",
    "大昭寺",
    "大骚逼",
    "大宋艳情史之深宫情难禁",
    "大阅兵背后的政治玄机",
    "对越自卫",
    "大衛教",
    "大卫教",
    "大乳",
    "戴維教",
    "戴维教",
    "대음순",
    "大陰唇",
    "大一紀一元",
    "大一纪一元",
    "大藏区",
    "戴晶",
    "待操",
    "大潮吹",
    "대준다",
    "대줄께",
    "대줄년",
    "大中國論壇",
    "大众色情成人网",
    "大衆真人真事",
    "大中華論壇",
    "大參考",
    "大参考",
    "大尺右香",
    "大屌",
    "大屌内射",
    "大屌小穴",
    "带套",
    "带套肛交",
    "大波",
    "大波翘臀",
    "大波浪",
    "大波粉B",
    "大波骚妇",
    "大波波",
    "代办员",
    "代办证件",
    "大便",
    "大便",
    "대포통장",
    "대포폰",
    "大逼",
    "大学生保卫粤语",
    "大学骚乱",
    "戴海静",
    "大血B",
    "大血比",
    "大湖帮",
    "戴红",
    "大花逼",
    "더러운년",
    "더어엉신",
    "더엉신",
    "더티섹스",
    "德国之声中文网",
    "德桑次仁",
    "德維爾",
    "덜은새끼",
    "덥치기",
    "덥침",
    "덩신",
    "덮치기",
    "덮침",
    "데져라",
    "데진다",
    "데질래",
    "鞀",
    "图库",
    "图公园",
    "倒挂金钩",
    "道教",
    "赌球",
    "도그플",
    "跳蛋",
    "跳大神",
    "挑逗",
    "도란년",
    "度冷丁",
    "堵路",
    "跳楼",
    "도른것",
    "도른넘",
    "도른년",
    "도른뇬",
    "도른뇸",
    "도른뇽",
    "도른늠",
    "赌马",
    "逃亡艳旅",
    "赌博",
    "淘宝",
    "導師",
    "陶驷驹",
    "屠殺",
    "屠殺",
    "屠杀",
    "屠杀维吾尔人",
    "屠杀藏人",
    "圖書管理員卡",
    "圖書管理員卡特",
    "盜攝",
    "屠城",
    "倒烧蜡烛",
    "度搜",
    "渡搜",
    "都市日报",
    "桃园蜜洞",
    "道淫",
    "盜竊犯",
    "盗窃犯",
    "徒弟会",
    "盗撮",
    "도쿄섹스",
    "도쿄섹스NIPON",
    "导弹",
    "倒扁",
    "赌恒指",
    "桃穴",
    "碡",
    "毒龙",
    "独龙转",
    "毒龙舔脚",
    "独立",
    "獨立臺灣會",
    "独立台湾会",
    "獨夫",
    "獨裁",
    "独裁",
    "獨裁政治",
    "独派",
    "毒品",
    "돈년",
    "돈새끼",
    "돈선불",
    "돈필요한",
    "돌개이",
    "突尼斯",
    "돌대가리",
    "돌림빵",
    "돌으년",
    "돌은것",
    "돌은넘",
    "돌은년",
    "돌은놈",
    "돌은뇬",
    "돌은뇸",
    "돌은새끼",
    "突点",
    "돔줘요",
    "동거",
    "同居",
    "동거녀",
    "同居万岁",
    "董建华",
    "東南西北論談",
    "东突",
    "東突獨立",
    "东突组织",
    "東突暴動",
    "东突暴动和独",
    "东突暴动和独立",
    "動亂",
    "动乱",
    "动漫色图",
    "东网",
    "同盟",
    "董文华",
    "懂文华",
    "东方闪电",
    "東方時空",
    "东方日报",
    "東方紅時空",
    "東部地下水路",
    "东北XX",
    "东北xx网",
    "東北獨立",
    "东北独立",
    "东北成人",
    "东北风情熟女之惑",
    "胴体",
    "東社",
    "동생강간",
    "東西南北論壇",
    "同性戀",
    "同性恋",
    "同修",
    "東升",
    "东升",
    "疼讯",
    "東亞",
    "东亚病夫",
    "东亚日报",
    "童颜巨乳",
    "東洋巨乳",
    "東洋屄",
    "东热空姐",
    "东热炮图",
    "东瀛绳艺",
    "東院看守",
    "东伊运",
    "同一教",
    "东正教",
    "東條",
    "东条英机",
    "東條英機",
    "童增",
    "同志用品",
    "董贱华",
    "动态网",
    "東土耳其斯坦",
    "东土耳其斯坦信息中心",
    "东海拔棒",
    "动向杂志",
    "돼진다",
    "되가리",
    "되져라",
    "되질래",
    "됻밥",
    "头交片",
    "두두섹스",
    "杜冷丁",
    "頭領墳墓管理",
    "頭領墳墓管理員",
    "頭領奧馬",
    "杜鲁门",
    "鬥士霍克",
    "鬥士哈夫拉蘇",
    "头条日报",
    "窀",
    "臀沟",
    "臀浪",
    "臀推",
    "둥신",
    "뒈져",
    "뒈져라",
    "뒈죠라",
    "뒈지",
    "뒈진다",
    "뒈질래",
    "뒤길넘",
    "뒤길놈",
    "뒤로너어줘",
    "뒤이치기",
    "뒤져",
    "뒤져라",
    "뒤죠",
    "뒤즬",
    "뒤진다",
    "뒤질",
    "뒤질래",
    "뒤치기",
    "뒷잇치기",
    "뒷치기",
    "뒹신",
    "드러운년",
    "드런년",
    "드런놈",
    "謄",
    "鄧笑貧",
    "邓小平",
    "邓小平讲英语",
    "邓小平访美",
    "腾讯",
    "腾讯币",
    "邓爷爷的预言",
    "邓派",
    "登輝",
    "디랄",
    "디지털섹스조선",
    "디진다",
    "디질",
    "디질래",
    "딜도",
    "딥콜",
    "딩쉰",
    "딩신",
    "따굴",
    "따먹",
    "따먹고",
    "따먹기",
    "따먹는",
    "따먹는다",
    "따먹어",
    "따먹어야지",
    "따먹었어",
    "따먹었지",
    "따먹을까",
    "따먹자",
    "따먹혀줄래",
    "따무라",
    "따묵기",
    "따아알따리",
    "따아알따아리",
    "따알따리",
    "따이고시퍼",
    "딱지떼자",
    "딱쳐",
    "딱쵸",
    "딸 달 이",
    "딸 따 리",
    "딸구",
    "딸달이",
    "딸따뤼",
    "딸따리",
    "딸딸",
    "딸딸이",
    "딸치",
    "딸치는",
    "때꺄",
    "때놈",
    "떠라이",
    "떡걸",
    "떡치기",
    "떡칠녀",
    "떨아이",
    "떵개",
    "떵싸구",
    "또라이",
    "또오라이",
    "똘똘이",
    "똥고",
    "똥구멍",
    "똥꼬",
    "똥꼬따줘",
    "똥꾸녕",
    "똥닦다",
    "똥대가리",
    "똥쳐먹다",
    "뛰발",
    "뛰봘",
    "뜨건밤",
    "뜨발",
    "뜨벌",
    "띠바",
    "띠박",
    "띠발",
    "띠발뇬",
    "띠방",
    "띠방세",
    "띠볼뇬",
    "띠부랄",
    "띠부럴",
    "띠불",
    "띠불뇬",
    "띱때",
    "띱새",
    "腡",
    "罗 干",
    "懶8",
    "羅幹",
    "罗干",
    "骡干",
    "懶較",
    "懶教",
    "懒教",
    "懶叫",
    "라노비아섹스샵",
    "羅禮詩",
    "裸露自拍",
    "裸聊",
    "裸聊服务",
    "萝莉girl",
    "喇嘛",
    "羅文嘉",
    "裸陪",
    "癩病",
    "羅福助",
    "裸体",
    "裸体两肖",
    "裸体码",
    "裸体少妇",
    "罗荣桓",
    "裸欲",
    "라이브섹스",
    "라이브스트립",
    "裸照",
    "羅志明",
    "懶趴",
    "懶八",
    "濼",
    "洛克菲爾特",
    "骆冰淫传",
    "卵",
    "爛",
    "爛B",
    "爛B",
    "烂B",
    "亂交",
    "亂交",
    "乱交",
    "爛袋",
    "亂倫",
    "亂倫",
    "亂輪",
    "乱伦",
    "乱伦群交",
    "乱伦小说",
    "乱伦熟女网",
    "爛比",
    "爛屄",
    "烂比",
    "烂屄",
    "亂性",
    "烂游戏",
    "烂人",
    "卵子",
    "卵子",
    "蘭迪",
    "烂鸟",
    "爛屌",
    "烂屌",
    "爛逼",
    "爛逼",
    "烂逼",
    "爛貨",
    "爛貨",
    "烂货",
    "垃圾游戏",
    "垃圾站",
    "濫B",
    "濫交",
    "滥交",
    "览叫",
    "濫比",
    "蓝营",
    "滥情",
    "藍椒",
    "攬趴",
    "濫逼",
    "濫貨",
    "繿纁繼",
    "拉登",
    "拉姆斯菲爾德",
    "拉姆斯菲尔德",
    "拉卜楞寺",
    "拉萨江苏路",
    "拉萨北京路",
    "拉萨烟火缭绕",
    "拉萨暴乱",
    "拉皮條",
    "狼群发布",
    "浪叫",
    "浪女",
    "浪臀",
    "浪妇",
    "浪屄",
    "狼色论坛",
    "浪骚逼",
    "狼友",
    "浪穴",
    "浪穴淫逼",
    "来干",
    "来插我",
    "来爽我",
    "萊特",
    "亮b",
    "两个待遇",
    "兩個中國",
    "两个中国",
    "两国论",
    "量大优惠",
    "凉木桃香",
    "亮屄",
    "晾屄",
    "晾骚",
    "粮食局",
    "兩岸關係",
    "两岸边才子对话",
    "兩岸三地論壇",
    "两腿之间",
    "亮逼",
    "亮穴",
    "两穴轮奸",
    "兩會",
    "兩會報道",
    "兩會新聞",
    "러브박사성인용품",
    "러브베드",
    "러브섹시클럽",
    "러브하자성인용품",
    "러브호텔",
    "러시아걸",
    "레드섹스TV",
    "呂京花",
    "旅馆自拍",
    "呂秀蓮",
    "吕秀莲",
    "黎阳评",
    "俪影蝎心",
    "旅游局",
    "驴屌",
    "丽春苑",
    "轢",
    "躒",
    "历史不会忘记",
    "力挺360",
    "联系QQ",
    "煉功",
    "联大",
    "連方瑀",
    "联席会议",
    "连続失禁",
    "連勝德",
    "連勝文",
    "联易",
    "联易互动",
    "恋孕",
    "連戰",
    "连战",
    "聯總",
    "联合国",
    "联合国大会",
    "联合报",
    "联合新闻",
    "联合新闻网",
    "联合早报",
    "連惠心",
    "莲花逼",
    "鋝",
    "列宁",
    "奩",
    "薟",
    "廉政大論壇",
    "零8宪",
    "令计划",
    "领导神宠",
    "灵灵教",
    "另类淫乱",
    "铃木麻奈美",
    "灵仙真佛宗",
    "零捌章",
    "零捌政",
    "零八宪z",
    "零八宪zhang",
    "零八憲章",
    "零八宪章",
    "零捌宪章",
    "零八宪政",
    "零捌宪政",
    "鱧",
    "禮品發放員",
    "輅",
    "撸",
    "劳+教+所",
    "露B",
    "露B照",
    "老江",
    "勞改",
    "露脸自拍",
    "露股",
    "撸管子",
    "勞教",
    "劳教信息公开申请书",
    "卢沟桥",
    "老奶奶",
    "露女",
    "勞動教養所",
    "劳动保障部",
    "劳拉",
    "로린이",
    "老母",
    "老母",
    "露毛",
    "露毛",
    "老毛子",
    "老鴇",
    "老屄",
    "露屄",
    "盧西德",
    "老少乱伦",
    "路易",
    "露阴照",
    "老子操你",
    "盧卡",
    "露底",
    "露点",
    "露點",
    "老土",
    "路透社",
    "老婆穴",
    "老逼",
    "露逼",
    "露逼照",
    "老汉推车",
    "露穴",
    "老虎机",
    "鹿城娱乐",
    "氯胺酮",
    "绿营",
    "論壇管理員",
    "誄",
    "雷管",
    "雷管制作",
    "雷尼亞",
    "賴士葆",
    "赖昌星",
    "濑户由衣",
    "雷汞",
    "鬧事",
    "廖錫龍",
    "撩人",
    "聊斋艳谭",
    "龙 虎 门",
    "龍碼閣",
    "龙狮旗",
    "龙狮旗",
    "龙狮香港旗",
    "龙狮香港旗",
    "龙新民",
    "龙应台",
    "龙虎",
    "龙虎门",
    "龍虎豹",
    "龍火之心",
    "漏毛",
    "楼凤",
    "漏底",
    "漏逼",
    "룸사롱",
    "룸살롱",
    "룸섹스",
    "룸쌀롱",
    "鷚",
    "刘 淇",
    "劉剛",
    "劉凱中",
    "溜光巨炮",
    "劉國凱",
    "刘军",
    "刘淇",
    "刘金国",
    "刘德",
    "刘德华和胡主席",
    "流泪的沈阳",
    "流亡政府",
    "流氓",
    "劉文勝",
    "劉文雄",
    "流蜜汁",
    "刘伯承",
    "劉賓深",
    "劉賓雁",
    "柳丝事件",
    "劉士賢",
    "劉山青",
    "刘少奇",
    "劉永川",
    "留园网",
    "刘云山",
    "流淫",
    "劉俊國",
    "劉千石",
    "劉青",
    "柳叶逼",
    "劉華清",
    "劉曉竹",
    "劉曉波",
    "刘晓波",
    "刘晓波获奖",
    "㈥",
    "六.四",
    "陆.肆",
    "六。四",
    "六?四",
    "六4事件",
    "六四",
    "六-四",
    "六四屠夫",
    "六四录音记录",
    "六四事件",
    "六四要平反",
    "六四运动",
    "六四真相",
    "六四学生领袖",
    "六四學潮",
    "六四学潮",
    "陆雪琴",
    "六星合彩",
    "六星合彩公司",
    "六月联盟",
    "陸委會",
    "六合彩",
    "六合菜",
    "六合采",
    "六合彩券",
    "六和采",
    "輪奸",
    "輪奸",
    "輪姦",
    "轮奸",
    "轮干",
    "轮奸内射",
    "倫功",
    "輪公",
    "輪功",
    "輪攻",
    "掄功",
    "伦功",
    "抡功",
    "轮公",
    "轮功",
    "轮攻",
    "輪大",
    "轮流干",
    "轮盘赌",
    "轮操",
    "轮暴",
    "鰳",
    "綾乃梓",
    "淩鋒",
    "凌辱",
    "淩辱",
    "李磕墙",
    "李慶安",
    "李慶華",
    "李繼耐",
    "李宏志",
    "李宏旨",
    "李克强",
    "里根",
    "李大师",
    "李大钊",
    "李登辉",
    "李蘭菊",
    "李岚清",
    "李老師",
    "李祿",
    "李錄",
    "里番",
    "李鹏",
    "李鹏*",
    "李鵬64",
    "李鹏64",
    "李鹏日记vs赵紫阳录音",
    "裏菲斯",
    "李四光的四大地震预言",
    "李四光的第四预言",
    "李山",
    "李常受",
    "李瑞环",
    "李先念",
    "李小鵬",
    "李淑嫻",
    "리얼섹",
    "리얼섹스플레이",
    "리얼에로",
    "李旺陽",
    "李源潮",
    "李月月鳥",
    "李义强",
    "李意珍",
    "裏日本",
    "李长春",
    "离长春",
    "李肇星务",
    "李志綏",
    "李鐵映",
    "李铁映",
    "李總理",
    "李總統",
    "李洪寬",
    "李弘旨",
    "李洪志",
    "李红志",
    "李洪志师父",
    "李紅痔",
    "李红痔",
    "躪",
    "林佳龍",
    "林肯",
    "淋病",
    "林保華",
    "林信義",
    "林业局",
    "林長盛",
    "林正勝",
    "林重謨",
    "林芝野战军",
    "林彪",
    "立法委员",
    "립서비스",
    "立花瞳",
    "立花里子",
    "링기미",
    "媽B",
    "媽B",
    "妈B",
    "马加爵",
    "媽個B",
    "麻古",
    "麻咕",
    "馬克思",
    "马克思",
    "마니아섹스",
    "馬大維",
    "摩洛客",
    "马拉戈壁",
    "馬良駿",
    "瑪麗亞",
    "媽了個b",
    "媽了個逼",
    "马勒戈壁",
    "媽賣媽屁",
    "妈卖妈屁",
    "马卖马屁",
    "摩门教",
    "吗啡",
    "嗎啡",
    "嗎啡",
    "吗啡碱",
    "嗎啡鹼",
    "吗啡片",
    "嗎啡片",
    "码报",
    "马报",
    "媽批",
    "媽比",
    "媽比",
    "妈批",
    "妈比",
    "馬三家",
    "麻生早苗",
    "馬時敏",
    "瑪雅",
    "玛雅网",
    "마약",
    "马营",
    "馬英九",
    "马英九",
    "馬永成",
    "蚂蚁事件",
    "蚂蚁养殖户",
    "蚂蚁的思考",
    "媽的",
    "媽的",
    "吗的",
    "嗎的",
    "嗎的",
    "妈的",
    "马的",
    "媽的B",
    "妈祖",
    "马祖日报",
    "码中特",
    "麻醉钢枪",
    "麻醉药",
    "麻醉乙醚",
    "麻醉枪",
    "麻醉枪货到付款",
    "馬特斯",
    "妈逼",
    "磨肛",
    "馬化騰",
    "馬化腾",
    "马化騰",
    "马化腾",
    "麻黄素",
    "막간년",
    "막간놈",
    "막간뇬",
    "막간뇸",
    "막대쑤셔줘?",
    "막대핧아줘",
    "莫索里尼",
    "莫偉强",
    "卍",
    "蹣",
    "만나서하자",
    "晚年周恩來",
    "晚年周恩来",
    "曼德拉",
    "馒头屄",
    "馒头穴",
    "萬里",
    "万利大造林",
    "瞞報",
    "万三退",
    "萬三退",
    "万税",
    "漫游",
    "萬維讀者論壇",
    "万维读者网",
    "万维博客",
    "萬潤南",
    "万淫堂",
    "만져볼래",
    "만져주께",
    "만져줄년",
    "만져줄분",
    "만져줘",
    "만지고파",
    "만지는중",
    "만지면커진다",
    "만화보지",
    "萬曉東",
    "茉莉花",
    "茉莉花革命",
    "말보지",
    "말자식",
    "抹红",
    "抹黑",
    "맛간년",
    "맛간놈",
    "맛간뇬",
    "맛간뇸",
    "맛간뇽",
    "맛간늠",
    "맛간쉑",
    "맛간쉨",
    "맛없는년",
    "맛이간년",
    "网12号披露",
    "망가짱",
    "网监分局",
    "亡共者胡",
    "網管",
    "网管",
    "网络警察",
    "网络封锁",
    "网络色情网址大全",
    "网络视频妹",
    "网络人权宣言",
    "網禪",
    "网游销售话务员",
    "亡秦者胡",
    "網特",
    "맞간년",
    "勱",
    "卖.国",
    "賣B",
    "卖B",
    "卖ID",
    "卖QQ",
    "玫瑰逼",
    "賣國",
    "卖国",
    "卖国求荣",
    "売女",
    "买断工龄",
    "买断职工",
    "卖党求荣",
    "梅毒",
    "梅毒",
    "买毒品",
    "卖毒品",
    "呆卵",
    "买卖钢珠枪",
    "妹妹兼职",
    "妹妹桃色电影",
    "妹妹陪聊",
    "妹妹服务",
    "妹妹骚图",
    "妹妹阴毛",
    "妹妹的服务",
    "买卖枪支",
    "媒体公民行动网",
    "賣比",
    "卖比",
    "賣騷",
    "卖身",
    "卖软件",
    "卖外挂",
    "卖垠票",
    "卖垠骠",
    "賣淫",
    "賣淫",
    "卖淫",
    "卖淫嫖娼",
    "卖卡",
    "买财富",
    "卖财富",
    "매춘",
    "买春",
    "买春军团",
    "买春堂",
    "매춘부",
    "매친",
    "매틘",
    "賣逼",
    "卖逼",
    "卖号",
    "魅惑巨乳",
    "梅花网",
    "梅花屄",
    "麦角酸",
    "麦角酸二乙基酰胺",
    "麥克斯",
    "麦司卡林",
    "맬섭",
    "孟建柱",
    "孟宏伟",
    "猛男群交",
    "猛女爽片",
    "猛插",
    "猛操",
    "猛操狂射",
    "猛片",
    "머니필요한",
    "머니헌터",
    "머라빠너",
    "머라빠노",
    "머저리",
    "먹어줘",
    "멍청도",
    "메둥",
    "메친",
    "메틘",
    "멜돔",
    "臱缴纍",
    "缅古",
    "免费A片",
    "免费成人网站",
    "免费偷窥网",
    "면상",
    "綿恒",
    "缅货",
    "明镜网",
    "明镜新闻",
    "明镜周刊",
    "命根",
    "明报",
    "明报月刊",
    "明星新聞網",
    "明星淫图",
    "鸣枪",
    "明慧",
    "明慧图片网",
    "明慧網",
    "明慧网",
    "明慧广播电台",
    "明慧资料馆",
    "明慧周刊",
    "明慧周末",
    "明慧周报",
    "明慧学校",
    "毛XX",
    "母奸",
    "母狗",
    "摸奶",
    "母女双飞",
    "母女潮吹",
    "모노섹스",
    "摸你鶏巴",
    "毛唐",
    "摸咪咪",
    "毛盘",
    "冒白浆",
    "毛屄",
    "毛屄图片",
    "谋杀",
    "摸阴蒂",
    "毛一鲜",
    "母子奸情",
    "母子奸情屏蔽",
    "母子交媾",
    "母子交欢",
    "毛賊",
    "毛賊東",
    "毛贼东",
    "毛主席",
    "毛主席复活",
    "毛主席临终前把小平",
    "毛剃り",
    "暮春堂",
    "毛厠洞",
    "毛厕洞",
    "毛泽东",
    "毛澤東荒淫糜爛",
    "毛澤荒淫糜爛",
    "모텔",
    "毛派",
    "毛片",
    "毛鲍",
    "摸胸",
    "穆斯林",
    "穆斯林兄弟会",
    "木耳",
    "木子論壇",
    "穆罕默德",
    "沒懶叫",
    "沒卵叫",
    "沒屁眼",
    "沒有共鏟黨才有新中國",
    "没有共铲党才有新中国",
    "몰카",
    "몸뚱이줄께",
    "몸보여",
    "몸안에사정",
    "몸좀",
    "몸캠",
    "몸켐",
    "몸파는",
    "몸파라",
    "몸팔러",
    "蒙古達子",
    "蒙古鞑子",
    "蒙古獨",
    "蒙古獨立",
    "蒙古独立",
    "蒙古达子",
    "蒙獨",
    "蒙独",
    "梦遗",
    "蒙藏委员会",
    "蒙尘药",
    "猫女凤舞",
    "嘸",
    "无界浏览器",
    "無界瀏覽器",
    "武藤兰",
    "무료망가",
    "무료몰카",
    "무료성인",
    "무료성인동영상",
    "무료성인만화",
    "무료성인방송",
    "무료성인싸이트",
    "무료성인엽기",
    "무료성인영화",
    "무료성인정보",
    "무료섹스",
    "무료섹스동영상",
    "무료섹스사이트",
    "무료야설",
    "무료포르노",
    "무료포르노동영상",
    "无码",
    "無碼H漫",
    "無碼電影",
    "无毛美少女",
    "无毛屄",
    "无毛少女",
    "无毛逼",
    "無毛穴",
    "妩媚挑逗",
    "无帮过",
    "无邦国",
    "无帮国",
    "무삭제원판",
    "무전망가",
    "无罪T恤青年",
    "无套内射",
    "无套自拍",
    "无套中出",
    "无套爆操",
    "无限充血",
    "默克尔",
    "墨索里尼",
    "默罕默德",
    "嘿咻",
    "閿",
    "文强沦为死囚后",
    "纹舞兰",
    "文物局",
    "문죄인",
    "文化大革命",
    "文化部",
    "文化衫",
    "勿忘",
    "물확뺄",
    "뮈칀세끼",
    "뮈칀세리",
    "뮈칀섹끼",
    "뮈칀섹이",
    "뮈칀쉐이",
    "뮈친",
    "뮈친세리",
    "뮈친섹끼",
    "뮈친섹이",
    "뮈친쉐이",
    "뮈친쉑이",
    "믜칀",
    "믜칑",
    "믜친",
    "믜칭",
    "믜칭넘",
    "믜튄",
    "믜틘",
    "믜틴",
    "羋",
    "미.친.개.잡.년",
    "미.친.개.잡.년.이네",
    "美B",
    "迷奸",
    "迷奸系列",
    "迷奸药",
    "美尻",
    "美國",
    "美国",
    "美國佬",
    "美国佬",
    "美国有线电视频道",
    "美國之音",
    "美国之音",
    "美國參考",
    "미국포르노",
    "美女祼聊",
    "美女六肖图",
    "美女丝袜",
    "美女特码图",
    "美女好小电影",
    "미년씹",
    "美臀",
    "美臀嫰穴",
    "美臀图片网",
    "미뛴",
    "미띤",
    "미륀",
    "미륀놈",
    "美利坚",
    "美里真里",
    "미린넘",
    "미린년",
    "咪咪",
    "咪咪图片",
    "咪咪爱",
    "咪咪情色",
    "美体艳姿",
    "美屄",
    "美沙酮",
    "美丝美臀",
    "迷色堂",
    "迷歼药",
    "美骚女",
    "미소녀섹스가이드",
    "美少妇",
    "美骚妇",
    "美少妇的哀羞",
    "尾数发财诗",
    "美穗由纪",
    "미스누드",
    "미스터콘돔",
    "尾申鲸",
    "迷失少女天堂",
    "미쎄스터",
    "미씨촌",
    "迷药",
    "媚药少年",
    "美乳",
    "美幼",
    "美乳撩人",
    "媚肉",
    "媚肉",
    "美淫",
    "미이친년아",
    "美洲台湾日报",
    "美竹凉子",
    "미진 놈",
    "미진놈",
    "미찬놈",
    "미천",
    "미첫",
    "미첸년",
    "미쳤",
    "미춘",
    "미췐",
    "미췬",
    "미췬년",
    "미췬세끼",
    "미췬쉐리",
    "미췽",
    "미츤",
    "미칀",
    "미칑",
    "미치넘",
    "미치년",
    "미치인",
    "미친개보지같은년",
    "미친개잡년이네",
    "미친구녕",
    "미친구멍",
    "미친넘",
    "미친년",
    "미친년아",
    "미친놈",
    "미친놈아",
    "미친뇬",
    "미친뇸",
    "미친뇽",
    "미친눔",
    "미친늠",
    "미친새끼",
    "미친새리",
    "미친세리",
    "미친섹이",
    "미친셰리",
    "미친쉐리",
    "미친쉐이",
    "미친쉑",
    "미친쉑이",
    "미친쉨",
    "미친시부랄",
    "미친씨부랄",
    "미친자식",
    "미칧",
    "미침",
    "미칭",
    "美腿",
    "美腿美逼",
    "美腿丝足",
    "美腿写真",
    "미튄",
    "미튼",
    "미틘",
    "미틩",
    "미티넘",
    "미틴것",
    "미틴년",
    "미틴놈",
    "미틴뇸",
    "미틴눔",
    "美鲍少女",
    "美穴",
    "美穴射精",
    "美穴诱人",
    "迷魂药",
    "迷幻藥",
    "迷幻药",
    "緡",
    "湣",
    "鰵",
    "黽",
    "澠",
    "民间电台",
    "敏感词",
    "民國",
    "民国",
    "民聯",
    "민셩야설",
    "闷骚",
    "民运",
    "民意論壇",
    "民政部",
    "民族分裂分子",
    "민주화",
    "民主",
    "民主女神之歌",
    "民主党",
    "民主黨總部",
    "民主墻",
    "民主潮",
    "民主中国",
    "民众电子报",
    "민증위조",
    "民陣",
    "民進黨",
    "民进党",
    "民爆器材",
    "民航局",
    "密洞",
    "蜜洞",
    "密室陵辱",
    "密室淫行",
    "密宗",
    "蜜汁嫩穴",
    "密穴",
    "蜜穴",
    "蜜穴淫乳",
    "密穴贴图",
    "蜜壺",
    "밑구녕",
    "밑구녕빨기",
    "밑구멍",
    "밑친",
    "ㅂㅅ",
    "바구리",
    "바이브레이터",
    "바주카자지",
    "颮",
    "迫奸",
    "博客大赛网站",
    "拍肩神药",
    "박고빼고",
    "박고싶다",
    "박고싶퍼",
    "博谈网",
    "薄码",
    "拍屄",
    "博訊",
    "博讯",
    "博讯网",
    "박아",
    "박아줄게",
    "박아줄께",
    "박을께",
    "박을년",
    "薄一波",
    "迫害",
    "迫害法轮功",
    "薄熙来",
    "밖에다쌀께",
    "反共",
    "反恐委员会",
    "潘國平",
    "反黨",
    "反党",
    "反对磁悬浮",
    "半島人",
    "反动",
    "反封鎖",
    "反封鎖技術",
    "反腐敗論壇",
    "反社會",
    "班禪",
    "班禅",
    "班禅九世的圆寂之地",
    "反送中",
    "反送中",
    "反右运动",
    "反宇宙",
    "反人類",
    "反日",
    "反日",
    "反日联盟",
    "反政府",
    "反抗中共暴政",
    "反革命",
    "反革命暴亂",
    "反革命暴乱",
    "反華",
    "反华",
    "发改委",
    "발기",
    "发浪",
    "發論",
    "发论",
    "發論公",
    "發論功",
    "发论公",
    "发论功",
    "發倫",
    "發輪",
    "發掄",
    "发伦",
    "发轮",
    "發倫功",
    "發掄功",
    "发伦功",
    "发抡功",
    "發楞",
    "潑婦",
    "拔屄",
    "拔屄自拍",
    "発射",
    "發騷",
    "发骚",
    "发展与改革委员会",
    "发展和改革委员会",
    "발정",
    "發正念",
    "発妻",
    "发票",
    "밟아버",
    "밟아부",
    "밟아브",
    "밥튕",
    "밥팅",
    "绑架",
    "龐建國",
    "放光明电视",
    "房奴",
    "放尿",
    "方勵之",
    "仿缅",
    "房事",
    "防衛指揮官",
    "仿制枪",
    "防沉迷",
    "放蕩",
    "放荡",
    "放荡少妇",
    "放荡少妇宾馆",
    "放荡熟女",
    "掰B",
    "倍可亲",
    "배때지",
    "배떼지",
    "扒屄",
    "排泄",
    "赔率",
    "陪睡觉",
    "배위에싸죠",
    "扒穴",
    "掰穴",
    "掰穴自拍",
    "掰穴自慰",
    "掰穴打洞",
    "掰穴皮卡丘",
    "白景富",
    "白嫩骚妇",
    "白嫩爆乳超激爽",
    "佰度",
    "佰渡",
    "百度",
    "百渡",
    "白莲教",
    "白立樸",
    "白目",
    "白夢",
    "백보디",
    "백보지",
    "白粉",
    "白色恐怖",
    "白石瞳",
    "白石日和",
    "白液四溅",
    "白衣行动",
    "白浆四溅",
    "白鸟樱",
    "白鸟智香子",
    "白痴",
    "白癡",
    "白皮書",
    "白虎女",
    "白虎嫩B",
    "白虎少妇",
    "白虎小穴",
    "白虎阴穴",
    "白话佛法",
    "밸년",
    "밸놈",
    "뱅쉰",
    "뱅싄",
    "뱅시",
    "뱅신",
    "뱡신",
    "버그캐쉬",
    "버그캐시",
    "버그프로그램",
    "버따리자지",
    "버러지",
    "버얼려서너어",
    "버지구녁",
    "버지구녕",
    "버지구녘",
    "버지구멍",
    "버지냄새",
    "버지따먹기",
    "버지뚫어",
    "버지뜨더",
    "버지물",
    "버지물마셔",
    "버지벌려",
    "버지벌료",
    "버지빨아",
    "버지빨어",
    "버지썰어",
    "버지쑤셔",
    "버지털",
    "버지핧아",
    "버짓물",
    "버짓물마셔",
    "벅쿠",
    "벅큐",
    "번개남",
    "번색",
    "번섹",
    "번쌕",
    "번쎅",
    "罚款黑幕",
    "벌기",
    "벌렁거려",
    "벌려",
    "벌려봐",
    "벌릴여자",
    "벌창",
    "벌창같은년",
    "泛蓝",
    "泛绿",
    "泛民",
    "梵蒂冈亚洲新闻通讯社",
    "梵蒂冈广播电台",
    "法(轮)功",
    "法*轮*功",
    "法~論",
    "法~倫",
    "法~淪",
    "法~綸",
    "法~輪",
    "法lun功",
    "法功",
    "法国国际广播电台",
    "法克鱿",
    "法論",
    "法论",
    "法侖",
    "法倫",
    "法淪",
    "法綸",
    "法輪",
    "法掄",
    "法仑",
    "法伦",
    "法轮",
    "法輪功",
    "法掄功",
    "法轮功",
    "法輪大法",
    "法轮大法",
    "法愣",
    "法西斯",
    "法十輪十功",
    "法十轮十功",
    "法广网",
    "法广新闻网",
    "法广中文网",
    "法爾卡",
    "法謫",
    "法謫功",
    "法制办",
    "벙개남",
    "벙섹",
    "벙신",
    "벙어리",
    "벨년",
    "벨놈",
    "벵쉰",
    "벵싄",
    "벵신",
    "벵틴",
    "벼잉신",
    "碧波荡漾",
    "碧香亭",
    "변/태",
    "변0",
    "변GIR",
    "변GIRL",
    "변O",
    "변YOU녀",
    "변견",
    "변남",
    "변녀",
    "变频棒",
    "변섹",
    "변태",
    "變態",
    "变态",
    "변테",
    "癟三",
    "别墅",
    "병1신",
    "병닥",
    "병딘",
    "병딱",
    "병딲",
    "병띤",
    "病逝的人民领袖",
    "병쉔",
    "병쉰",
    "병싀",
    "병싄",
    "병시나",
    "병신",
    "병신세리",
    "병신셰리",
    "병신쉐리",
    "병신쉐이",
    "병신씨발",
    "병자",
    "鸨",
    "보,지",
    "보.지",
    "보G",
    "보x",
    "보Z",
    "保监会",
    "普京",
    "报禁",
    "보뒤",
    "报码聊天",
    "保密局",
    "보밍아웃",
    "보빨",
    "寶石商人",
    "보오지",
    "保卫钓鱼岛",
    "보ㅈ",
    "宝的一封信",
    "保釣",
    "保钓",
    "보쥐",
    "보즤",
    "보지",
    "보지걸",
    "보지구녁",
    "보지구녕",
    "보지구멍",
    "보지깔년",
    "보지깔아",
    "보지나라",
    "보지녀",
    "보지년",
    "보지뇬",
    "보지당",
    "보지따먹기",
    "보지뚫어",
    "보지뜨더",
    "보지머리박기",
    "보지물",
    "보지물마셔",
    "보지박어",
    "보지벌",
    "보지벌려",
    "보지벌료",
    "보지벌리",
    "보지벌리자",
    "보지보지",
    "보지빨",
    "보지빨아",
    "보지빨어",
    "보지씹",
    "보지씹네",
    "보지에자지껴",
    "보지에자지너",
    "보지자지",
    "보지정액",
    "보지쥐어짜",
    "보지찌져",
    "보지찢어",
    "보지털",
    "보지털뽑아",
    "보지털어",
    "보지틀래기",
    "보지핧아",
    "보지핧아줄까",
    "보지핧아줘",
    "보지핧어",
    "보짓",
    "보짓년",
    "보짓물",
    "보짓물마셔",
    "보짖",
    "보짖물",
    "보찌",
    "보털",
    "保險套",
    "纀",
    "襆",
    "服務",
    "服务价格",
    "服务详解",
    "服务项目介绍",
    "复员军官",
    "复员军人",
    "纀軹繼",
    "纀轵继",
    "腹黑",
    "复兴党",
    "本?拉登",
    "本拉登",
    "本土无码",
    "封殺",
    "凤翔合碧",
    "봉신",
    "봉알",
    "丰乳",
    "封印的靈魂騎",
    "封印的靈魂騎士",
    "封從德",
    "봉지",
    "丰唇艳姬",
    "뵹싄",
    "뵹신",
    "仆街",
    "仆街",
    "敷脚",
    "父女乱伦",
    "斧头镰刀",
    "부라",
    "富兰克林",
    "부랄",
    "不良",
    "不良少女日记",
    "부럴",
    "不滅帝王",
    "剖腹一刀五千几",
    "부부섹스",
    "夫妇乐园",
    "傅鹏",
    "富士康",
    "赴死扛",
    "赴死坑",
    "富山の三助",
    "不爽不要錢",
    "不雅照",
    "부알",
    "부엉이 바위",
    "부엉이바위",
    "不玩了",
    "不要奥运要人权",
    "阝月",
    "傅作義",
    "傅作义",
    "부지",
    "夫妻3p",
    "夫妻俱乐部",
    "夫妻多p",
    "夫妻乱交",
    "夫妻床上激情自拍",
    "夫妻性事",
    "夫妻秀",
    "夫妻淫乱",
    "夫妻自拍",
    "부카케",
    "부킹맨",
    "腐败",
    "北疆",
    "北京xx网",
    "北京當局",
    "北京帮",
    "北京之春",
    "北京天安门",
    "北大三角地論",
    "北大三角地論壇",
    "北岛优",
    "北美自由論壇",
    "北美情趣",
    "北美中文网",
    "北鮮",
    "北原梨奈",
    "北韓",
    "糞",
    "鱝",
    "体奸",
    "苯基丙酮",
    "噴尿",
    "粉嫩穴",
    "噴你",
    "喷你",
    "笨蛋",
    "分隊長施蒂文",
    "分裂",
    "分裂祖国",
    "分裂中国",
    "苯环已哌啶",
    "粉屄",
    "笨屄",
    "笨屄",
    "苯比",
    "粉屄初插",
    "体射",
    "焚烧",
    "粉飾太平",
    "噴精",
    "噴精",
    "体制改革",
    "愤青",
    "糞便",
    "粉逼",
    "笨逼",
    "笨逼",
    "粉穴",
    "粉红穴",
    "紱",
    "佛教",
    "불랄",
    "불륜",
    "불알",
    "佛展千手法",
    "佛祖",
    "불할",
    "붕가",
    "붕딱",
    "붕딲",
    "붕뛴",
    "붕삼",
    "붕싄",
    "붕시나",
    "붕신",
    "붕알",
    "붜지",
    "븅싄",
    "븅시",
    "븅신",
    "븅아",
    "븅틴",
    "브라자",
    "브라쟈",
    "브랄",
    "브레지어",
    "브지",
    "블알",
    "븡딱",
    "븡쉰",
    "羆",
    "轡",
    "屄",
    "屄",
    "肥臀巨乳",
    "費良勇",
    "비렁뱅이",
    "秘裂",
    "比毛",
    "屄毛",
    "屄毛",
    "非法征用",
    "非法拆毁",
    "屄屄",
    "比比少女好片",
    "屄屄特写",
    "非常新闻通讯社",
    "屄芯",
    "屁眼",
    "屁眼",
    "比樣",
    "飛揚論壇",
    "卑猥",
    "비용신",
    "비웅신",
    "秘肉",
    "비잉신",
    "비즈니스클럽",
    "扉之阴",
    "肥屌",
    "啤牌",
    "肥鲍",
    "肥逼",
    "肥穴",
    "屄穴",
    "費鴻泰",
    "宾馆女郎",
    "繽舎繳",
    "缤舎缴",
    "賓周",
    "宾周",
    "빌어먹",
    "빌어먹을",
    "빙.신",
    "빙구",
    "빙닥",
    "冰毒",
    "빙딱",
    "빙뛴",
    "빙띤",
    "冰粉",
    "빙순",
    "빙쉬",
    "빙쉰",
    "빙싀",
    "빙싄",
    "빙시",
    "빙신",
    "빙신쉐리",
    "빙신쉐이",
    "빙아",
    "冰火九重天",
    "冰後",
    "빠가",
    "빠가십새",
    "빠가씹새",
    "빠걸",
    "빠구리",
    "빠구울",
    "빠굴",
    "빠굴이",
    "빠꾸리",
    "빠네",
    "빠도리",
    "빠돌이",
    "빠라",
    "빠라라",
    "빠라바",
    "빠라조",
    "빠라죠",
    "빠라주까",
    "빠라줘",
    "빠러",
    "빠르너",
    "빠수니",
    "빠순이",
    "빠아가",
    "빠아구리",
    "빠아구우리",
    "빠아아라",
    "빠큐",
    "빡돌",
    "빡돌아",
    "빡촌",
    "빡큐",
    "빤스",
    "빤쓰",
    "빤쥬",
    "빤쮸",
    "빤쮸리",
    "빤쯔",
    "빨갱이",
    "빨게해도",
    "빨고시퍼",
    "빨고시포",
    "빨고싶다",
    "빨고싶어",
    "빨고파",
    "빨구시퍼",
    "빨구싶나",
    "빨구싶어",
    "빨구와라",
    "빨기",
    "빨께",
    "빨라줘",
    "빨아",
    "빨아도",
    "빨아라",
    "빨아봐",
    "빨아봐요",
    "빨아죠",
    "빨아주고싶어",
    "빨아줄게",
    "빨아줄까",
    "빨아줄까요",
    "빨아줄께",
    "빨아줄꼐",
    "빨아줄래",
    "빨아줄수있어",
    "빨아줄여",
    "빨아줘",
    "빨어",
    "빨어핥어박어",
    "빨자",
    "빨자좃",
    "빨자좆",
    "빨치산",
    "빨통",
    "빽보디",
    "빽보비",
    "빽보지",
    "빽자지",
    "빽큐",
    "뺑신",
    "뺑쒼",
    "뺑씬",
    "뺘아뻐그리",
    "뺘큐",
    "뻐르나",
    "뻐르너",
    "뻐르노",
    "뻐어큐",
    "뻐지",
    "뻐큐",
    "뻐킹",
    "뻑구",
    "뻑뀨",
    "뻑유",
    "뻑큐",
    "뻨규",
    "뻨큐",
    "뼝신",
    "뽀개",
    "뽀로노",
    "뽀르나",
    "뽀르너",
    "뽀르노",
    "뽀오지",
    "뽀쥐",
    "뽀지",
    "뽀쮜",
    "뽀찌",
    "뽀큐",
    "뽁큐",
    "뽕알",
    "뾰큐",
    "뿅신",
    "뿅씬",
    "뿡알",
    "쀼웅신",
    "쁑신",
    "삐구",
    "삐꾸",
    "삐리넷",
    "삑구",
    "삑꾸",
    "삥쉰",
    "삥쒼",
    "삥씬",
    "ㅄ",
    "ㅅ1발새갸",
    "ㅅㅂ",
    "㈣",
    "厙",
    "私/服",
    "私?服",
    "私\\服",
    "傻B",
    "傻B",
    "死GD",
    "死GD",
    "死GM",
    "死GM",
    "死ね",
    "사갈탱이",
    "事件真相",
    "舎缴",
    "思科羅",
    "社科院",
    "邪教",
    "사까리",
    "사까쉬",
    "사까시",
    "사까시이",
    "사까아시",
    "사까아시이",
    "사까치",
    "斯諾",
    "射奶",
    "駛你公",
    "駛你娘",
    "駛你老母",
    "駛你老師",
    "駛你母",
    "駛你爸",
    "邪黨",
    "邪党",
    "斯大林",
    "司徒華",
    "傻卵",
    "死狼网",
    "史萊姆",
    "史萊姆王",
    "射了還說要",
    "司馬璐",
    "司馬晋",
    "沙漠风暴",
    "丝袜mm",
    "丝袜高跟",
    "丝袜聊天室",
    "丝袜写真",
    "丝袜骚货",
    "丝袜淫女",
    "丝袜淫妇",
    "丝袜淫娃",
    "丝袜足交",
    "사발까",
    "司法部",
    "事变",
    "士兵管理員瓦",
    "士兵管理員瓦爾臣",
    "私服",
    "私服",
    "私-服",
    "私—服",
    "傻比",
    "傻比",
    "傻屄",
    "傻屄",
    "射爽",
    "謝選駿",
    "事實獨立",
    "謝深山",
    "邪惡的黨",
    "邪恶的党",
    "邪惡打上獸的印記",
    "邪恶打上兽的印记",
    "射颜",
    "사앙뉸",
    "丝诱",
    "사이버섹스",
    "四人幫",
    "四人帮",
    "傻子",
    "死雜種",
    "謝長廷",
    "射在你臉上",
    "傻吊",
    "死全家",
    "死全家",
    "死绝",
    "射精",
    "射精入穴",
    "思潮",
    "傻鳥",
    "謝中之",
    "사창가",
    "私彩",
    "私處",
    "私處",
    "私处走光",
    "四川獨",
    "四川獨立",
    "四川独立",
    "傻屌",
    "四肖中特",
    "사카시",
    "사카치",
    "丝荡袜",
    "渣波波",
    "斯皮爾德",
    "傻逼",
    "傻逼",
    "四海龙女",
    "四海帮",
    "泻火尤物",
    "社會主義",
    "驶你公",
    "驶你娘",
    "驶你老母",
    "驶你老师",
    "驶你母",
    "驶你爸",
    "谢长廷",
    "毿",
    "删 号",
    "山口組",
    "产权局",
    "山本五十六",
    "山本梓",
    "散散步",
    "山西百万人心慌",
    "山西巷",
    "删号",
    "殺す",
    "撒尿",
    "撒尿",
    "殺你一家",
    "殺你全家",
    "薩達姆",
    "薩達姆",
    "萨德",
    "萨马兰奇",
    "살인",
    "杀人",
    "살인마",
    "살인범",
    "殺人犯",
    "杀人犯",
    "살인자",
    "살인현장",
    "撒切尔",
    "萨达姆",
    "煞筆",
    "煞笔",
    "煞逼",
    "萨哈卜",
    "살해",
    "살해범",
    "살해현장",
    "㈢",
    "糝",
    "三K黨",
    "三P",
    "三個代表",
    "三个代表",
    "三个代婊",
    "三个呆婊",
    "三去车仑",
    "三去車侖工力",
    "三公",
    "三级",
    "三級片",
    "三级片",
    "三码中特",
    "三尾发财诗",
    "三尾中特诗",
    "三民主义",
    "三班仆人派",
    "三陪",
    "三陪女",
    "三陪先生",
    "三陪小姐",
    "三唑仑",
    "三小",
    "삼일한",
    "三挫仑",
    "三中二",
    "三晋大地谣言足",
    "三八淫",
    "三八淫色网",
    "三韓征伐",
    "三行中特",
    "插",
    "插b",
    "插B图",
    "插GM",
    "涩工厂",
    "插那嗎B",
    "插那嗎比",
    "插那嗎逼",
    "插内射",
    "插嫩逼",
    "插嫩穴",
    "插你",
    "插你",
    "插妳",
    "插妳",
    "插你娘",
    "插你奶奶",
    "插你老母",
    "插你老師",
    "插你媽",
    "插你媽",
    "插你妈",
    "插你屁眼",
    "插你爺爺",
    "插你爺爺",
    "插你全家",
    "插你祖宗",
    "插到射",
    "插蜜穴",
    "插比",
    "插屄",
    "插屁眼",
    "插屄篇",
    "插射",
    "插小穴",
    "插骚穴",
    "插深些",
    "插我",
    "插我",
    "插阴",
    "插阴茎",
    "삽입",
    "插入内射",
    "插她",
    "삽쥘",
    "插进",
    "삽질",
    "插屌",
    "插他",
    "插暴",
    "插暴你",
    "插笔门",
    "插逼",
    "插香蕉",
    "插穴手淫",
    "插穴止痒",
    "插后庭",
    "삿가시",
    "삿까시",
    "삿깟시",
    "鯗",
    "相奸",
    "常勁",
    "上交所",
    "상넘",
    "상년",
    "상놈",
    "上你",
    "上妳",
    "上妳",
    "爽你",
    "桑东·络桑丹增",
    "床头戏",
    "商务部",
    "上门服务",
    "上門援交",
    "常盘樱子",
    "上訪",
    "上方のぜい六",
    "爽报",
    "爽屄",
    "爽死我了",
    "上杉美香子",
    "床上功夫",
    "想上你",
    "常受教",
    "想与我爽",
    "上原多香子",
    "相田桃",
    "床第教战",
    "爽操",
    "相调会",
    "上海孤兒院",
    "上海幫",
    "爽穴",
    "赏穴",
    "새.끼",
    "새//끼야",
    "새/끼",
    "새;끼",
    "새1끼",
    "새X",
    "새끼야",
    "塞你公",
    "塞你公",
    "塞你娘",
    "塞你娘",
    "賽你娘",
    "賽妳娘",
    "赛你娘",
    "赛妳娘",
    "塞你娘賽妳阿母",
    "塞你老母",
    "塞你老母",
    "賽你老母",
    "賽妳老母",
    "塞你老師",
    "塞你老师",
    "賽你老師",
    "賽你老八",
    "賽妳老八",
    "塞你母",
    "塞你母",
    "賽你母",
    "塞你爸",
    "塞你爸",
    "賽你婆",
    "塞白",
    "새부",
    "새에끼",
    "赛她娘",
    "새캬",
    "새키",
    "赛他娘",
    "賾",
    "色97爱",
    "색갸",
    "색걸",
    "色界",
    "色界论坛",
    "색골",
    "색광",
    "色区",
    "색기",
    "색꺄",
    "색끈한",
    "색끼",
    "색남",
    "색녀",
    "色图",
    "色拉寺",
    "色狼",
    "色狼论坛",
    "色狼小说",
    "色撸",
    "색마",
    "色网",
    "色猫",
    "色猫网",
    "色迷城",
    "色色",
    "色书库",
    "색수",
    "색쉬",
    "색스",
    "색스코리아",
    "색시",
    "색쑤",
    "색쓰",
    "色窝窝",
    "色情",
    "色情",
    "色情服务",
    "色情小电影",
    "色情五月天",
    "色情电影",
    "色中色",
    "색캬",
    "색키",
    "색파트너",
    "색할",
    "색햐",
    "色狐狸网址",
    "색히",
    "샊꺄",
    "샙세",
    "生奸",
    "生姦",
    "生奸内射",
    "生奸射精",
    "生徒胸触",
    "생리",
    "생리중",
    "生命力公益新闻网",
    "生命分流的圖",
    "生命分流的圖騰",
    "生殖器",
    "生鸦片",
    "생양아치",
    "生肖中特诗",
    "生孩子沒屁眼",
    "샤럽",
    "샤불년",
    "샤앙녀",
    "샤앙년",
    "샵년",
    "샹넘",
    "샹년",
    "샹논",
    "샹놈",
    "샹뇬",
    "샹늠",
    "샹악년",
    "샹악연",
    "徐光春",
    "徐國舅",
    "西北帮",
    "瑞士金融大學",
    "서양뽀르노",
    "서양이쁜이",
    "西洋风情",
    "西尔艾力",
    "西藏",
    "西藏314事件",
    "西藏国",
    "西藏獨",
    "西藏独立",
    "西藏分裂",
    "西藏自由",
    "西藏之声",
    "西藏天葬",
    "西哈努克",
    "徐向前",
    "释迦牟尼",
    "石拳戰鬥兵",
    "释放艾未未",
    "夕树舞子",
    "释欲",
    "腊肠希",
    "石川施恩惠",
    "石化圖騰",
    "詵",
    "躚",
    "先奸后杀",
    "选举",
    "先烈对话",
    "善惡有報",
    "鮮人",
    "仙人送码",
    "旋转地球",
    "鮮族",
    "鲜族",
    "蘚鮑",
    "设局",
    "舌头穴",
    "설라디언",
    "雪山狮子",
    "雪山狮子旗",
    "泄欲",
    "薛偉",
    "舌战法庭",
    "泄题",
    "雪腿玉胯",
    "灄",
    "聂荣臻",
    "涉日",
    "성X",
    "性感",
    "性感内衣裤",
    "성감대",
    "性感猫装",
    "性感少妇",
    "性感妖娆",
    "性感乳娘",
    "性感诱惑",
    "性感肉丝",
    "性开放俱乐部",
    "성게시판",
    "성겨육",
    "성경험",
    "성고민상담",
    "性高潮",
    "성과섹스",
    "성관계",
    "性關係",
    "星光伴我淫",
    "성교",
    "性交",
    "性交",
    "性交易",
    "성교육중",
    "성교제",
    "性交吞精",
    "성교하자",
    "성교해",
    "성기",
    "性器官",
    "성기구",
    "性饥渴",
    "性奴",
    "性奴隷",
    "成都兼职妹妹",
    "成都军区",
    "星岛环球网",
    "星岛日报",
    "星岛日报消息",
    "성매매",
    "性免费电影",
    "性无能",
    "性無能",
    "性無能",
    "性病",
    "性病",
    "성보조기구",
    "性福",
    "性福狼窝",
    "性服务",
    "性事",
    "聖射手",
    "성상담",
    "声色场所",
    "성생활",
    "성섹스",
    "性骚扰",
    "성숙녀",
    "城市激情聊天室",
    "性息",
    "性乐",
    "性愛",
    "性愛",
    "性爱",
    "性愛圖站点",
    "性愛圖片",
    "性愛插穴",
    "性愛截圖",
    "星野光",
    "成娱成人",
    "性玩物",
    "性慾",
    "性欲",
    "性欲",
    "성욕구",
    "性佣",
    "性友",
    "声援",
    "声援刘晓波",
    "性萎靡",
    "性游戏",
    "成人A片",
    "成人bt",
    "성인CD",
    "성인IJ",
    "성인갤러리",
    "성인게시판",
    "성인게임",
    "성인공간",
    "성인그리고섹스",
    "성인극장",
    "성인나라",
    "성인놀이문화",
    "성인누드",
    "성인뉴스",
    "성인대화",
    "성인대화방",
    "成人图",
    "成人图片",
    "成人导航",
    "성인동영상",
    "성인드라마",
    "成人论坛",
    "成人聊天室",
    "성인만화",
    "成人漫画",
    "성인만화나라",
    "성인만화천국",
    "成人网",
    "성인망가",
    "成人网站",
    "성인몰",
    "성인무료",
    "성인무료동영상",
    "성인무료사이트",
    "성인무료영화",
    "成人无码",
    "성인무비",
    "成人文学",
    "성인물",
    "성인미스랭크",
    "성인미팅방",
    "성인방",
    "성인방송",
    "성인방송국",
    "성인방송안내",
    "成人百强",
    "성인별곡",
    "성인비됴",
    "성인비디오",
    "成人社区",
    "성인사이트",
    "성인사이트소개",
    "성인사진",
    "성인상품",
    "성인생방송",
    "성인샵",
    "成人书库",
    "성인서적",
    "성인성교육스쿨",
    "成人性爱用品",
    "성인소녀경",
    "성인소라가이드",
    "성인소설",
    "成人小说",
    "성인쇼",
    "성인쇼핑",
    "성인쇼핑몰",
    "成人视频",
    "성인시트콤",
    "성인싸이트",
    "성인애니",
    "성인애니메이션",
    "성인야동",
    "성인야사",
    "성인야설",
    "성인야캠",
    "성인야화",
    "성인에로무비",
    "성인에로영화",
    "成人软件",
    "성인엽기",
    "성인엽기DAMOIM",
    "성인영상",
    "成人影视",
    "成人影院",
    "成人影片",
    "성인영화",
    "성인영화관",
    "성인영화나라",
    "성인영화방",
    "성인영화세상",
    "성인영화천국",
    "成人午夜场",
    "성인와레즈",
    "성인용",
    "성인용CD",
    "성인용품",
    "成人用品",
    "성인용품도매센터",
    "성인용품샵",
    "성인용품에로존",
    "성인용품전문쇼핑몰",
    "성인용품할인매장",
    "성인유머",
    "성인이미지",
    "성인일본",
    "성인자료",
    "성인자료실",
    "成人自拍",
    "성인잡지",
    "成人电影",
    "성인전용관",
    "성인전용정보",
    "성인정보",
    "成人站",
    "성인채팅",
    "성인챗",
    "성인천국",
    "성인체위",
    "성인카툰",
    "성인카페",
    "성인컨텐츠",
    "성인클럽",
    "成人片",
    "성인포탈",
    "성인플래쉬",
    "성인플래시",
    "성인화상",
    "성인화상채팅",
    "聖戰",
    "性战擂台",
    "性之站",
    "성체위",
    "성체험",
    "성추행",
    "성충동",
    "性快感",
    "성클리닉",
    "성태크닉",
    "성테크닉",
    "性吧",
    "性派对",
    "성폭력",
    "성폭행",
    "成品交易",
    "性虐",
    "性虐",
    "性虐待",
    "성행위",
    "性虎",
    "性虎色网",
    "盛華仁",
    "貰",
    "세게빨아",
    "世界經濟導報",
    "世界报纸",
    "世界新闻网",
    "世界新闻媒体网",
    "世界维吾尔代表大会",
    "世界维吾尔大会",
    "世界以利亚福音宣教会",
    "世界日报",
    "世界电影(台湾)",
    "洗腦",
    "洗脑班",
    "稅力",
    "税务总局",
    "세븐누드닷컴",
    "세븐섹시",
    "세월호",
    "世维会",
    "洗肠射尿",
    "细川百合子",
    "세퀴",
    "세키",
    "税票代开",
    "섹,스",
    "섹.스",
    "섹s",
    "섹걸",
    "섹걸닷컴",
    "섹골",
    "섹골닷컴",
    "섹끼",
    "섹남",
    "섹녀",
    "섹도우즈",
    "섹뜨",
    "섹마",
    "섹무비",
    "섹보지",
    "섹소리",
    "섹수",
    "섹쉬",
    "섹슈",
    "섹스",
    "섹스하자",
    "섹스해",
    "섹시TV",
    "섹시WAVE",
    "섹시게이트",
    "섹시나라",
    "섹시나이트",
    "섹시누드",
    "섹시맵",
    "섹시샵",
    "섹시성인용품",
    "섹시섹스코리아",
    "섹시씨엔엔",
    "섹시에로닷컴",
    "섹시엔TV",
    "섹시엔몰",
    "섹시연예인",
    "섹시재팬",
    "섹시조선",
    "섹시존",
    "섹시짱",
    "섹시촌",
    "섹시코디",
    "섹시코리아",
    "섹시클럽",
    "섹시클릭",
    "섹시팅하자",
    "섹시팬티",
    "섹쑤",
    "섹쓰",
    "섹키",
    "섹티쉬",
    "섹티즌",
    "섹파트너",
    "섹하고",
    "섹하구",
    "섹하자",
    "섹하장",
    "섹한번",
    "섹할",
    "섹해",
    "섹히",
    "센스디스",
    "셁기",
    "骚",
    "騷B",
    "骚b",
    "骚B熟女",
    "小B樣",
    "苏家屯",
    "召奸",
    "笑脸门",
    "笑脸二号",
    "笑脸一号",
    "騷鶏",
    "小雞雞",
    "小鶏鶏",
    "小鸡鸡",
    "騷棍",
    "小攻",
    "烧汽车",
    "蘇南成",
    "骚女",
    "少女高潮",
    "骚女叫春",
    "少女峰",
    "少女性爱",
    "少女十八摸",
    "少女吞枪",
    "少女被插",
    "少年阿宾",
    "小嫩鸡",
    "小嫩逼",
    "小嫩穴",
    "骚洞",
    "俏臀摄魄",
    "騷卵",
    "骚乱",
    "蘇拉",
    "騷浪",
    "骚浪熟女",
    "骚浪人妻",
    "小靈通",
    "骚老婆",
    "小林瞳",
    "骚妈",
    "骚妹",
    "小妹扛枪",
    "骚妹妹",
    "骚母",
    "小毛逼",
    "小美屄",
    "騷棒",
    "骚妇露逼",
    "骚妇掰B",
    "少妇白洁",
    "少妇自拍",
    "少妇偷情",
    "骚比",
    "骚屄",
    "小屄大图",
    "小比樣",
    "蘇菲爾",
    "烧商店",
    "小昭寺",
    "小骚骚",
    "蘇紹智",
    "小受",
    "骚水",
    "销售54式64式",
    "销售工字牌汽枪",
    "少修正",
    "销售虎头猎枪",
    "骚熟女",
    "消息员",
    "消息提示",
    "小室友里",
    "小心性命",
    "小野谷美穗",
    "酥痒",
    "蘇盈貴",
    "小右",
    "酥乳",
    "骚乳",
    "小乳头",
    "小肉粒",
    "소음순",
    "骚姨妈",
    "素人娘",
    "素人娘发堀",
    "素人乱伦",
    "素人露出",
    "少儿勿入",
    "骚人妻",
    "素人撮",
    "小日本",
    "소자식",
    "小姐",
    "小姐兼职",
    "小姐裸聊",
    "骚姐姐",
    "小姐打飞机",
    "小挺嫩屄",
    "蘇貞昌",
    "素质女",
    "小參考",
    "小参考",
    "骚妻",
    "骚妻淫穴",
    "小泉",
    "小泉純一郎",
    "小泉纯一郎",
    "小泉彩",
    "小泽奈美",
    "小泽玛莉亚",
    "小泽圆",
    "小泽菜穗",
    "蘇特勤",
    "蘇特勤魔法師",
    "蘇特勤護衛兵",
    "蘇特勒守護兵",
    "小平的预言",
    "騷包",
    "小逼",
    "騷逼",
    "騷逼",
    "骚逼",
    "骚逼逼",
    "小穴",
    "小穴",
    "酥穴",
    "骚穴",
    "骚穴怒放",
    "小穴诱惑",
    "小穴特写",
    "销魂",
    "销魂洞",
    "騷貨",
    "騷貨",
    "骚货",
    "蘇曉康",
    "烧毁",
    "酥胸",
    "酥胸诱惑",
    "속궁합",
    "俗辣",
    "速成代理",
    "孫大千",
    "孙文",
    "孙永波",
    "孙逸仙",
    "孫中山",
    "孙中山",
    "送Q币",
    "松岛枫",
    "宋書元",
    "送外挂",
    "宋祖英",
    "宋楚瑜",
    "松阪季实子",
    "晒B",
    "晒屄",
    "刷钻",
    "碎片製造商人馬克",
    "碎片製造商人蘇克",
    "쇼킹섹스",
    "쇼킹섹스코리아",
    "쇼킹에로",
    "숏자지",
    "藪",
    "售ID",
    "收QB",
    "售TNT",
    "帅哥激情",
    "帅哥初次",
    "수간",
    "獸奸",
    "兽奸",
    "氵去",
    "數據中國",
    "水去车仑",
    "氵去車侖工力",
    "氵去車侖工力?",
    "售高压气枪",
    "售工字气枪",
    "獸交",
    "獸交",
    "兽交",
    "售军用手枪",
    "수꼴",
    "水嫩B",
    "修煉",
    "售獵槍",
    "手榴弹",
    "水利部",
    "树麻里子",
    "售卖武器",
    "秀毛穴",
    "售仿真枪",
    "手洗龙门",
    "兽兽门",
    "售软件",
    "兽欲",
    "售游戏币",
    "售银票",
    "수음",
    "手淫",
    "手淫",
    "售猎枪",
    "首切り",
    "水晶之恋",
    "睡着的武神",
    "售枪械",
    "手枪的制造方法",
    "售枪支",
    "羞耻母",
    "售弹药",
    "受虐狂",
    "售号",
    "誶",
    "熟女",
    "熟女乱伦",
    "熟女流精",
    "熟女毛逼",
    "熟女颜射",
    "熟女人妻",
    "熟女逼",
    "熟女护士",
    "熟母",
    "숙모보지",
    "熟妇",
    "熟妇骚器",
    "熟妇人妻",
    "熟骚妇",
    "叔嫂肉欲",
    "숙식제공",
    "纯一郎",
    "숩삭키",
    "숫처녀",
    "쉐꺄",
    "쉐뀌",
    "쉐끼",
    "쉐캬",
    "쉐키",
    "쉬발새야",
    "쉬발엑스",
    "쉬방",
    "쉬방새",
    "쉬방세이",
    "쉬방쉐이",
    "쉬밸",
    "쉬뱅",
    "쉬벌",
    "쉬벌년",
    "쉬벌뇬",
    "쉬봘",
    "쉬부",
    "쉬불",
    "쉬불넘아",
    "쉬붕",
    "쉬빌",
    "쉬빠",
    "쉬빡",
    "쉬빨",
    "쉬빵세이",
    "쉬탱",
    "쉬파",
    "쉬팍",
    "쉭뻘",
    "쉭셰리",
    "쉽발새야",
    "쉽새",
    "쉽생구리",
    "쉽샤퀴",
    "쉽세",
    "쉽셋",
    "쉽쌔",
    "쉽쎄",
    "쉽쎅",
    "쉽알",
    "쉽알넘",
    "쉽알년",
    "쉽알놈",
    "쉽알늠",
    "쉽앨",
    "쉽탱",
    "쉽텡아",
    "쉽팔",
    "쉽할이",
    "쉿새",
    "쉿세",
    "슈발",
    "스너프",
    "스댕",
    "스댕아",
    "스바새키",
    "스바쉐리",
    "스바쉑캬",
    "스발",
    "스발녀나",
    "스발년",
    "스벌년아",
    "스벌놈",
    "스벨",
    "스봉아",
    "스붕아",
    "스뽕아",
    "스시국",
    "스시녀",
    "스와핑",
    "스트립쇼",
    "스트립쑈",
    "스팍",
    "스팔",
    "스패킹",
    "스팽킹",
    "스펄",
    "슨상님",
    "鯴",
    "슴가",
    "习近平",
    "袭近平",
    "湿了",
    "濕了還說不要",
    "濕了還說要",
    "习李十八大",
    "湿身诱惑",
    "习远平",
    "习仲勋",
    "습탱이",
    "湿穴",
    "脀繼",
    "脀继",
    "绳虐",
    "屎",
    "釃",
    "鰣",
    "是鸡",
    "屎你公",
    "屎你娘",
    "屎你娘",
    "屎妳娘",
    "屎你老母",
    "屎你老師",
    "屎你母",
    "屎你爸",
    "시다바리",
    "시달년",
    "時代論壇",
    "時代革命",
    "時代革命",
    "时代革命",
    "时代革命",
    "시댕",
    "시댕아",
    "시댕이",
    "시뎅",
    "시뎅아",
    "시땡이",
    "柴玲",
    "시바라마",
    "시바알년",
    "시박",
    "시박색히",
    "시박쉑히",
    "시발",
    "시발넘",
    "시발년",
    "시발년아",
    "시발놈",
    "시발놈아",
    "시발뇬",
    "시발롬",
    "시발름",
    "시발새끼",
    "시발어",
    "시방색희",
    "시방세",
    "시방세야",
    "시방쉐",
    "시방쉑희",
    "시방이",
    "시벌",
    "시벌새끼",
    "시벌세끼",
    "시벨",
    "시벨넘",
    "시벨새키",
    "시벵",
    "시볼년",
    "시봉아",
    "시부랄",
    "시불",
    "시불알년",
    "시붕",
    "시붕년",
    "시뷀",
    "시브랄",
    "시블",
    "시블넘들",
    "视频",
    "视频宝贝",
    "视频自慰",
    "视频脱衣",
    "시빌",
    "시빡",
    "시빨",
    "시빨년",
    "시뽕아",
    "時事論壇",
    "视讯人妻",
    "시아눔",
    "시앙 놈",
    "시앙 눔",
    "시앙넘",
    "시앙년",
    "시앙놈",
    "시앙눔",
    "시양넘",
    "시양년",
    "시양놈",
    "시양눔",
    "示威",
    "示威抗议日本藐视中国",
    "시이발",
    "시이방",
    "시이밸",
    "시이벌",
    "시이불",
    "시이붕",
    "시이빡",
    "시이팔",
    "시이펄",
    "시이풀",
    "시입세",
    "시입세에",
    "시입팔",
    "侍從倫斯韋",
    "侍從貝赫爾特",
    "市川香织",
    "시탱",
    "시파",
    "시팍",
    "시팍새꺄",
    "시팍새끼",
    "시팍새캬",
    "시팍쉐리",
    "시팍쉐이",
    "시팏",
    "시팔",
    "시팔넘",
    "시팔년",
    "시팔놈",
    "시팔뇬",
    "시팔새",
    "시팔새꺄",
    "시팔새끼",
    "시팔새리",
    "시팔쉐리",
    "시팔쉐이",
    "시퐐",
    "시풀",
    "시풍아",
    "食捻屎",
    "食捻屎",
    "食屎",
    "食屎",
    "食精",
    "新疆",
    "新疆7.5事件",
    "新疆砍人",
    "新疆国",
    "新疆獨",
    "新疆独立",
    "新疆分裂",
    "神經病",
    "神经病",
    "神谷姬",
    "新觀察論壇",
    "新纪元",
    "新纪元周刊387期",
    "新党",
    "新唐人",
    "新唐人电视台",
    "新唐人电视台网",
    "信徒",
    "新头壳",
    "新闻管制",
    "新聞封鎖",
    "新闻封锁",
    "新闻小天使",
    "新闻出版署",
    "新聞出版總署",
    "新闻出版总署",
    "新潘金莲",
    "新报网站",
    "信报月刊",
    "信报财经新闻",
    "身份生成器",
    "身份證產生器",
    "身份證字號產生器",
    "身份證號碼產生器",
    "新史记",
    "新三才",
    "新生網",
    "新生网",
    "新手訓練營",
    "新约教会",
    "新語絲",
    "信用危機",
    "神韵艺术团",
    "신음",
    "呻吟",
    "신음소리",
    "新義安",
    "慎入",
    "辛子陵",
    "神雕外传之郭襄",
    "神洲电影制片厂",
    "信众",
    "新台湾新闻",
    "神通加持法",
    "神婆",
    "神汉",
    "辛灝年",
    "新華舉報",
    "新華內情",
    "新華通論壇",
    "失禁",
    "실리콘하우스성인용품",
    "失身",
    "实际神",
    "諶",
    "諗",
    "审江大联盟",
    "深交所",
    "心灵法门“白话佛法”系列节目",
    "心水",
    "深圳卫视",
    "心身障害者",
    "婶婶诱惑",
    "深爱色色",
    "深爱爱色",
    "심한포르노",
    "寻欢",
    "㈩",
    "십8",
    "十景缎",
    "십구녕",
    "십넘",
    "십녀",
    "十年动乱石进",
    "십년아",
    "십놈",
    "십뇬",
    "십딱",
    "십때꺄",
    "십때끼",
    "십떼끼",
    "十码中特",
    "십물",
    "십발새끼",
    "십발쌔꺄",
    "십밸",
    "십버지",
    "십보지",
    "십봉지",
    "십부랄",
    "십부럴",
    "十四跳",
    "十三兄弟帮",
    "십새",
    "십새꺄",
    "십새끼",
    "십새야",
    "십새캬",
    "십새키",
    "십색",
    "십색꺄",
    "십색끼",
    "십색히",
    "십세",
    "십세기",
    "십세끼",
    "십세리",
    "십세이",
    "십세키",
    "십섹키",
    "십셰리",
    "십쇄리",
    "십쇡기",
    "십쇼키",
    "십쉐",
    "십쉐끼",
    "십쉐리",
    "십쉐이",
    "십쉑",
    "십쉑히",
    "십시끼",
    "십쌔",
    "십쌔꺄",
    "십쎄끼",
    "십씨키",
    "십앋",
    "십알",
    "십알넘",
    "십알년",
    "십알놈",
    "십알늠",
    "십왈",
    "十二煞星帮",
    "十字军",
    "십자석",
    "십자슥",
    "십좌지",
    "십지랄",
    "십지럴",
    "十指弹琴",
    "십질",
    "십창",
    "십창녀",
    "十七大权斗",
    "十七大人大代表一封信",
    "十七大的一封信",
    "十七条",
    "십탱",
    "십탱구리",
    "십탱굴이",
    "십탱꾸리",
    "십탱이",
    "십팔",
    "十八禁",
    "십팔넘",
    "십팔년",
    "십팔놈아",
    "十八大",
    "十八大与习近平时代",
    "十八大人员安排",
    "十八大接班",
    "十八大布局",
    "십팔련",
    "十八摸",
    "십팔새",
    "십팔새끼",
    "십팔연",
    "십할",
    "십할년",
    "십할련",
    "싯;팔",
    "싯1팔",
    "싯끼",
    "싯빨",
    "싯팔",
    "싯퐐",
    "싱발",
    "싲팔",
    "싴팔",
    "싵팔",
    "싶알",
    "싶팔",
    "ㅆ1발",
    "ㅆ1팔",
    "ㅆㅂ",
    "싸가리",
    "싸가지",
    "싸갈",
    "싸갈통",
    "싸게해줄께",
    "싸고시포",
    "싸달",
    "싸물어",
    "싸발",
    "싸발년",
    "싸뱔",
    "싸앙넘",
    "싸앙녀",
    "싸앙년",
    "싸앙눔",
    "싸앙뉸",
    "싸앙늠",
    "싸이커",
    "싸이코",
    "싸죠",
    "싸줄께",
    "싸줘",
    "싹년",
    "싹동",
    "싹바",
    "쌉년",
    "쌍너엄",
    "쌍넌",
    "쌍넘",
    "쌍녀언",
    "쌍년",
    "쌍념",
    "쌍노무",
    "쌍놈",
    "쌍놈아",
    "쌍뇨나",
    "쌍뇬",
    "쌍뇸",
    "쌍뇽",
    "쌍뉸",
    "쌍늠",
    "双洞内射",
    "双洞齐插",
    "双龙入洞",
    "쌍보지",
    "双峰微颤",
    "双飞",
    "双插多P",
    "雙十節",
    "쌍쌍보지",
    "쌍악연",
    "双语学生邮报",
    "쌍연",
    "双掌进洞",
    "双穴齐插",
    "쌔밸년",
    "쌔엑스",
    "쌔캬",
    "쌔키",
    "쌕갸",
    "쌕걸",
    "쌕꺄",
    "쌕수",
    "쌕쉬",
    "쌕스",
    "쌕시",
    "쌕쌕이티비",
    "쌕쑤",
    "쌕쓰",
    "쌕캬",
    "쌤통",
    "쌩MOLCA",
    "쌩몰카",
    "쌩보지",
    "쌩보지쑈",
    "쌩아다",
    "쌩포르노",
    "쌰갈탱",
    "쌰꺄",
    "쌰럽",
    "쌰발",
    "쌰앙넘",
    "쌰앙녀",
    "쌰앙년",
    "쌰앙눔",
    "쌰앙뉸",
    "쌰앙늠",
    "썁색",
    "썁슥",
    "썁식",
    "썅",
    "썅넌",
    "썅넘",
    "썅녀",
    "썅년",
    "썅놈",
    "썅뇬",
    "썅뇸",
    "썅눔",
    "썅늠",
    "썅아녀",
    "썅아여",
    "썅악",
    "썅악년",
    "썅악여",
    "썅악연",
    "썅앙년",
    "썅여",
    "썅연",
    "썌꺄",
    "써글",
    "써글넘",
    "써글년",
    "써글놈",
    "써글눔",
    "써글늠",
    "써버럴",
    "써벌",
    "썩을",
    "썩을넘",
    "썩을년",
    "썩을놈",
    "썩을눔",
    "썩을늠",
    "쎄꺄",
    "쎄끈",
    "쎄끈한",
    "쎄끼",
    "쎄럽",
    "쎄리",
    "쎄엑",
    "쎄엑스",
    "쎅기",
    "쎅뜨",
    "쎅수",
    "쎅스",
    "쎅스코리아",
    "쎅시넷",
    "쎅쑤",
    "쎅쓰",
    "쎅콜닷컴",
    "쎅클럽",
    "쎅히",
    "쎼끼",
    "쎽스",
    "쐉넘",
    "쐉년",
    "쐑갸",
    "쐑꺄",
    "쐑수",
    "쐑스",
    "쐑쑤",
    "쐑쓰",
    "쐑캬",
    "쑤벌",
    "쑤셔",
    "쑤셔줄께",
    "쑤셔줘",
    "쑤시기",
    "쑤시자",
    "쑤시자아",
    "쑤쎠박는다",
    "쑤우셔",
    "쑤우셔어",
    "쑤우시자",
    "쑵년",
    "쑵새",
    "쒜끼",
    "쒜럽",
    "쒜리",
    "쒝기",
    "쒝끼",
    "쒝수",
    "쒝스",
    "쒝쑤",
    "쒝쓰",
    "쒝캬",
    "쒭새",
    "쒸8",
    "쒸댕",
    "쒸댕아",
    "쒸바",
    "쒸바라",
    "쒸박",
    "쒸발",
    "쒸방",
    "쒸방세이",
    "쒸방쉐이",
    "쒸밸",
    "쒸뱔",
    "쒸벌",
    "쒸벨",
    "쒸부",
    "쒸부뎅",
    "쒸부랄",
    "쒸부럴",
    "쒸불",
    "쒸붕",
    "쒸브뎅",
    "쒸빠",
    "쒸빡",
    "쒸빨",
    "쒸빨넘",
    "쒸빵",
    "쒸빵세이",
    "쒸뻘",
    "쒸이",
    "쒸이바",
    "쒸이발",
    "쒸이방",
    "쒸이밸",
    "쒸이벌",
    "쒸이부",
    "쒸이불",
    "쒸이붕",
    "쒸이빡",
    "쒸이빨",
    "쒸이팔",
    "쒸이펄",
    "쒸이풀",
    "쒸입새",
    "쒸파",
    "쒸팍",
    "쒸팔",
    "쒸펄",
    "쒸펠",
    "쒸폴",
    "쒸풀",
    "쒸풍",
    "쓉년",
    "쓉발",
    "쓉새",
    "쓉쎄끼",
    "쓉알",
    "쓉질",
    "쓉창",
    "쓉탱",
    "쓋빨",
    "쓋뻘",
    "쓋파라",
    "쓋팍",
    "쓰글",
    "쓰글넘",
    "쓰글년",
    "쓰글놈",
    "쓰글늠",
    "쓰댕",
    "쓰델",
    "쓰뎅",
    "쓰바",
    "쓰바새끼",
    "쓰바색캬",
    "쓰바색키",
    "쓰바세끼",
    "쓰바쉐리",
    "쓰바쉑캬",
    "쓰바쌔끼",
    "쓰박",
    "쓰발",
    "쓰발넘",
    "쓰발놈",
    "쓰발새끼",
    "쓰발세꺄",
    "쓰발세끼",
    "쓰발쌔꺄",
    "쓰발쌕끼",
    "쓰밝",
    "쓰방",
    "쓰방새끼",
    "쓰방세끼",
    "쓰밸",
    "쓰버",
    "쓰벌",
    "쓰벌놈",
    "쓰벌눔",
    "쓰벨",
    "쓰벵세끼",
    "쓰볼",
    "쓰블",
    "쓰빡",
    "쓰뺄",
    "씌8",
    "씌댈",
    "씌댕",
    "씌델",
    "씌뎅",
    "씌바",
    "씌발",
    "씌발년",
    "씌발련이",
    "씌밝",
    "씌방",
    "씌밸",
    "씌뱅",
    "씌뱌",
    "씌벌",
    "씌벌년",
    "씌벌뇬",
    "씌벨",
    "씌벵",
    "씌봉",
    "씌봘",
    "씌뵹",
    "씌부럴",
    "씌부려",
    "씌블",
    "씌뻘년",
    "씌팍",
    "씌팏",
    "씌팔",
    "씌팔년",
    "씌퐁",
    "씝발",
    "씝새",
    "씝알",
    "씝알놈",
    "씝질",
    "씝창",
    "씝창년",
    "씝탱",
    "씝탱구리",
    "씝팔",
    "씝할",
    "씦알",
    "씨,발,연",
    "씨,발넘",
    "씨.발늠",
    "씨.팔",
    "씨.팔,년",
    "씨.팔,년,,",
    "씨;발",
    "씨1발",
    "씨1발1년들아",
    "씨8",
    "씨가랭넘",
    "씨가랭년",
    "씨가랭놈",
    "씨가랭뇬",
    "씨꽈랄",
    "씨끼",
    "씨누드21",
    "씨다발",
    "씨댕",
    "씨댕년",
    "씨댕이",
    "씨델",
    "씨뎅",
    "씨땡이",
    "씨레기",
    "씨뤠 기",
    "씨뤠기",
    "씨바",
    "씨바라",
    "씨박",
    "씨박새끼",
    "씨발",
    "씨\'발",
    "씨\'\'발",
    "씨발넘",
    "씨발년",
    "씨발놈",
    "씨발놈아",
    "씨발뇬",
    "씨발뇸",
    "씨발뇽",
    "씨발눔아",
    "씨발늠",
    "씨발롬",
    "씨발병신",
    "씨발새끼",
    "씨발스키",
    "씨발연",
    "씨방새끼",
    "씨방세",
    "씨방쉐",
    "씨밸",
    "씨밸놈",
    "씨밸늠",
    "씨뱅",
    "씨뱅가리",
    "씨뱅놈",
    "씨뱅아",
    "씨뱔",
    "씨버럴",
    "씨버럴년",
    "씨버럴놈",
    "씨벌",
    "씨벌년",
    "씨벌새끼",
    "씨벌세끼",
    "씨벌쉐이",
    "씨벧",
    "씨벨",
    "씨벨넘",
    "씨벨년",
    "씨벨놈",
    "씨벨늠",
    "씨벨세꺄",
    "씨벵",
    "씨보랄",
    "씨볼",
    "씨볼넘",
    "씨볼년",
    "씨볼놈",
    "씨볼늠",
    "씨봉",
    "씨봉새끼",
    "씨봉알",
    "씨봘",
    "씨부랄뇬",
    "씨부랠",
    "씨부럴",
    "씨부렁",
    "씨부려",
    "씨부리",
    "씨부얼",
    "씨불",
    "씨불넘",
    "씨불년",
    "씨불알",
    "씨불알년",
    "씨불얼",
    "씨붕",
    "씨블",
    "씨블년",
    "씨븡",
    "씨븡새끼",
    "씨비",
    "씨빠",
    "씨빡",
    "씨빨",
    "씨빨년",
    "씨빯",
    "씨뺠년",
    "씨뻘",
    "씨뽀랄",
    "씨뽝",
    "씨뽤",
    "씨뽥",
    "씨뿔",
    "씨쁠",
    "씨섹끼",
    "씨알",
    "씨앙넘",
    "씨앙년",
    "씨앙놈",
    "씨앙눔",
    "씨양넘",
    "씨양년",
    "씨양념",
    "씨양놈",
    "씨양눔",
    "씨이댕",
    "씨이뎅",
    "씨이바",
    "씨이발",
    "씨이방",
    "씨이밸",
    "씨이벌",
    "씨이부",
    "씨이불",
    "씨이붕",
    "씨이빡",
    "씨이빨",
    "씨이팔",
    "씨이펄",
    "씨이풀",
    "씨입",
    "씨입년",
    "씨입뇬",
    "씨입새",
    "씨입새에",
    "씨입세",
    "씨입세에",
    "씨잘",
    "씨창",
    "씨키",
    "씨탱",
    "씨텡",
    "씨파",
    "씨파??",
    "씨파넘",
    "씨파섹",
    "씨팍",
    "씨팍넘",
    "씨팍새꺄",
    "씨팍새끼",
    "씨팍세끼",
    "씨팍쉐리",
    "씨팏",
    "씨팔",
    "씨팔넘",
    "씨팔년",
    "씨팔놈",
    "씨팔뇬",
    "씨팔뇸",
    "씨팕",
    "씨팗",
    "씨팞",
    "씨팡",
    "씨펄",
    "씨퐁",
    "씨퐁넘",
    "씨퐁뇬",
    "씨퐁보지",
    "씨퐁자지",
    "씨풀",
    "씨풍",
    "씨필",
    "씨할",
    "씰알",
    "씳박",
    "씳발",
    "씳알",
    "씹.년.들",
    "씹.새.끼",
    "씹1세",
    "씹8",
    "씹갓길",
    "씹구녕",
    "씹구멍",
    "씹넘",
    "씹녀",
    "씹년",
    "씹년들",
    "씹놈",
    "씹뇬",
    "씹뇸",
    "씹덕",
    "씹덕후",
    "씹딱꿍",
    "씹때꺄",
    "씹때끼",
    "씹떼",
    "씹떼끼",
    "씹물",
    "씹미랄",
    "씹발놈",
    "씹밸",
    "씹버지",
    "씹벌",
    "씹보지",
    "씹보지년",
    "씹부랄",
    "씹붕알",
    "씹브랄",
    "씹블",
    "씹빡",
    "씹빨",
    "씹빨년",
    "씹빨아",
    "씹빵구",
    "씹뻘",
    "씹뽀지",
    "씹새",
    "씹새꺄",
    "씹새끼",
    "씹새리",
    "씹새이",
    "씹새캬",
    "씹새키",
    "씹색꺄",
    "씹색끼",
    "씹색히",
    "씹샛길",
    "씹생",
    "씹생알",
    "씹선비",
    "씹세",
    "씹세기야",
    "씹세끼",
    "씹세야",
    "씹세이",
    "씹세키",
    "씹섹년",
    "씹섹키",
    "씹셋나루",
    "씹셍아",
    "씹셰",
    "씹쉐",
    "씹쉐뀌",
    "씹쉐리",
    "씹쉐이",
    "씹쉐키",
    "씹쉑",
    "씹쉑캬",
    "씹쉑히",
    "씹시끼",
    "씹쌔",
    "씹쌔기",
    "씹쌔꺄",
    "씹쌔끼",
    "씹쌔들",
    "씹쌔키",
    "씹쎄",
    "씹쎄끼",
    "씹쒜",
    "씹씨키",
    "씹알",
    "씹알년",
    "씹알놈",
    "씹알뇬",
    "씹알늠",
    "씹연",
    "씹을냔",
    "씹이다",
    "씹자석",
    "씹자슥",
    "씹자식",
    "씹자지",
    "씹자지년",
    "씹지랄",
    "씹지럴",
    "씹질",
    "씹창",
    "씹창녀",
    "씹창년",
    "씹탱",
    "씹탱구 리",
    "씹탱구리",
    "씹탱굴이",
    "씹탱꾸리",
    "씹탱이",
    "씹퉁",
    "씹파뇬",
    "씹팍쉐리",
    "씹팔",
    "씹팔넘",
    "씹팔년",
    "씹팔놈",
    "씹팔늠",
    "씹팔련",
    "씹팔쉐리",
    "씹팔쌔끼",
    "씹팔연",
    "씹펄",
    "씹풀",
    "씹할",
    "씹할년",
    "씹할련",
    "씹할새끼",
    "씹할연",
    "씻끼",
    "씻발",
    "씻벌",
    "씻뻘",
    "씻퐁",
    "씾팔",
    "앂년",
    "앂팔",
    "屙",
    "아가리",
    "我奸",
    "我奸",
    "我干",
    "我幹",
    "아갈",
    "아갈이",
    "아갈창",
    "아갈통",
    "아괄",
    "아구리",
    "아구지",
    "아구창",
    "아구통",
    "俄國",
    "아굴",
    "아굴창",
    "亚女",
    "我妳老爸",
    "我妳老爸",
    "아다",
    "児童ポルノ",
    "俄罗斯人体",
    "阿罗约",
    "阿拉",
    "阿拉法特",
    "阿萊娜",
    "아랫도리",
    "亚历山大",
    "我咧干",
    "我老二很大",
    "아리랑치기",
    "阿里布达年代记",
    "아마걸포르노",
    "雅蠛蝶",
    "啊無卵",
    "阿弥陀佛",
    "阿兵哥言语录",
    "아색기가",
    "아아가리",
    "我爱色",
    "亚热",
    "我要性交",
    "我淫我色",
    "我淫我电影",
    "아이러브에로스쿨",
    "아이템매니아",
    "아이템메니아",
    "아이템베이",
    "我日",
    "我日",
    "我日你",
    "我的性启蒙老师",
    "亚情",
    "我操",
    "我操",
    "我操你",
    "亚洲薄码",
    "亚洲色",
    "亚洲色图",
    "亚洲成人密码",
    "亚洲性虐",
    "亚洲熟女",
    "亚洲幼图",
    "亚洲有码",
    "亚洲淫娃",
    "亚洲情色网",
    "亚洲周刊",
    "阿椒",
    "我就去色",
    "我就色",
    "아타락시안",
    "阿波罗网",
    "阿波罗新闻网",
    "阿巴斯",
    "아퍼살살해",
    "阿扁",
    "鴉片",
    "鸦片",
    "阿扁万岁",
    "阿扁萬歲",
    "鴉片渣",
    "鸦片渣",
    "鴉片液",
    "鸦片液",
    "阿扁与老胡",
    "아포온",
    "垵",
    "頇",
    "顔慶章",
    "颜骑",
    "安南",
    "安纳咖",
    "安拉",
    "按摩",
    "安麦疆汽抚狗维",
    "安眠酮",
    "安倍晋三",
    "安非他命",
    "安非他命",
    "顔射",
    "顏射",
    "颜射",
    "颜射自拍",
    "안에사정",
    "安全局",
    "顔清標",
    "訐",
    "알몸",
    "알몸공개",
    "알몸사진",
    "알몸쇼",
    "알바걸",
    "알바녀",
    "暗黑法師",
    "押大",
    "押小",
    "押注",
    "央視內部晚會",
    "爱AV情色在线电影",
    "挨球",
    "爱国者同盟",
    "애널",
    "애널입4",
    "爱女人",
    "애년",
    "爱图公园",
    "애동",
    "艾麗絲",
    "애로",
    "애로물",
    "애로영화",
    "埃裏克蘇特勤",
    "애마부인",
    "애무",
    "愛撫",
    "애므",
    "애믜",
    "애미",
    "애미랄",
    "애미보지",
    "애미씨뱅",
    "애미씹",
    "애미자지",
    "애미잡년",
    "애미좃물",
    "애븨",
    "애비",
    "埃斯萬",
    "爱思想",
    "艾森豪威尔",
    "爱色cc",
    "爱色吧",
    "爱神之传奇",
    "爱爱",
    "애애무",
    "애액",
    "愛液",
    "爱液",
    "爱液横流",
    "애에무",
    "애에미",
    "애에비",
    "爱欲淫靡",
    "爱幼阁",
    "爱幼人士",
    "애자",
    "愛滋",
    "愛滋",
    "爱滋",
    "애자매",
    "愛滋病",
    "艾滋病",
    "애쟈",
    "爱田由",
    "挨操",
    "爱操",
    "애좌",
    "爱妻淫穴",
    "额尔德尼",
    "앰병",
    "앰창",
    "앵년",
    "앵벌이",
    "罂粟",
    "樱树露衣",
    "樱朱音",
    "야,한",
    "야dong",
    "야게임",
    "야게임즈닷넷",
    "야겜",
    "夜激情",
    "野雞",
    "野鶏",
    "야근병동",
    "夜勤雀栋",
    "야께임",
    "야껨",
    "야녀",
    "야담",
    "야덩",
    "野덩",
    "야동",
    "夜동",
    "野동",
    "야동게시판",
    "야똥",
    "야려",
    "야리",
    "야리다",
    "야마",
    "야사",
    "夜사",
    "野사",
    "夜色俱乐部",
    "야설",
    "夜설",
    "野설",
    "야설공작소",
    "야설신화",
    "야설의문",
    "야섹",
    "耶稣",
    "耶穌",
    "耶苏",
    "야쉬쉬",
    "야시",
    "야시25TV",
    "야시MOLCA",
    "야시MTV",
    "야시걸",
    "야시꾸리",
    "야시녀",
    "야시랭크",
    "야시룸",
    "야시시",
    "야시시한",
    "야시캠",
    "야시코리아",
    "야애니",
    "野外露出",
    "野外放尿",
    "野外性交",
    "野外操逼",
    "야자료",
    "夜情",
    "野種",
    "야캠",
    "야하네",
    "야한",
    "夜한",
    "야한거",
    "야한걸",
    "야한것",
    "야한게임",
    "야한그림",
    "야한놈",
    "야한놈SEX",
    "야한누드",
    "야한동영상",
    "야한만화",
    "야한밤",
    "야한밤TV",
    "야한밤티브이",
    "야한방",
    "야한사딘",
    "야한사이트",
    "야한사진",
    "야한소설",
    "야한쇼닷컴",
    "야한영화",
    "야한이야기",
    "야한클럽",
    "惹火身材",
    "夜話紫禁城",
    "惹火自拍",
    "耶和华",
    "藥水",
    "藥材商人蘇耐",
    "藥材商人蘇耐得",
    "约炮",
    "얀동영상",
    "羊角奶",
    "양갈보",
    "楊建利",
    "阳光时务周刊",
    "陽具",
    "陽具",
    "阳具",
    "양년",
    "讓你操",
    "养蚂蚁",
    "陽物",
    "阳物",
    "杨思敏",
    "양아",
    "阳软禁中的谈话",
    "楊巍",
    "楊月清",
    "陽萎",
    "陽痿",
    "阳痿",
    "阳的最后思考",
    "阳精",
    "楊周",
    "楊懷安",
    "얘자",
    "얨병",
    "얨뵹",
    "얨븅",
    "齬",
    "飫",
    "御の二代目",
    "어덜트랜드",
    "御龙",
    "어미강간",
    "어미따먹자",
    "어미쑤시자",
    "漁夫菲斯曼",
    "抑制剂",
    "讞",
    "訁",
    "言论出版自由",
    "얼싸",
    "孽種",
    "嚴家其",
    "嚴家祺",
    "俺去也QVOD伦理资源站",
    "엄교",
    "閹狗",
    "广东工人联合会",
    "엄마젓",
    "엄마주번",
    "严方军",
    "广场上的鲜血",
    "广州情色录",
    "엄창",
    "業病",
    "业主维权",
    "엉덩이",
    "에니탑",
    "에로",
    "에로69",
    "에로69TV",
    "에로79",
    "에로걸즈",
    "에로게이트",
    "에로게임",
    "에로관",
    "에로극장",
    "에로니폰",
    "에로닷컴",
    "에로당",
    "에로데이",
    "에로동",
    "에로동영상",
    "에로라이브",
    "에로무비",
    "에로물",
    "에로뮤직비디오",
    "에로바다",
    "에로방티브",
    "에로배우",
    "에로비",
    "에로비디오",
    "에로비안나이트",
    "에로샵",
    "에로세일",
    "에로섹스TV",
    "에로소라가이드",
    "에로쇼",
    "에로스",
    "에로스TV",
    "에로스데이",
    "에로스아시아",
    "에로스재팬",
    "에로스코리아",
    "에로스쿨",
    "에로스타",
    "에로스타일",
    "에로스페셜",
    "에로스포유",
    "에로시네마",
    "에로시안닷컴",
    "에로시티",
    "에로씨네",
    "에로아시아",
    "에로야",
    "에로에스",
    "에로엔조이",
    "에로영화",
    "에로영화관",
    "에로올",
    "에로와이프",
    "에로이브",
    "에로존",
    "에로주",
    "에로촬영현장",
    "에로카",
    "에로카섹",
    "에로카섹스",
    "에로클릭",
    "에로투유",
    "에로틱코리아",
    "에로파크",
    "에로패티시",
    "에로팬티",
    "에로플래쉬",
    "에로필름",
    "에무",
    "에믜",
    "에미",
    "에미젓",
    "에비",
    "에비씹",
    "에애무",
    "에에무",
    "에에미",
    "에에비",
    "에자",
    "엑스노브라",
    "엑스모텔",
    "엑스터시",
    "엑윽",
    "엠병",
    "엠창",
    "余洁麟",
    "輿論",
    "舆论监察的禁令",
    "舆论钳制",
    "輿論反制",
    "여성성인용품",
    "여성자위기구",
    "与我爽",
    "与我视频",
    "여엄병",
    "余英時",
    "여자먹고",
    "여자몸",
    "여자보지",
    "繹",
    "易丹軒",
    "睪丸",
    "緣圈圈",
    "연꽃합성",
    "燕玲论坛",
    "연병",
    "연븅",
    "軟纋",
    "软纋",
    "연인사이성인샵",
    "烟草局",
    "热那亚",
    "热辣",
    "热辣欲女",
    "熱比婭",
    "热比娅",
    "热-比-娅",
    "熱站政論網",
    "閻明複",
    "艳舞",
    "艳舞淫业",
    "艳迷",
    "염병",
    "염병할",
    "염뵹",
    "艳妇淫女",
    "盐酸羟亚胺",
    "盐酸二氢埃托菲",
    "阎王",
    "艳乳",
    "艳情",
    "艳情小说",
    "艳照",
    "葉菊蘭",
    "엽기에로",
    "엽기적인섹스",
    "엿같",
    "엿먹",
    "엿먹어",
    "엿먹어라",
    "엿처거머",
    "엿처먹어",
    "婴1儿汤",
    "营救刘晓波",
    "永久网址",
    "英国金融时报",
    "英文中国邮报",
    "英雄纪念碑",
    "婴儿汤",
    "鯢",
    "瘞",
    "倪育賢",
    "倪拆声",
    "嶴",
    "吴 仪",
    "吴官正",
    "乌光枪",
    "吳宏達",
    "乌克兰分离",
    "奧克拉",
    "吾吉买买提",
    "오까마",
    "오나니",
    "澳大利亚时报澳奇网",
    "五大诉求",
    "五大诉求",
    "吳敦義",
    "오랄",
    "奧拉德",
    "오럴",
    "오럴섹스",
    "오렌지야동",
    "奧魯奇",
    "乌鲁木齐",
    "乌-鲁-木-齐",
    "奧倫",
    "오르가슴",
    "오르가줌",
    "오르가즘",
    "奧利弗",
    "오마담",
    "奥马尔",
    "오마이에로",
    "吴邦国",
    "吴帮国",
    "吳方城",
    "吳百益",
    "오봉년",
    "오빠아파",
    "오빠아파닷컴",
    "오빠아퍼",
    "오성인",
    "五星红旗",
    "吳淑珍",
    "五十二旅",
    "午夜",
    "午夜激情桃色",
    "오예성인영화",
    "奧運迫害",
    "奥运迫害",
    "오유충",
    "吳育升",
    "吴仪",
    "吾尔凯西",
    "吾爾凱希",
    "吾爾開希",
    "吾尔凯希",
    "吾尔开希",
    "吳仁華",
    "五一七三",
    "오입",
    "오입질",
    "澳洲光明网",
    "澳洲广播电台中文网",
    "吳志芳",
    "奧特蘭",
    "奥巴马",
    "吳學燦",
    "吳學璨",
    "五行玄机诗",
    "吳弘達",
    "玉女心经",
    "玉女艳姿",
    "玉体撩人",
    "玉乳",
    "沃尔开西",
    "玉杵",
    "狱畜",
    "玉蒲团",
    "玉穴",
    "韞",
    "溫B",
    "瘟家",
    "溫家寶",
    "瘟假鸨",
    "瘟家宝",
    "温加宝",
    "温家保",
    "温家宝",
    "温家宝变革",
    "温假饱",
    "温加饱",
    "温家饱",
    "温公子",
    "温宝宝",
    "温夫人",
    "溫比",
    "温影帝",
    "温云松",
    "溫元凱",
    "温的一封信",
    "温总理",
    "溫逼",
    "温馨公告",
    "温馨提示",
    "膃",
    "올가슴",
    "올가자미",
    "올가즘",
    "옷버그",
    "옷벗",
    "옷벗기기",
    "옷벗기기게임",
    "瓮安",
    "窝窝客",
    "玩群交",
    "玩幼穴",
    "浣腸",
    "玩屌",
    "玩逼",
    "玩穴",
    "曰GM",
    "曰你",
    "왕가스",
    "왕가슴",
    "王剛",
    "王刚",
    "王軍濤",
    "王锦思",
    "王岐山",
    "王丹",
    "王丹公開信",
    "王丹公开信",
    "王丹主張",
    "王丹主张",
    "王立军",
    "왕버지",
    "旺报",
    "王寶森",
    "王宝森",
    "王輔臣",
    "왕보지",
    "往事微痕北大专辑",
    "往事追忆录",
    "王世堅",
    "王世勛",
    "왕소세지",
    "王秀麗",
    "王樂泉",
    "王乐泉",
    "王乐泉下台",
    "王潤生",
    "王子淫传",
    "왕자지",
    "왕잠지",
    "王兆國",
    "王兆国",
    "王震",
    "王超華",
    "王太华",
    "왕털버지",
    "왕털보지",
    "왕털자지",
    "왕털잠지",
    "旺波",
    "王八",
    "王八蛋",
    "王八蛋",
    "王涵萬",
    "王昊",
    "王滬寧",
    "王洪文",
    "倭寇",
    "倭国",
    "外 挂",
    "外/挂",
    "外_挂",
    "外\\挂",
    "外掛",
    "外挂",
    "外-挂",
    "外—挂",
    "外交論壇",
    "外交部",
    "外交與方略",
    "外圈",
    "猥褻",
    "猥亵",
    "猥琐",
    "外纯内骚",
    "外遇",
    "外陰",
    "外专局",
    "外汇局",
    "요년",
    "搖頭玩",
    "摇头玩",
    "搖頭丸",
    "搖頭丸",
    "摇头丸",
    "姚羅",
    "姚文元",
    "妖媚熟母",
    "要射了",
    "遥想当年春衫薄",
    "要爽就注册",
    "要色色",
    "요씨댕",
    "妖艳少妇",
    "姚月謙",
    "摇奖",
    "鵒",
    "欲娘",
    "浴尿",
    "欲仙欲浪",
    "欲仙欲死",
    "浴室乱伦",
    "浴室自拍",
    "欲照",
    "欲火",
    "欲火焚身",
    "욘병",
    "욘뵹",
    "욘븅",
    "욤뵹",
    "욧먹어",
    "牛B",
    "牛头马面",
    "우라질",
    "우랄질",
    "牛泪",
    "宇明網",
    "忧木瞳",
    "尤物",
    "우미쑤셔",
    "牛博网",
    "牛比",
    "尤比亞",
    "憂鬱的艾拉",
    "于幼軍",
    "右翼",
    "邮政局",
    "右派",
    "牛埔帮",
    "牛逼",
    "友好的魯德",
    "頊",
    "郁慕明",
    "운지",
    "원.교",
    "원교",
    "援交",
    "援交",
    "援交图",
    "援交妹",
    "援交薄码",
    "援交自拍",
    "原教旨主义",
    "援交炮图",
    "元老蘭提(沃",
    "元老蘭提(沃德）",
    "圓滿",
    "圆明网",
    "原味丝袜",
    "鸳鸯浴",
    "原子能机构",
    "原子弹",
    "远程偷拍",
    "원조가카",
    "원조교",
    "援助交易",
    "원조교재",
    "원조교제",
    "援助交際",
    "원조녀",
    "원조알바",
    "원조하자",
    "原住民族电视台",
    "遠志明",
    "원초적",
    "원틀릭페이",
    "月經",
    "月經",
    "月经",
    "月经不调",
    "粤语保卫战",
    "越後の米つき",
    "伟哥",
    "尉健行",
    "卫生部",
    "慰安婦",
    "威而鋼",
    "威而钢",
    "威而柔",
    "威而柔",
    "孬種",
    "慰春情",
    "乳",
    "鮪",
    "幼雞",
    "乳尻",
    "乳尻美脚",
    "乳交",
    "幼交",
    "乳沟",
    "维权",
    "幼妓",
    "유깝",
    "幼男",
    "幼女",
    "幼女奸淫猛片",
    "幼女天空",
    "幼圖",
    "유두",
    "乳頭",
    "乳頭",
    "乳头",
    "유두빨어",
    "유두핧어",
    "幼龍",
    "游龙金凤",
    "乳林大会",
    "幼网",
    "幼文共赏",
    "唯美",
    "유발조물락",
    "유방",
    "乳房",
    "乳房",
    "유방만져",
    "유방빨아",
    "유방주물럭",
    "유방쪼물딱",
    "유방쪼물럭",
    "유방핧아",
    "유방핧어",
    "乳峰",
    "乳射",
    "唯色",
    "游錫坤",
    "乳酥屄胀",
    "幼婴国度",
    "维吾尔独立",
    "有容奶大",
    "유우까압",
    "유우깝",
    "유우방",
    "유우우방것",
    "幼幼网",
    "幼幼网站",
    "幼幼写真",
    "幼幼影片",
    "幼幼诱女",
    "幼幼合集",
    "柔阴术",
    "遺精",
    "俞正声",
    "维族扎针",
    "游佐七海",
    "乳此多娇",
    "乳此动人",
    "乳此丰满",
    "乳此丝袜",
    "幼齒",
    "幼齿",
    "游蕩的僵尸",
    "游蕩的士兵",
    "游蕩爪牙",
    "乳波",
    "乳波臀浪",
    "乳霸",
    "幼片",
    "幼片种子",
    "乳爆",
    "幼逼",
    "游行",
    "幼香",
    "幼穴",
    "誘惑娛樂網",
    "幼花苑",
    "乳暈",
    "乳晕",
    "유흥업소",
    "游戲管理員",
    "游戏管理员",
    "遊戲管理員",
    "遊戲管理者",
    "游戏宫理员",
    "由喜貴",
    "游戏发奖员",
    "游戏广播",
    "铀",
    "顬",
    "诱阁",
    "诱奸",
    "诱奸幼女",
    "诱色uu",
    "诱色网",
    "诱幼",
    "诱人",
    "诱人胴体",
    "遗精",
    "诱惑",
    "诱惑视频",
    "诱惑全裸",
    "肉嫁",
    "肉感熟女",
    "肉感炮友",
    "육갑",
    "肉茎",
    "肉棍",
    "肉棍",
    "肉棍干骚妇",
    "肉棍子",
    "肉具",
    "肉沟",
    "육구자세",
    "肉奴隷",
    "肉洞",
    "肉洞",
    "肉洞骚妇",
    "肉壁",
    "육봉",
    "肉棒",
    "肉棒",
    "肉缝",
    "肉体",
    "肉体招生",
    "肉牝奴隶",
    "肉丝裤袜",
    "肉色勾人",
    "肉箫",
    "육시랄",
    "육시럴",
    "육실헐",
    "肉慾",
    "肉欲",
    "肉欲",
    "肉淫器吞精",
    "肉唇",
    "肉弹",
    "肉便器",
    "肉蒲团",
    "肉逼",
    "肉虐調教",
    "肉穴",
    "肉壶",
    "윤간",
    "尹慶民",
    "윤락",
    "윤븅",
    "恩格斯",
    "银民吧",
    "隐私图片",
    "隐窝窝",
    "隱者之路",
    "银票",
    "乙肝检测",
    "乙肝歧视",
    "乙肝筛选",
    "乙肝维权",
    "乙肝战友",
    "乙肝携带者",
    "淫",
    "淫",
    "淫B",
    "淫の方程式",
    "淫腔",
    "음경",
    "陰莖",
    "陰莖",
    "陰茎",
    "阴痉",
    "阴茎",
    "阴茎增大",
    "淫尻",
    "淫告白",
    "淫交",
    "淫叫",
    "陰囊",
    "陰囊",
    "阴囊",
    "淫女",
    "淫奴",
    "淫妞",
    "음담패설",
    "陰道",
    "陰道",
    "阴道",
    "阴道图片",
    "吟稻雁",
    "淫洞",
    "淫东方",
    "음란",
    "淫亂",
    "淫乱",
    "婬乱军团",
    "淫亂潮吹",
    "淫浪",
    "淫驴屯",
    "淫流",
    "淫魔",
    "淫码",
    "음마성전",
    "淫妹",
    "淫売",
    "음모",
    "淫母",
    "淫毛",
    "淫毛",
    "陰毛",
    "陰毛",
    "阴毛",
    "陰門",
    "陰門",
    "阴门",
    "淫媚",
    "淫美",
    "淫靡",
    "淫糜",
    "淫蜜",
    "음박학염",
    "음부",
    "淫婦",
    "淫妇",
    "陰部",
    "阴部",
    "阴阜",
    "阴部自拍",
    "淫妇自慰",
    "阴部特写",
    "阴屄",
    "淫丝",
    "淫师荡母",
    "淫色",
    "淫色少妇",
    "淫色贴图",
    "淫书",
    "淫城",
    "淫星",
    "淫声浪语",
    "淫骚",
    "淫少妇",
    "陰小撕大",
    "阴小撕大",
    "淫水",
    "淫水",
    "陰水",
    "阴水",
    "淫兽感染",
    "淫兽学园",
    "음순",
    "陰脣",
    "淫湿",
    "음액",
    "淫液",
    "淫样",
    "淫語",
    "淫语连连",
    "淫孽",
    "淫穢",
    "淫穢",
    "淫秽",
    "淫玩",
    "淫娃",
    "淫娃",
    "음욕",
    "淫欲",
    "淫辱教室",
    "淫欲世家",
    "淫欲肉体",
    "淫欲日本",
    "음위",
    "淫肉",
    "淫肉体",
    "淫肉诱惑",
    "淫人妻",
    "淫浆",
    "淫姐",
    "淫姐姐",
    "淫战群P",
    "淫店",
    "淫情",
    "陰精",
    "陰精",
    "阴精",
    "淫汁",
    "陰唇",
    "陰唇",
    "阴唇",
    "淫妻",
    "淫賤",
    "淫贱",
    "陰蒂",
    "陰蒂",
    "阴蒂",
    "阴缔",
    "淫术炼金士",
    "淫蟲",
    "淫痴",
    "음탕",
    "淫蕩",
    "淫蕩",
    "淫荡",
    "淫态",
    "淫逼",
    "淫河",
    "淫虐",
    "陰虐",
    "陰核",
    "阴核",
    "淫穴",
    "陰穴新玩法",
    "淫豪",
    "陰戶",
    "陰戶",
    "阴户",
    "淫貨",
    "淫货",
    "淫虫",
    "淫戏",
    "应召女郎",
    "鷹眼派氏族",
    "蚁力神",
    "疑案",
    "議員斯格文德",
    "意淫",
    "議長阿茵斯塔",
    "意志",
    "意志不堅的圖",
    "意志不堅的圖騰",
    "倚天屠龙别记",
    "㈡",
    "鮞",
    "二B",
    "异見人士",
    "以茎至洞",
    "伊古莱提感想",
    "二奶",
    "이녀나",
    "이년",
    "이뇽",
    "이늠",
    "二道桥",
    "伊东怜",
    "伊拉克",
    "伊拉克伊斯兰国",
    "伊朗",
    "移民",
    "이반성인용품몰",
    "二屄",
    "伊斯蘭",
    "伊斯兰",
    "伊斯兰国",
    "伊斯兰军",
    "伊斯兰团",
    "伊斯兰马格里布基地组织",
    "伊斯蘭亞格林",
    "伊斯蘭亞格林尼斯",
    "伊斯兰运动",
    "伊斯兰解放组织",
    "伊莎貝爾",
    "이새끼",
    "이시끼",
    "二十周年",
    "二乙基酰胺发",
    "二乙基酰胺发抡",
    "异議人士",
    "二二八事件",
    "이자식",
    "二中二",
    "怡春堂",
    "二逼",
    "二穴凌辱",
    "二穴插入",
    "二穴中出",
    "异型叛軍",
    "人间福报",
    "人间便器",
    "引咎辞职",
    "人權",
    "人大",
    "人大代表",
    "人代会",
    "人屠",
    "引導",
    "印度佛学院",
    "人浪逼美",
    "人民",
    "人民內情真相",
    "人民大會堂",
    "人民大会堂",
    "人民報",
    "人民报",
    "人民广场",
    "人民银行",
    "人民日报",
    "人民之聲論壇",
    "人民真實",
    "人体摄影",
    "人体艺术",
    "人体炸弹",
    "人性本色",
    "人小奶大",
    "人妖",
    "儿子干妈妈",
    "人之初神秘",
    "人妻",
    "人妻交换",
    "人妻凌辱",
    "人妻色诱",
    "人妻小说",
    "人妻熟女",
    "人妻外卖",
    "人妻外拍",
    "人妻自拍",
    "人妻榨乳",
    "人妻风骚",
    "人弹",
    "인터넷가입",
    "인터넷성인방송",
    "仁波切",
    "引爆",
    "㈠",
    "日",
    "日GM",
    "日X妈",
    "一个身份",
    "日喀则",
    "一贯道",
    "一国两制",
    "日軍",
    "一军两策",
    "一起操",
    "日內瓦金融",
    "日你",
    "日你",
    "日你娘",
    "日你老母",
    "日你媽",
    "日你媽",
    "日你妈",
    "日你媽買B",
    "日你爺爺",
    "一党专政",
    "一黨專制",
    "一党专制",
    "일밍아웃",
    "일벤져스",
    "一边一国",
    "日本AV女优",
    "日本RING",
    "日本のチベット",
    "日本灌肠",
    "日本鬼子",
    "一本道",
    "일본동영상",
    "일본망가",
    "日本妹妹和黑人做",
    "일본미소녀",
    "일본뽀르노",
    "일본성인만화",
    "일본성인방송",
    "日本素人",
    "日本骚货",
    "日本熟母",
    "日本有码",
    "日本人妻",
    "日本帝國",
    "일부심",
    "日屄",
    "日死",
    "日死",
    "日死你",
    "日死你",
    "一山村过年吃人肉",
    "一线B",
    "一夜性网",
    "一夜情",
    "一夜情",
    "一夜情信息",
    "一夜欢",
    "一王两奴",
    "一字解特码",
    "壹电视",
    "一条龙",
    "壹周刊",
    "壹周刊(台湾)",
    "日朱駿",
    "一中一台",
    "일진회",
    "一進會",
    "一肖中特",
    "日他娘",
    "日他娘",
    "一陀糞",
    "一派胡言",
    "日逼",
    "임마",
    "임병",
    "入联",
    "입사후장",
    "입싸",
    "입안사정",
    "입주둥이",
    "入珠环",
    "入穴一游",
    "扔石头",
    "ㅈ;ㄹ",
    "ㅈ1ㄹ",
    "ㅈ1랄",
    "ㅈL랄",
    "ㅈrㅈ!",
    "ㅈrㅈ1",
    "ㅈrㅈI",
    "ㅈrㅈl",
    "ㅈr지",
    "ㅈㄱ",
    "ㅈㄱ만남",
    "ㅈㄱㅈㄱ",
    "ㅈㄴ",
    "ㅈ물",
    "ㅈㅇ",
    "訾",
    "髭",
    "秭",
    "자 궁",
    "자,지",
    "자.지",
    "자g",
    "자JI",
    "자x",
    "자궁",
    "子宮",
    "子宫",
    "자기핧아",
    "子女任职名单",
    "自瀆",
    "自动建材",
    "자디",
    "自立晚报",
    "自摸",
    "自民黨",
    "自民党",
    "自拍内射",
    "自拍美穴",
    "自拍写真",
    "自拍诱惑",
    "自拍淫照",
    "自拍招友",
    "自拍偷拍",
    "自拍炮图",
    "自辩书",
    "資本主義",
    "自焚",
    "自焚",
    "자빠",
    "자빠졌네",
    "자살",
    "自殺",
    "自杀",
    "自杀手册",
    "自杀指南",
    "自插",
    "自插图",
    "自插小穴",
    "자슥아",
    "자아압옷",
    "자아지",
    "자압것",
    "紫阳",
    "자연인",
    "自蔚",
    "자위",
    "自慰",
    "自慰",
    "自慰抠穴",
    "자위기구",
    "자위남",
    "자위녀",
    "자위씬",
    "자위용품",
    "자위중",
    "자위코리아",
    "自慰炮图",
    "자위하자",
    "자위할래",
    "자위행위",
    "自由欧洲电台",
    "自由网",
    "自由民主論壇",
    "自由民主论坛",
    "自由时报",
    "自由新闻报",
    "自由亚洲电台",
    "自已的故事",
    "자ㅈ!",
    "자ㅈ1",
    "자ㅈI",
    "자ㅈl",
    "自制手枪",
    "自主择业",
    "자쥐",
    "자지",
    "자지구녕",
    "자지구멍",
    "자지꽂아",
    "자지넣자",
    "자지뜨더",
    "자지뜯어",
    "자지박어",
    "자지빨아",
    "자지빨아줘",
    "자지빨어",
    "자지쑤셔",
    "자지쓰레기",
    "자지정개",
    "자지짤라",
    "자지털",
    "자지핧아",
    "자지핧아줘",
    "자지핧어",
    "紫彩乃",
    "自治区党委",
    "自治机关",
    "子弹制",
    "孖牌",
    "自閉症児",
    "斫",
    "作秀",
    "作愛",
    "作愛",
    "作爱",
    "炸药",
    "작은보지",
    "猎枪销售",
    "猎枪的制造结构图",
    "炸弹",
    "戔",
    "驏",
    "잔슨빌",
    "잘빨아",
    "潜烈蟹",
    "赚钱中特诗",
    "잠지",
    "잠지뚫어",
    "잠지물마셔",
    "잠지털",
    "잠짓물마셔",
    "잡것",
    "잡넘",
    "잡년",
    "잡놈",
    "잡뇬",
    "잡뇸",
    "잡늠",
    "砸你饭碗是城管",
    "잡밥",
    "卡斯特罗",
    "雜碎",
    "잡쓰래기",
    "잡쓰레그",
    "잡쓰레기",
    "雜種",
    "雜種",
    "杂种",
    "卡通色图",
    "臟",
    "丬",
    "装B",
    "庄家",
    "張鋼",
    "蔣介石",
    "蒋介石",
    "張健",
    "蒋经国",
    "奖系统",
    "张高丽",
    "长谷香子",
    "長官沙塔特",
    "张德江",
    "张德亮预测",
    "藏独",
    "张黎利",
    "奖励发放",
    "奖励使者",
    "张磊",
    "长濑爱",
    "張林",
    "张立昌",
    "藏民",
    "張博雅",
    "張伯笠",
    "张柏芝",
    "藏妇会",
    "裝屄",
    "装屄",
    "裝屄呢",
    "张先玲",
    "張昭富",
    "张筱雨",
    "张小平",
    "张新枫",
    "張溫鷹",
    "張偉國",
    "长肉棍",
    "藏人",
    "藏人大会",
    "藏人无自治",
    "醬猪媳",
    "酱猪媳",
    "藏传",
    "张朝阳",
    "张潮阳",
    "藏族",
    "藏族同胞团结",
    "蔣中正",
    "蒋中正",
    "張志清",
    "脏车",
    "張清芳",
    "藏青会",
    "壮屌",
    "张春桥",
    "將則民",
    "将则民",
    "裝逼",
    "装逼",
    "裝逼呢",
    "张学良",
    "帳號",
    "賬號",
    "章孝嚴",
    "잦이",
    "잦털",
    "賫",
    "再奸",
    "灾区治安失控",
    "재기甲",
    "재기찡",
    "재랄",
    "在线公布",
    "在线销售",
    "재섭서",
    "재셥다",
    "재수없어",
    "재습다",
    "再转轮",
    "财政部",
    "재팬마나",
    "재팬만화",
    "재팬팬티",
    "争权",
    "爭鳴論壇",
    "争鸣杂志",
    "齟",
    "翥",
    "猪哥6合网中国网址联盟",
    "저까",
    "저까는",
    "저까튼",
    "저꽈튼",
    "저꽛튼",
    "她娘",
    "她奶奶的",
    "저년",
    "저놈",
    "저뇸",
    "저뇽",
    "저늠",
    "猪猡",
    "猪聾畸",
    "猪聋畸",
    "她媽",
    "她妈",
    "她馬的",
    "她媽的",
    "她妈的金日成",
    "猪毛",
    "猪毛1",
    "저빠지게",
    "저빱",
    "猪容基",
    "저자식",
    "抵制",
    "抵制北京奥运",
    "猪操",
    "저주글",
    "저주금",
    "저즌팬티",
    "磧",
    "糴",
    "覿",
    "적가튼",
    "積克館",
    "적까",
    "적꽈튼",
    "赤裸",
    "赤裸羔羊社区",
    "赤裸天使",
    "迪裏夏提",
    "寂寞少妇",
    "寂寞自摸",
    "賊民",
    "赤匪",
    "滴蜡",
    "赤色騎士",
    "赤色戰士",
    "滴腊",
    "적셔줄까",
    "적셔줄께",
    "적셔줄래",
    "迪吧门",
    "顓",
    "电监会",
    "转经道",
    "全国看守所死法分布图",
    "錢國梁",
    "全國兩會",
    "全國人大",
    "全国人大",
    "田期玉",
    "田紀雲",
    "田纪云",
    "錢其琛",
    "전나",
    "전나게",
    "전나구려",
    "全能神",
    "錢達",
    "전땅크",
    "全裸",
    "전라디언",
    "全无码",
    "全范围教会",
    "转法轮",
    "電視流氓",
    "全身舐め",
    "转业干部",
    "转业军官",
    "转业军人",
    "专业代炼",
    "专业代销",
    "傳染性病",
    "转账",
    "电转插穴",
    "转钱",
    "專政",
    "专政",
    "專制",
    "专制",
    "전조건",
    "专枪销售气枪",
    "前凸后翘",
    "吮屌",
    "专宠",
    "全套服务",
    "传播专业开锁技巧",
    "战牌",
    "展穴",
    "轉化",
    "전화방",
    "吮吸",
    "颛",
    "钱其琛",
    "젇놔",
    "癤",
    "절라",
    "절라게",
    "絕望之地",
    "绝美",
    "绝色美屄",
    "绝食抗暴",
    "窃听器",
    "窃听器材",
    "切七",
    "覘",
    "占领中环",
    "점물",
    "占中",
    "접밥",
    "젓가튼",
    "젓가튼쉐이",
    "젓같내",
    "젓같네",
    "젓같은",
    "젓까",
    "젓까는",
    "젓까리",
    "젓나",
    "젓나게",
    "젓냄새",
    "젓대가리",
    "젓더",
    "젓도",
    "젓떠",
    "젓마난",
    "젓마니",
    "젓마무리",
    "젓만이",
    "젓만한",
    "젓물",
    "젓물냄새",
    "젓밥",
    "젓빠지게",
    "젓은팬티",
    "晶",
    "㊣",
    "顶360",
    "程凱",
    "正見網",
    "正见网",
    "丁關根",
    "靖國神社",
    "靖国神社",
    "政權",
    "鄭麗文",
    "정력",
    "정력강화용품",
    "정력팬티",
    "정력포탈",
    "정박아",
    "政变",
    "鄭寶清",
    "情婦",
    "政府",
    "政府无能",
    "政府丑闻",
    "정사",
    "정사씬모음",
    "정사채널",
    "情色",
    "情色",
    "情色导航",
    "情色六月天",
    "情色网",
    "情色文学",
    "情色艺术天空",
    "情色五月",
    "情色五月天",
    "情色帝国",
    "情色综合站",
    "情色天崖",
    "丁关根",
    "情獸",
    "正式做（无套）",
    "正式做（有套）",
    "精神领袖",
    "精神薄弱児",
    "精神病",
    "정액",
    "精液",
    "정액마셔",
    "정액먹어",
    "정액발사",
    "精液浴",
    "精液榨取",
    "정액짜",
    "정액핧아",
    "精液横飞",
    "鄭餘鎮",
    "正悟网",
    "情欲",
    "정우성인용품전문점",
    "鄭運鵬",
    "鄭源",
    "庭院警衛兵",
    "靓乳",
    "鄭義",
    "正義黨論壇",
    "精子",
    "精子",
    "丁字裤",
    "丁字裤翘臀",
    "丁子霖",
    "정자마셔",
    "정자먹어",
    "정자핧아",
    "貞操",
    "贞操带手铐",
    "静坐",
    "征集业主意见",
    "程鐵軍",
    "精蟲",
    "情趣熟妇",
    "情趣自拍",
    "政治",
    "政治反對派",
    "政治反对派",
    "政治犯",
    "政治体制改革",
    "政治风波",
    "政治黑暗",
    "侦探设备",
    "靓逼",
    "丁香社区",
    "丁香成人社区",
    "靓穴",
    "젖",
    "젖가슴",
    "젖가튼",
    "젖같내",
    "젖같네",
    "젖같은",
    "젖고",
    "젖고픈",
    "젖까",
    "젖까는",
    "젖꼭지",
    "젖나",
    "젖나게",
    "젖도",
    "젖마난",
    "젖만한",
    "젖물",
    "젖밥",
    "젖밥새귀",
    "젖빠지게",
    "젖은팬티",
    "젖이다",
    "젖탱이",
    "젖통",
    "젗같은",
    "젗까",
    "젗도",
    "젗밥",
    "젙같은",
    "젙니",
    "鱭",
    "帝國主義",
    "帝国主义",
    "제기랄",
    "齊諾",
    "제랄",
    "齊墨",
    "制服美妇",
    "制服狩",
    "制服诱惑",
    "制服淫妹",
    "堤莎也加",
    "济世灵文",
    "帝顺",
    "제이제이일본성인만화",
    "制作工艺",
    "齐志勇",
    "诸侯进京",
    "져꽈튼",
    "졎꼭지",
    "졎꼭찌",
    "操",
    "操",
    "祚",
    "繰",
    "銚",
    "肏",
    "肏",
    "阼",
    "操b",
    "操b",
    "操B内射",
    "操B自拍",
    "操B指南",
    "操GM",
    "操XX",
    "趙zi陽",
    "赵zi阳",
    "粗チン",
    "粗ちん",
    "粗マン",
    "粗まん",
    "赵家堡有动物异常现象",
    "조가튼",
    "조가튼년",
    "조가틍",
    "朝冈实岭",
    "曹剛川",
    "曹刚川",
    "조개나빨어",
    "조개넓은년",
    "조개따조",
    "조개따조?",
    "조개마셔줘",
    "조개마셔줘?",
    "조개벌려조",
    "조개벌려조?",
    "조개속물",
    "조개쑤셔줘?",
    "조개핧아줘",
    "조개핧아줘?",
    "조건가능",
    "조건만남",
    "趙建銘",
    "找建总行",
    "操雞",
    "操鶏",
    "找工总行",
    "调教",
    "调教嫩逼",
    "调教老外",
    "调教性奴",
    "调教虐待",
    "操狗",
    "操機歪",
    "操機八毛",
    "조까",
    "조까는",
    "조까라",
    "조까치",
    "조까튼",
    "조깐다",
    "조깟",
    "조깥",
    "조껀",
    "조껀만남",
    "조끗",
    "조끗네",
    "操那嗎B",
    "操那嗎B",
    "操那嗎比",
    "操那嗎比",
    "操那嗎逼",
    "操那嗎逼",
    "趙南",
    "조낸",
    "找农总行",
    "操嫩屄",
    "操嫩穴",
    "操尼",
    "操你",
    "操你",
    "操妳",
    "叼你",
    "操你B",
    "操你娘",
    "操妳娘",
    "操你奶奶",
    "操你大爺",
    "操你老媽",
    "操你老母",
    "操你媽",
    "操你媽",
    "操你妈",
    "操妳媽",
    "操妳妈",
    "叼你媽",
    "叼你妈",
    "肏你妈",
    "操你媽個B",
    "操你媽個屄",
    "操你媽屄",
    "操你妈屄",
    "操你媽的",
    "操你媽的B",
    "操你媽的逼",
    "操你爺爺",
    "操你爺爺",
    "操你全家",
    "操你祖宗",
    "操妳祖宗",
    "操你爸",
    "操你八辈祖宗",
    "操蛋",
    "操蛋",
    "조刀ㅏ",
    "조둥아리",
    "조또",
    "操烂",
    "조랄",
    "조뢀",
    "操林",
    "操母狗",
    "조물락",
    "朝美穗香",
    "造反",
    "조밥",
    "潮喷",
    "操比",
    "操比",
    "操屄",
    "肏屄",
    "操屄术",
    "操屄混戰",
    "조빠",
    "조빠라",
    "조빠러",
    "조빠지게",
    "조빱",
    "조뺑이",
    "조삐리",
    "操射",
    "操死",
    "操死",
    "조사단",
    "조사반",
    "조사부",
    "操爽",
    "朝鮮",
    "朝鲜",
    "朝鮮人",
    "朝鮮征伐",
    "早泄",
    "조센징",
    "操骚逼",
    "助手威爾特",
    "操我",
    "造愛",
    "釣魚島",
    "钓鱼岛",
    "钓鱼岛不属于中国",
    "钓鱼台",
    "조오가튼",
    "조오건",
    "조오까",
    "조오까튼",
    "조오또",
    "조오오조",
    "조오온니",
    "조오올라",
    "조오옷",
    "조오우까튼",
    "조오웃",
    "조오지",
    "조옥같네",
    "조온니",
    "조온마니",
    "조온만",
    "조올라",
    "조옴마니",
    "조옷",
    "조옷까",
    "조옷나",
    "조옷나게",
    "조옷만",
    "조옺",
    "操王",
    "조우까튼",
    "操幼自拍",
    "趙紫陽",
    "赵紫阳",
    "曹長青",
    "조쟁이",
    "操她",
    "操她",
    "操她妈",
    "调情",
    "粗制吗啡",
    "粗製嗎啡",
    "조져",
    "操肿",
    "조지",
    "조지냐",
    "조진다",
    "조질래",
    "操妻",
    "朝天穴",
    "粗屌",
    "潮吹",
    "潮吹",
    "조카툰",
    "操他",
    "操他",
    "操他妈",
    "조털",
    "趙品潞",
    "操逼",
    "操逼",
    "肏逼",
    "操逼毛",
    "趙海青",
    "操穴",
    "操穴喷水",
    "趙曉微",
    "操黑",
    "调戏",
    "鰷",
    "鸟GM",
    "鸟你",
    "족가튼",
    "足脚交",
    "족간네",
    "족같",
    "족같내",
    "족같네",
    "족같은",
    "足交",
    "족까",
    "족까내",
    "족딸",
    "족발이",
    "족밥",
    "足切り",
    "졳",
    "존나",
    "존나게",
    "존나아",
    "존년",
    "존니",
    "존마나",
    "존마난",
    "존마니",
    "존만",
    "존만아",
    "존만이",
    "존만한",
    "존만한년",
    "존망이",
    "존맹구",
    "존물",
    "존슨",
    "졷까",
    "졷따",
    "졸라",
    "졸라게",
    "졸라맨",
    "좀년",
    "좀마니",
    "좀만아",
    "좀물",
    "좀쓰레기",
    "좁년",
    "좁밥",
    "좁빠라라",
    "좁빱",
    "좃",
    "좃가튼",
    "좃가튼년",
    "좃가튼놈",
    "좃가튼뇬",
    "좃가튼뇽",
    "좃가튼늠",
    "좃간 년",
    "좃간년",
    "좃같내",
    "좃같네",
    "좃같은",
    "좃같은년",
    "좃까",
    "좃까는",
    "좃까라",
    "좃까리",
    "좃깟네",
    "좃나",
    "좃나게",
    "좃냄새",
    "좃넘",
    "좃니",
    "좃대가리",
    "좃도",
    "좃또",
    "좃마난",
    "좃마무리",
    "좃만아",
    "좃만이",
    "좃만한",
    "좃만한것",
    "좃만한쉐이",
    "좃물",
    "좃물냄새",
    "좃밥",
    "좃뱅이",
    "좃보지",
    "좃부랄",
    "좃빠구리",
    "좃빠네",
    "좃빠라라",
    "좃빠지게",
    "좃이나",
    "좃털",
    "좄",
    "좄까",
    "종간나",
    "宗教",
    "宗教极端主义",
    "종나",
    "종년",
    "종놈",
    "纵览中国",
    "鐘山風雨論壇",
    "纵情兵团",
    "纵火",
    "좆",
    "좆가튼",
    "좆같다",
    "좆같아",
    "좆같은",
    "좆같은년",
    "좆같은놈",
    "좆같은새끼",
    "좆같은색키",
    "좆같은세끼",
    "좆같은쉐리",
    "좆같은쉑캬",
    "좆같은쉑키",
    "좆구녕",
    "좆구멍",
    "좆귀두",
    "좆까",
    "좆까는",
    "좆까라",
    "좆꼴려",
    "좆꼴리",
    "좆나",
    "좆나게",
    "좆년",
    "좆대가리",
    "좆대에",
    "좆도",
    "좆또",
    "좆마난",
    "좆만아",
    "좆만한",
    "좆만한년",
    "좆만한놈",
    "좆만한뇽",
    "좆만한늠",
    "좆만한새끼",
    "좆머거",
    "좆먹어",
    "좆목질",
    "좆물",
    "좆밥",
    "좆비비",
    "좆빠",
    "좆빠라",
    "좆빠러",
    "좆빠지게",
    "좆빨아",
    "좆빨이",
    "좆뺑이",
    "좆삐리",
    "좆새끼",
    "좆선족",
    "좆쑤시",
    "좆터래길",
    "좆털",
    "좆헛물",
    "좇",
    "좇같은",
    "좇같은년",
    "좇까",
    "좇나",
    "좇도",
    "좇또",
    "좇밥",
    "좉",
    "좋만",
    "좋만한 것",
    "좋만한것",
    "左营海军军官学校",
    "좌음",
    "좌이버",
    "좌이트",
    "左翼",
    "坐庄",
    "좌좀",
    "좌좀년",
    "좌지",
    "坐台",
    "坐台的",
    "左派",
    "죄랄",
    "죠까틍",
    "죠카태",
    "죠카테",
    "죤나",
    "죤니",
    "죤만년",
    "죤만놈",
    "죤만한색기",
    "죨라",
    "죳",
    "죳나",
    "죳도",
    "죴",
    "죵나",
    "죵늬",
    "죶",
    "죶가튼",
    "죶같은",
    "죶나",
    "죶니",
    "죶댓까면서",
    "죶마난",
    "죶빠지게",
    "죷까",
    "죷맹구",
    "죷카",
    "죹",
    "주거라",
    "주글년",
    "주글래",
    "주길년",
    "주댕이",
    "주뎅이",
    "주둥아리",
    "주둥이",
    "주우둥이",
    "주절먹",
    "㈱",
    "輳",
    "籀",
    "邾",
    "周健康",
    "做雞",
    "主攻指揮官",
    "走光偷拍",
    "周群",
    "驻军",
    "朱德",
    "周六性吧",
    "朱立倫",
    "筹码",
    "做梦死",
    "周柏豪",
    "周柏豪",
    "酒瓶扩肛",
    "周鋒鎖",
    "朱鳳芝",
    "主佛",
    "走私",
    "主席",
    "周小康",
    "周守訓",
    "主神教",
    "做鴨",
    "做愛",
    "做愛",
    "做爱",
    "做爱姿势",
    "周永康",
    "肘永康",
    "朱容鸡",
    "朱容基",
    "朱熔基",
    "朱镕基",
    "朱溶劑",
    "朱溶剂",
    "朱狨基",
    "周恩來",
    "周恩来",
    "主義",
    "驻藏",
    "主场新闻",
    "酒店援交",
    "朱駿",
    "注册后",
    "周总理",
    "朱总理",
    "走向圆满",
    "粥健康",
    "竹联帮",
    "竹笋乳",
    "죽여버린다",
    "죽여버릴꺼야",
    "죽여줘",
    "粥永康",
    "죽은년",
    "죽을년",
    "죽을놈",
    "죽인년",
    "죽일꺼",
    "죽일년",
    "죽통",
    "蠢蛋",
    "蠢猪",
    "蠢豬",
    "中谷香子",
    "中共",
    "中共",
    "中共监狱",
    "中共警察",
    "中共高層軍權鬥爭",
    "中共高层军权斗争",
    "中共高层内幕",
    "中共高层斗争",
    "中共高层黑幕",
    "中共垮臺",
    "中共垮台",
    "中共軍隊血腥鎮壓",
    "中共军队血腥镇压",
    "中共軍隊黑幕",
    "中共军队黑幕",
    "中共獨裁",
    "中共独裁",
    "中共獨裁暴政",
    "中共独裁暴政",
    "中共独枭",
    "中共領導人黑幕",
    "中共领导人黑幕",
    "中共迫害",
    "中共幫兇",
    "中共帮凶",
    "中共粉飾太平",
    "中共粉饰太平",
    "中共查禁",
    "中共邪黨",
    "中共邪党",
    "中共邪恶",
    "中共恶霸",
    "中共伪政权",
    "中共偽政權",
    "中共一党专制",
    "中共的死穴",
    "中共的暴政",
    "中共专制",
    "中共政坛腐败内幕",
    "中共政治课",
    "中共走狗",
    "中共中央黑幕",
    "中共统治危机",
    "中共統治危機",
    "中共暴政",
    "中共陷內鬥",
    "中共陷内斗",
    "中共海外滲透",
    "中共血腥镇压",
    "中共还在祖国横行",
    "中共黑匣子",
    "中共黑幕",
    "中共黑帮",
    "重口味",
    "中欧新闻网",
    "中国のチベット自治区",
    "中国高干子女名单",
    "中國孤兒院",
    "中国高层领导的子女现状",
    "中國共産黨",
    "中国恐怖组织",
    "中国难以避免陷入内乱",
    "中国领导人最强阵容",
    "中國論壇",
    "中国茉莉花革命",
    "中國問題論壇",
    "中国民间保钓联合会",
    "中国民众赴日本大使馆",
    "中国密报",
    "中國復興論壇",
    "中國社會論壇",
    "中國社會進步",
    "中國社會進步黨",
    "中国社会进步党",
    "中国性爱城",
    "中国成人论坛",
    "中国时报",
    "中国时报新竹分社",
    "中國威脅論",
    "中國移動通信",
    "中国人权双周刊",
    "中國猪",
    "中国政局",
    "中國之春",
    "中国之春",
    "中國真實內容",
    "中国真实内容",
    "中国太子党名单",
    "中國和平",
    "중궈",
    "중꿔",
    "中南海",
    "中年美妇",
    "中毒圖騰",
    "中毒的圖騰",
    "중력절",
    "中文搜性网",
    "중삐리",
    "중삘",
    "中山领导流浪汉",
    "中宣部",
    "中时电子报",
    "中俄邊界",
    "中央警卫局大换血",
    "中央高干子弟名单",
    "中央权力被架空",
    "中央社",
    "中央社新闻网",
    "中央委员",
    "中央日报网络报",
    "中央電視臺",
    "中央派系斗争",
    "中越自卫反击战",
    "中奖",
    "中出",
    "中出し",
    "中出肛交",
    "中出解禁",
    "中特",
    "中華講清",
    "中华大陆行政执事站",
    "中華大衆",
    "中華大地",
    "中华联邦政府",
    "中華民國",
    "中华民国",
    "中華時事",
    "中華養生益智",
    "中華養生益智功",
    "中華人民實話",
    "中華人民實話實說",
    "中华人民实话实说",
    "中華人民正邪",
    "中华人民正邪",
    "中华日报",
    "中华电视公司",
    "中華真實報道",
    "中华抗日同盟",
    "줫밥",
    "줴섭다",
    "쥐랄",
    "쥐보지",
    "騭",
    "즐길녀",
    "즐길성인",
    "즐길여",
    "즐색",
    "즐섹",
    "曾庆红",
    "曾慶紅步江後塵",
    "曾庆红步江后尘",
    "曾慶紅十七大下課",
    "曾庆红十七大下课",
    "曾慶紅被迫寫信十七大退",
    "曾庆红被迫写信十七大退",
    "曾道人",
    "曾培炎",
    "增值票",
    "즤랄떠냐",
    "躓",
    "지.랄",
    "地まわり",
    "舐めたい",
    "지껄여",
    "지껄여봐",
    "支那",
    "支那そば",
    "支那チク",
    "支那料理",
    "支那人",
    "指導員",
    "遲鈍的圖騰",
    "지랄",
    "지랄무지개",
    "지랄삽차기",
    "지랄쌈치기",
    "지랄육갑",
    "지랄이",
    "지랄하",
    "지랄하는",
    "지랄해",
    "지랗",
    "지랠",
    "지랼",
    "지럴",
    "支联会",
    "지롤",
    "지뢀",
    "지룔",
    "지룰",
    "지미",
    "지미랄",
    "지미럴",
    "지보",
    "持不同政見",
    "持月真由",
    "지이라알",
    "지이랄",
    "指點江山論壇",
    "지좆",
    "支持360",
    "支持达赖",
    "地下教會",
    "地下的先烈们",
    "迟浩田",
    "镇江公安局",
    "陳建銘",
    "陳景俊",
    "陈功",
    "陈冠希",
    "进口汽狗销售",
    "陳菊",
    "陳軍",
    "陳唐山",
    "陈独秀",
    "震动棒",
    "陳良宇",
    "陈良宇",
    "真理教",
    "陳蒙",
    "陳博志",
    "阵法五行",
    "陳炳基",
    "真相调查团",
    "陈腊肠",
    "陳宣良",
    "真善美",
    "真善忍",
    "进城",
    "陳小同",
    "陳水扁",
    "陈水扁",
    "真实人妻",
    "鎮壓",
    "镇压",
    "姫辱",
    "陈云",
    "唇肉",
    "陈毅",
    "真人绣",
    "真人主播",
    "真一善一美",
    "陳一諮",
    "陳定南",
    "真主",
    "真主党",
    "真主安拉",
    "陈至立",
    "陈智敏",
    "秦青的幸福生活",
    "陳總統",
    "陳破空",
    "陳學聖",
    "陈晓宁",
    "陳希同",
    "陈希同",
    "질사",
    "질알",
    "질질싸",
    "질퍽하게",
    "질할",
    "縶",
    "集体性爱",
    "集体淫",
    "执政党的罪错",
    "짓껄이",
    "짜샤",
    "짜쌰",
    "짜져",
    "짜져자",
    "짜쥐",
    "짜지",
    "짬지",
    "짭것",
    "짭년",
    "짭놈",
    "짭뇬",
    "짭새",
    "짱보",
    "째냐",
    "째려",
    "쩌껀",
    "쩝밥",
    "쩤밥",
    "쪼가리",
    "쪼갈",
    "쪼건",
    "쪼까",
    "쪼까튼",
    "쪼껀",
    "쪼다",
    "쪼다새끼",
    "쪼물딱",
    "쪼물락",
    "쪼빠라",
    "쪼빤다",
    "쪽국",
    "쪽발이",
    "쫀나",
    "쫀물",
    "쫍밥",
    "쫍빱",
    "쫏",
    "쫒",
    "쫒갈",
    "쫒같",
    "쫓",
    "쬬다",
    "쭈쭈",
    "찌끄래기",
    "찌랄",
    "찌랄이",
    "찌지리",
    "찌찌",
    "찢어버린다",
    "鹺",
    "車侖",
    "车仑",
    "車侖女幹",
    "车模兽兽自拍视频",
    "车臣",
    "次旺仁增",
    "次仁益西",
    "諑",
    "诼",
    "錯B",
    "錯那嗎B",
    "戳那嗎B",
    "錯那嗎比",
    "戳那嗎比",
    "錯那嗎逼",
    "戳那嗎逼",
    "戳你",
    "錯比",
    "錯逼",
    "纘",
    "缵",
    "躜",
    "躦",
    "察警U行民人",
    "扎瓦赫里",
    "扎卡维",
    "扎卡維是英雄",
    "扎卡维是英雄",
    "扎针党",
    "扎针事件",
    "讖",
    "槧",
    "譖",
    "鏨",
    "黲",
    "參加齊撐粵語大行動",
    "参加齐撑粤语大行动",
    "参谋本部",
    "参事室",
    "참수",
    "昌",
    "錆",
    "閶",
    "抢劫",
    "枪决女犯",
    "枪决现场",
    "枪械制造图纸",
    "枪械制造方法",
    "娼妓",
    "猖妓",
    "창남",
    "창녀",
    "창녀버지",
    "창녀촌",
    "창년",
    "창년벼지",
    "창뇨",
    "창뇬",
    "昌都",
    "沧澜曲",
    "창부",
    "创世之子猎艳之旅",
    "枪手",
    "창여",
    "苍井空",
    "枪支",
    "枪支弹药",
    "蔡啓芳",
    "채널레드TV",
    "蔡崇國",
    "菜市口交",
    "蔡安季",
    "채워줘",
    "彩票",
    "采花堂",
    "采花坊",
    "册那",
    "册那娘餓比",
    "챵녀",
    "챵년",
    "챵뇬",
    "处男",
    "处女",
    "处女开包",
    "처녀막",
    "處女膜",
    "處女膜",
    "处女屄",
    "处女终结者",
    "처머거",
    "처먹",
    "처먹어",
    "처발라",
    "躑",
    "賤",
    "蕆",
    "囅",
    "贱",
    "韉",
    "天AN门",
    "賤B",
    "賤B",
    "濺b",
    "賤bi",
    "天剑二型飞弹",
    "天剑一型飞弹",
    "賤女人",
    "贱奴",
    "川岛和津实",
    "天灭珙党",
    "天滅中共",
    "天灭中共",
    "天父的儿女",
    "賤比",
    "贱比",
    "千涩",
    "天线宝宝",
    "天安門",
    "天安门",
    "天安門大屠殺",
    "天安门大屠杀",
    "天安門屠殺",
    "天安門錄影帶",
    "天安門流血",
    "天安门流血",
    "天安門母親",
    "天安門事件",
    "天安门事件",
    "天按門事件",
    "天安門時報",
    "天安门运动",
    "天安門一代",
    "天安門坦克",
    "天眼日报社",
    "天閹",
    "天然素人",
    "穿越浏览器",
    "穿乳夹阴",
    "賤人",
    "賤人",
    "贱人",
    "千人斩",
    "天葬",
    "天藏",
    "千摺り",
    "天梯书店",
    "天朝60年编年史",
    "天朝六十年编年",
    "賤種",
    "賤種",
    "天主教",
    "天地行技术论坛",
    "浅仓舞",
    "天天干",
    "天天干贴图",
    "天天情色",
    "賤逼",
    "賤逼",
    "濺逼",
    "贱逼",
    "天下杂志",
    "川合里美",
    "天海丽",
    "賤貨",
    "賤貨",
    "贱货",
    "天皇陛下",
    "凸",
    "哲蚌寺",
    "哲蚌市",
    "凸肉优",
    "徹底陵辱",
    "僉",
    "舔B",
    "舔脚",
    "尖阁列岛",
    "舔鸡巴",
    "舔抠",
    "舔菊花",
    "舔奶",
    "舔蛋",
    "舔屄",
    "舔屁股沟",
    "添屁沟",
    "舔屁沟",
    "舔屁眼",
    "舔西",
    "舔我雞巴",
    "舔我老二",
    "舔阴",
    "舔足",
    "舔逼",
    "舔穴",
    "钻插",
    "请加QQ",
    "青姦",
    "青年肉身挡坦克",
    "青年日报",
    "请大家转发维族朋友",
    "청부",
    "청부살인",
    "청부폭력",
    "清晰内射",
    "清晰炮图",
    "请秀群",
    "清心论坛",
    "请愿书",
    "青天白日",
    "达杜拉",
    "达兰萨拉",
    "达赖",
    "达赖喇嘛",
    "达赖喇嘛万岁",
    "达赖喇嘛复兴之路",
    "达赖喇嘛的复兴之路",
    "达赖喇嘛的智慧箴言",
    "达赖喇嘛即将返藏",
    "达赖返藏",
    "达赖集团",
    "체모",
    "达米宣教会",
    "达菲鸡",
    "蒂顺",
    "缔顺",
    "체위",
    "체위동영상",
    "쳐먹",
    "쳐먹어",
    "쳐발라",
    "쳐쑤셔박어",
    "쳐죽",
    "쳐죽일",
    "草",
    "譙",
    "軺",
    "齠",
    "屌",
    "屌",
    "屌7",
    "屌7",
    "招鶏",
    "屌鳩",
    "屌鸠",
    "超级性虐",
    "超级娱乐中心",
    "招妓",
    "招妓",
    "草你",
    "草你",
    "屌你",
    "屌妳",
    "屌妳",
    "草泥马",
    "草你媽",
    "草你妈",
    "超大逼",
    "超桃尻",
    "草榴",
    "草榴社区",
    "草妈",
    "屌毛",
    "屌毛",
    "超毛大鲍",
    "超美炮图",
    "硝酸甘油",
    "超爽少妇",
    "超爽淫图",
    "超爽炮图",
    "屌西",
    "屌西",
    "硝铵炸药的制作方法",
    "超淫",
    "草擬媽",
    "草拟妈",
    "草的你妈",
    "超靓",
    "屌七",
    "屌七",
    "肖特",
    "硝化甘油",
    "躅",
    "瘃",
    "促成政治改革",
    "촌년",
    "偬",
    "总局",
    "總理",
    "总理",
    "总的一封信",
    "总政治部",
    "总参",
    "总统府",
    "촹녀",
    "촹년",
    "催眠迷幻水",
    "最新色站",
    "최음제",
    "催情药",
    "最快报码室",
    "諏",
    "陬",
    "隹",
    "诹",
    "鄹",
    "鯫",
    "鲰",
    "推女郎",
    "抽你丫的",
    "抽头",
    "推翻",
    "追查国际",
    "抽插",
    "抽水",
    "秋元友美",
    "推油",
    "舳",
    "畜生",
    "轴永康",
    "春光外泻",
    "春宫图",
    "春药",
    "春风劳动争议",
    "春夏自由論壇",
    "出轨",
    "出售54式",
    "出售56军刺",
    "出售56式半刺刀",
    "出售假币",
    "出售假钞",
    "出售健卫小口径步枪",
    "出售圣甲虫跳刀",
    "出售军刀",
    "出售军用手狗",
    "出售军用五连发手枪",
    "出售军刺",
    "出售金钟气枪",
    "出售跳刀",
    "出售仿真手狗",
    "出售三棱刀",
    "出售三唑仑",
    "出售手",
    "出售手qiang",
    "出售手拉鸡",
    "出售兽用麻醉枪",
    "出售手枪",
    "出售神符",
    "出售广州三箭气枪",
    "出售铅弹气枪",
    "出售瓦斯手狗",
    "出售自制手枪",
    "出售猎枪",
    "出售账号",
    "出售电狗",
    "出售左轮手枪",
    "出售此号",
    "出售枪",
    "出售枪支",
    "出售弹簧刀",
    "出售海洛因",
    "出售虎头双管",
    "出售火药动力钢珠枪",
    "출장마사지",
    "出戻り",
    "出海志愿者报名电话",
    "充气娃娃",
    "充当涉毒场所保护伞",
    "冲赛康",
    "就去干",
    "就去男孩",
    "就去色色",
    "就去诱惑",
    "就去日",
    "就去嫖",
    "臭鸡",
    "臭鸡巴",
    "臭机",
    "臭机八",
    "吹气娃娃",
    "吹喇叭",
    "吹喇叭",
    "臭西",
    "吹簫",
    "吹簫",
    "吹箫",
    "吹萧",
    "取消审批制",
    "吹箫自拍",
    "取消限制媒体跨省",
    "就业歧视",
    "就要色",
    "聚集",
    "臭化西",
    "聚会",
    "侧那",
    "测拿",
    "厕奴",
    "厕所盗摄",
    "厕所偷拍",
    "測試員",
    "測試人員",
    "测绘局",
    "鯔",
    "鴟",
    "觶",
    "鲻",
    "痴鳩",
    "痴拈",
    "치마속",
    "耻辱轮奸",
    "痴乳",
    "癡漢",
    "痴漢プレイ",
    "耻肛",
    "致胡书记的公开信",
    "則民",
    "櫬",
    "齔",
    "亲共行动",
    "친년",
    "친놈",
    "親美",
    "親民黨",
    "亲民党",
    "親日",
    "七万言书",
    "七五纪念",
    "七五事件",
    "七五事件一周年",
    "七五暴行图片",
    "七月七日",
    "七贤帮",
    "唚",
    "针孔摄像",
    "针孔偷拍",
    "侵略",
    "沉謎",
    "沉迷",
    "沉谜",
    "沉睡圖騰",
    "侵害业主正当权益",
    "카X",
    "카섹",
    "카섹스",
    "칼라섹스",
    "캐세끼",
    "캐셋키",
    "캐쉬공짜",
    "캐쉬무료",
    "캐쉬버그",
    "캐쉬얼려",
    "캐쉬올려",
    "캐시공짜",
    "캐시무료",
    "캐시버그",
    "캐시얼려",
    "캐시올려",
    "캐씨버그",
    "캐씨얼려",
    "캐씨올려",
    "커닐링구스",
    "커엄세엑",
    "컴S",
    "컴X",
    "컴색",
    "컴샷",
    "컴세엑",
    "컴섹",
    "컴쌕",
    "컴쎅",
    "컴투스",
    "케새킹",
    "케쉬얼려",
    "케쉬올려",
    "케시버그",
    "코리아성인가이드",
    "코리아스트립닷컴",
    "코리아엑스파일",
    "코리아포로노",
    "코리안걸스",
    "코섹스",
    "콘끼그",
    "콘돔",
    "콘돔닥터",
    "콘돔몰",
    "콘돔사용",
    "콘돔예스",
    "콘돔피아",
    "콘필",
    "콜걸",
    "快乐AV",
    "쿤닐링구스",
    "쿨에로",
    "크리토리스",
    "큰년",
    "큰놈",
    "큰보지",
    "큰자지",
    "큰좇",
    "클럽AV스타",
    "클럽에로",
    "클리토리스",
    "큼섹",
    "킬링티비",
    "鼉",
    "打、烧、抢、砸",
    "他ㄇ的",
    "他干",
    "他幹",
    "打款",
    "他娘",
    "他娘",
    "他奶娘的",
    "他奶奶",
    "他奶奶的",
    "打奶炮",
    "他爹",
    "打倒",
    "打倒GCD",
    "打倒共鏟",
    "打倒共铲",
    "打倒共产党",
    "打倒毛泽东",
    "他媽",
    "他媽",
    "他妈",
    "他媽ㄉ",
    "它馬ㄉ",
    "打麻雀",
    "他馬的",
    "他媽的",
    "他媽的",
    "他嗎的",
    "他妈的",
    "他马的",
    "它媽的",
    "他母親",
    "他母親",
    "他母亲",
    "躲猫猫死",
    "타부코리아",
    "打飛機",
    "打飞机",
    "打-烧-抢-砸",
    "打手槍",
    "打手枪",
    "타액",
    "打野炮",
    "堕淫",
    "打砸抢",
    "打钱",
    "他操",
    "他祖宗",
    "打枪利器",
    "打泡",
    "打砲",
    "打炮",
    "打炮",
    "打炮内射",
    "倬",
    "籜",
    "卓伯源",
    "坦克",
    "炭疽病毒",
    "吞精",
    "吞精骚妹",
    "彈劾",
    "탈김치",
    "脱内裤",
    "탈라도",
    "脱衣美女",
    "鰨",
    "塔內",
    "塔利班",
    "塔烏",
    "湯光中",
    "荡女",
    "蕩妹",
    "蕩婦",
    "蕩婦",
    "荡妇",
    "紿",
    "鮐",
    "太監",
    "太监",
    "台军",
    "泰奴橋警衛兵",
    "泰奴橋掠奪者",
    "台独",
    "台独党",
    "台独党纲",
    "台独万岁",
    "台独分子",
    "台联",
    "台湾18DY电影",
    "台湾ハゲ",
    "台湾间谍",
    "台湾建国运动组织",
    "台湾共产党",
    "台湾共和国",
    "台湾狗",
    "台湾国",
    "台湾记者协会",
    "台湾党",
    "台湾岛国",
    "台湾图博之友会",
    "台湾独",
    "台湾独立",
    "台湾民国",
    "台湾旺报",
    "台湾自由联盟",
    "台湾帝国",
    "台湾青年独立联盟",
    "台商",
    "太陽報",
    "太阳报",
    "台英社",
    "太子党",
    "台办",
    "台海危机",
    "泽口明日香",
    "擇民",
    "澤民",
    "澤夫",
    "泽井芽衣",
    "撑粤语",
    "攄",
    "터래기",
    "討伐中宣部",
    "討閥中宣部",
    "讨伐中宣部",
    "讨阀中宣部",
    "讨薪",
    "土人",
    "土制C4过程",
    "土左衛門",
    "토탈에로",
    "通姦",
    "통궈",
    "統獨",
    "统独光谱",
    "統獨論壇",
    "통들통들",
    "捅屁眼",
    "統戰",
    "统战部",
    "退dang",
    "腿交",
    "褪党",
    "退黨",
    "退党",
    "退一黨",
    "退一党",
    "退出共产",
    "退出共產",
    "退出中共",
    "偷窺",
    "偷窥",
    "偷窥图片",
    "偷窥视频",
    "投毒",
    "投毒杀人",
    "偷拍",
    "偷拍美穴",
    "偷拍幼幼",
    "透视软件",
    "투앤투동거클럽",
    "偷情",
    "偷情",
    "偷精",
    "偷情俏寡妇",
    "套套堂",
    "套牌",
    "偷欢",
    "트렘플링",
    "特码",
    "특수콘돔",
    "티빨",
    "ㅍㅌ",
    "罢考",
    "罢工",
    "破瓜",
    "罢课",
    "罢教",
    "波胆",
    "巴倫侍從",
    "巴倫坦",
    "破麻",
    "破嘛",
    "把网民的事",
    "破网软件",
    "破坏",
    "罢食",
    "罢餐",
    "破處",
    "破处",
    "波推",
    "波推龙身",
    "爸爸干女儿",
    "波霸",
    "破鞋",
    "팍큐",
    "贩毒",
    "办理文凭",
    "办理证件",
    "㈧",
    "八角街",
    "八九",
    "八九年",
    "八九民運",
    "八九民运",
    "八九事件",
    "八九风波",
    "八九學運",
    "八九学运",
    "八九學潮",
    "八九学潮",
    "八道娱乐网",
    "八零二三部队",
    "八方国际资讯",
    "八一",
    "패니스",
    "패닡",
    "敗類",
    "패션앤섹스",
    "貝尤爾",
    "贝肉",
    "霸占土地",
    "패티쉬",
    "패티쉬우먼",
    "패티시",
    "팬T",
    "팬티",
    "팬티캔디",
    "팰라치오",
    "팸돔",
    "彭德怀",
    "彭丽媛",
    "彭博",
    "彭博商业周刊",
    "彭博新闻社",
    "彭真",
    "彭湖",
    "퍽유",
    "퍽큐",
    "펀색",
    "펀섹",
    "페니스",
    "페로몬",
    "페미돔",
    "페티걸",
    "페티쉬",
    "페티쉬러브",
    "페티쉬매거진",
    "페티쉬우먼",
    "페티쉬즘",
    "페티쉬코리아",
    "페티시",
    "페팅",
    "펜섭",
    "펜썹",
    "펜티",
    "펠라티오",
    "펨돔",
    "펨둠",
    "펨섭",
    "諞",
    "片手落ち",
    "鞭打",
    "苹果日报",
    "姘頭",
    "平反",
    "平叛",
    "평신",
    "平井玛莉亚",
    "废物",
    "废除劳教制度",
    "廃疾",
    "廢墟守護者",
    "포경",
    "鮑戈",
    "炮图",
    "葡萄小穴",
    "鮑彤",
    "鲍彤",
    "布萊爾",
    "布莱尔",
    "포로노",
    "布雷爾",
    "布雷尔",
    "포루X",
    "포루노",
    "포르x",
    "포르노",
    "포르노사진",
    "포르노세상",
    "포르노섹스",
    "포르노테이프",
    "포르노테입",
    "포르노테잎",
    "포르느",
    "포르로",
    "泡沫經濟",
    "鲍朴",
    "炮房",
    "包房淫乱",
    "布什",
    "布什问锦涛",
    "鲍岳桥",
    "包夜",
    "鲍鱼娇",
    "포온섹",
    "炮友",
    "鲍威尔",
    "鮑伊",
    "包二奶",
    "포주",
    "布达拉",
    "包皮",
    "包皮",
    "暴奸",
    "暴干",
    "曝光中共",
    "爆轰",
    "暴菊花",
    "暴君",
    "暴徒",
    "暴动",
    "暴-动",
    "爆动",
    "暴亂",
    "暴乱",
    "暴力",
    "暴力镇压",
    "暴力拆迁",
    "暴力虐待",
    "暴露股沟",
    "暴发",
    "爆发",
    "暴插",
    "爆双穴",
    "暴熱的戰士",
    "暴乳",
    "爆乳",
    "爆乳娘",
    "爆乳人妻",
    "暴淫",
    "爆炸",
    "爆炸物",
    "暴政",
    "暴政奧運",
    "暴政奥运",
    "暴操",
    "爆操",
    "暴躁的警衛兵",
    "暴躁的警衛兵靈魂",
    "暴躁的馬杜克",
    "暴躁的城塔野",
    "暴躁的城塔野獸",
    "爆草",
    "暴風亡靈",
    "폰X",
    "폰땍",
    "폰떽",
    "폰색",
    "폰세엑",
    "폰섹",
    "폰섹,스",
    "폰섹.스",
    "폰섹스",
    "폰쉑",
    "폰쌕",
    "폰쎅",
    "폰팅",
    "嫖",
    "鑣",
    "婊",
    "婊",
    "嫖客",
    "嫖客",
    "嫖妓",
    "嫖妓女",
    "嫖妓指南",
    "表日本",
    "表子",
    "婊子",
    "婊子",
    "錶子",
    "婊子養的",
    "婊子養的",
    "嫖娼",
    "标会",
    "品色堂",
    "品香堂",
    "品穴",
    "풋잡",
    "疯狂抽送",
    "冯锦华",
    "馮東海",
    "风流岁月",
    "风山陆军军官学校",
    "风骚",
    "风骚熟妇",
    "风骚侍女",
    "馮素英",
    "风骚欲女",
    "风艳阁",
    "豐饒的果實",
    "風雨神州",
    "風雨神州論壇",
    "风月大陆",
    "风尘劫",
    "프리섹스",
    "플래이보이",
    "플레이보이2030",
    "플레이보이소라가이드",
    "플레이보이온라인",
    "플레이보조개",
    "플레이보지",
    "被狗幹",
    "被内射",
    "被立王",
    "피임기구",
    "피임용품",
    "避孕套",
    "皮条",
    "被操",
    "被肏",
    "皮條客",
    "皮条客",
    "皮鞭",
    "蹕",
    "逼",
    "逼奸",
    "逼你老母",
    "逼毛",
    "逼樣",
    "逼痒",
    "逼样",
    "逼真女下体",
    "贺过墙",
    "賀國强",
    "贺国强",
    "荷兰国际广播电台中文网",
    "賀龍",
    "贺龙",
    "下流地带",
    "夏目奈奈",
    "下体",
    "下三爛",
    "下三濫",
    "河殤",
    "河殇",
    "㉻㉶",
    "下注",
    "下賤",
    "下賤",
    "下體",
    "下體",
    "何候华",
    "澩",
    "虐奸",
    "虐奴",
    "虐待",
    "學聯",
    "学生挡坦克",
    "学生领袖",
    "學生妹",
    "学生妹",
    "學運",
    "学运",
    "學自聯",
    "學潮",
    "学潮",
    "漢奸",
    "韓東方",
    "韓聯潮",
    "한번꽂자",
    "한번주께",
    "한번줄래",
    "한스테이성인",
    "捍卫粤语",
    "捍卫粤语公民行动",
    "汉人无自由",
    "汉人血迹冲洗净",
    "韓正",
    "韩质洁",
    "割れ目",
    "할타",
    "할터",
    "핥아",
    "핥아주께",
    "핥어",
    "핥을래",
    "핧아",
    "핧아줄께",
    "핧아줘",
    "핧어",
    "闞",
    "함대주까",
    "함대줄래",
    "含老二",
    "함빨자",
    "含屌",
    "含屌",
    "함하자",
    "合法财产",
    "哈批",
    "合数玄机诗",
    "嗑药",
    "哈爾羅尼",
    "鴿派",
    "핫도그TV",
    "핫성인용품",
    "肛",
    "肛交",
    "肛交",
    "肛交內射",
    "肛交自拍",
    "港獨",
    "港獨",
    "港独",
    "港独",
    "항문",
    "肛門",
    "肛门",
    "肛门拳交",
    "肛门喷水",
    "항문수셔",
    "항문쑤셔",
    "肛门调教",
    "肛屄",
    "肛插",
    "項小吉",
    "港澳办",
    "抗曰游型",
    "肛肉",
    "抗议升级",
    "抗议传播",
    "抗日",
    "抗日联盟",
    "抗日老兵",
    "肮脏美学",
    "抗战纪念",
    "港支联",
    "抗暴纪念",
    "抗暴连线",
    "項懷誠",
    "海盗的悠闲生活",
    "海洛因",
    "海洛因",
    "嗨粉",
    "解体中共",
    "害羞",
    "嗨药",
    "咳药水",
    "海洋局",
    "解體中共",
    "해피에로",
    "核工业基地",
    "核武器",
    "核潜艇",
    "핸타이",
    "幸运2008",
    "幸运在线",
    "幸运活动",
    "倖田梨纱",
    "行至日本驻深圳领世馆",
    "향기콘돔",
    "乡巴佬",
    "向巴平措",
    "香港uu",
    "香港经济日报",
    "香港独立媒体",
    "香港六星合彩",
    "香港六合彩",
    "香港白小姐",
    "香港报纸",
    "香港商报",
    "香港赛马会",
    "香港人网",
    "香港人撐住呀",
    "香港人撐住呀",
    "香港电台",
    "香港曾道人",
    "香港彩宝必中六肖",
    "香港彩票",
    "香港总彩",
    "香港惠泽社群",
    "香港黄大仙",
    "許家屯",
    "허뎝",
    "许万平",
    "虛無的飽食者",
    "허버리년",
    "허벌",
    "허벌년",
    "허벌보지",
    "허벌자식",
    "허벌자지",
    "허벌창",
    "許信良",
    "虛弱圖騰",
    "許財利",
    "허접새끼",
    "허접쌔끼",
    "허쩝새끼",
    "허쩝쌔끼",
    "宪兵司令部",
    "轩然大波",
    "宪章零八",
    "獻祭的圖騰",
    "헐렁보지",
    "獫",
    "验证通知",
    "헤라러브샵",
    "헤로인",
    "헨타이",
    "헬로우콘돔",
    "헬브레이커",
    "헴타이",
    "혀로보지핧기",
    "赫鲁晓夫",
    "革命",
    "峴",
    "蜆",
    "현18만",
    "현18만원",
    "현거래",
    "玄机图",
    "玄机诗",
    "玄机解生肖",
    "현금18",
    "현금18만원",
    "현금최대",
    "現金",
    "現金交易",
    "现代艳帝传奇",
    "现代情色小说",
    "穴",
    "穴串插",
    "穴图",
    "穴毛",
    "穴爽",
    "血腥图片",
    "血腥镇压",
    "穴淫",
    "血逼",
    "穴海",
    "穴穴",
    "嫌韓",
    "篋",
    "叶剑英",
    "叶山丽子",
    "荊棘護衛兵",
    "邢錚",
    "惠美梨",
    "慧园拾真",
    "惠帝凡是二年",
    "惠澤社群",
    "灝",
    "顥",
    "胡Ｊ涛",
    "胡XX",
    "胡哥对刘德华",
    "胡哥与华仔对话",
    "胡江大斗法",
    "胡江曾内斗",
    "胡景濤",
    "胡景涛",
    "胡系",
    "胡公子",
    "胡喬木",
    "胡乔木",
    "胡錦淘",
    "胡錦滔",
    "胡锦淘",
    "胡锦滔",
    "胡锦涛",
    "胡锦涛秘密来到青岛",
    "胡锦涛与刘晓波的对峙",
    "胡錦濤嫡系大調動",
    "胡锦涛嫡系大调动",
    "胡锦涛调虎离山",
    "胡锦涛走向个人崇拜",
    "胡锦涛陈水扁对对联",
    "胡锦涛和刘德华",
    "胡緊掏",
    "胡紧掏",
    "胡紧涛",
    "胡緊套",
    "胡紧套",
    "호냥년",
    "好嫩",
    "胡惊涛",
    "호로",
    "호로년",
    "호로새꺄",
    "호로새끼",
    "호로새리",
    "호로새캬",
    "호로색",
    "호로색끼",
    "호로색히",
    "호로쉐리",
    "호로쉐캬",
    "호로쌔기",
    "호로쌔꺄",
    "호로오자석",
    "호로자",
    "호로자슥",
    "호로자식",
    "호로잡년",
    "호로잡놈",
    "호루새씨",
    "호루자슥",
    "호루잡년",
    "호르자슥",
    "호모섹기",
    "호모쎄끼",
    "虎门",
    "護法",
    "호봉세씨",
    "호봉쎄끼",
    "호봉쒜리",
    "好屄",
    "호빠",
    "护士诱惑",
    "好色",
    "好色cc",
    "好色网",
    "好色欲女",
    "호스트바",
    "호스트빠",
    "호스티스",
    "湖岸警衛兵",
    "湖岸哨兵隊長",
    "湖岸護衛兵",
    "호양년",
    "胡瘟",
    "胡温",
    "胡温政府",
    "胡溫借國際輿論造勢",
    "胡温借国际舆论造势",
    "胡溫暴政",
    "胡温暴政",
    "胡耀邦",
    "胡耀邦封殺",
    "胡耀邦封杀",
    "胡耀邦平反",
    "豪乳",
    "豪乳靓屄",
    "互淫",
    "蝴蝶骚逼",
    "蝴蝶逼",
    "호좁",
    "胡主席",
    "胡曾内斗公开",
    "胡志明",
    "好地方成人",
    "互舔淫穴",
    "胡總書記",
    "胡总书记",
    "호테스바",
    "호테스빠",
    "呼喊派",
    "胡海峰",
    "豪华",
    "混 沌决",
    "混蛋",
    "混蛋神风流史",
    "混沌决",
    "混亂的圖騰",
    "昏迷圖騰",
    "昏药",
    "혼음",
    "鍃",
    "忽然1周",
    "홀오자식",
    "홍들홍들",
    "紅燈區",
    "紅毛人",
    "洪发交流",
    "红丝熟妇",
    "紅色恐怖",
    "红色贵族",
    "红色政权",
    "紅炎猛獸",
    "红卫兵",
    "洪傳",
    "紅朝謊言錄",
    "红朝谎言录",
    "洪志",
    "洪哲勝",
    "红太阳的陨落",
    "홍팍",
    "红海湾",
    "洪興",
    "洪兴",
    "華建敏",
    "华建敏",
    "和锅枪",
    "华国锋",
    "화끈남",
    "화끈녀",
    "화끈물개",
    "화끈한",
    "화냥",
    "화냥년",
    "货到付款",
    "火辣图片",
    "火辣撩人",
    "火辣写真",
    "화류",
    "花柳",
    "花柳",
    "话里知特码",
    "和服潮吹",
    "火棒",
    "华富财经",
    "화상채팅",
    "华盛顿",
    "華岳時事論壇",
    "花野真衣",
    "火药",
    "화양년",
    "花样性交",
    "華語世界論壇",
    "华尔街日报",
    "华人今日网",
    "花清园",
    "華通時事論壇",
    "華夏文摘",
    "和谐社会花似锦",
    "鸌",
    "鍰",
    "幻想世界",
    "幻想世鎅开心大礼包",
    "幻想爱心礼袋",
    "幻想幻世飞行活动",
    "幻想欢乐送不停",
    "幻想活动礼包",
    "幻世特权",
    "还我们360",
    "欢乐性今宵",
    "换妻",
    "换妻大会",
    "换妻杂交",
    "欢欢娱乐时空",
    "活動",
    "活动-官方",
    "活動員",
    "活動人員",
    "活動專員",
    "活動主持",
    "活动通知",
    "活佛",
    "活摘",
    "貺",
    "黄 菊",
    "黃劍輝",
    "黄瓜插比",
    "黄教",
    "黃菊",
    "黄菊",
    "皇軍",
    "黄金圣水",
    "黃金幼龍",
    "黄大仙",
    "黄盘",
    "黃伯源",
    "黃翔",
    "黄色",
    "黄色图片",
    "黄色漫画",
    "黄色网站",
    "黄色文学",
    "黄色小说",
    "黄色小电影",
    "黄色影视",
    "黄色电影",
    "黃義交",
    "黃慈萍",
    "黃仲生",
    "黃片",
    "黄片",
    "黄海暗杀",
    "黄海刺胡",
    "黃禍",
    "黄祸",
    "噦",
    "回教",
    "回归宪法",
    "回良玉",
    "回民",
    "回民是猪",
    "回民暴動",
    "回民暴动",
    "回民吃猪肉",
    "回族人吃猪肉",
    "懷特",
    "回回",
    "獲獎",
    "黌",
    "嘵",
    "鱟",
    "喉交",
    "후까시",
    "후다",
    "후다년",
    "후라덜넘",
    "후라려",
    "후라질",
    "후래아",
    "후래자슥",
    "후래자식",
    "후랴달",
    "후랴들",
    "후랴들년",
    "후레",
    "후레자식",
    "후리자",
    "후릴",
    "후릴년",
    "후배위",
    "후비파",
    "후우자앙",
    "후우장",
    "후장",
    "후장꽂아",
    "후장뚫어",
    "후장입사",
    "后庭",
    "後亭花",
    "薰樱子",
    "훔쳐보기",
    "諼",
    "毀滅騎士",
    "毀滅步兵",
    "毀滅射手",
    "譎",
    "胸交",
    "胸濤乳浪",
    "胸推",
    "黑警",
    "黑警",
    "黑教",
    "黑崎扇菜",
    "黑妞色情",
    "黑毛屄",
    "黑木香",
    "黑白无常",
    "黑屄",
    "黑丝骚妇",
    "黑丝熟妇",
    "黑社會",
    "黑社会",
    "黑星女侠",
    "黑手黨",
    "黑手党",
    "黑恶势力保护伞",
    "黑人肉棒",
    "黑庄",
    "黑天使",
    "黑屌",
    "黑炮狂插",
    "黑逼",
    "黑火药的制作方法",
    "很黄",
    "吃鸡巴",
    "吃女婴",
    "吃大便",
    "吃大便",
    "吃屎",
    "吃屎",
    "吃精",
    "欠干",
    "欠骑",
    "鑫报e乐网",
    "欽本立",
    "欠薪",
    "欠人骑",
    "吸毒",
    "吸屄掏肛",
    "吸收的圖騰",
    "吸精",
    "吸鸟",
    "吸血獸",
    "兴奋剂",
    "興纊繿聏繼",
    "兴纩繿聏继",
    "흥분시켜",
    "흥분제",
    "흥분할",
    "흥분할녀",
    "餼",
    "希拉克",
    "希望之声",
    "希望之声国际广播电台",
    "姬勝德",
    "嘻游中国",
    "希特勒",
    "히로뽕",
    "ㅗ",
    "후래",
    "홍어",
    "홀닥",
    "혼탕",
    "혼숙",
    "하고싶어",
    "퇴폐",
    "털지갑",
    "촉촉",
    "짱나네",
    "죽일놈",
    "죽인다",
    "죽을래",
    "죽여",
    "죽어라",
    "죽는다",
    "죽고싶지않다면",
    "주글",
    "전신안마",
    "전마나",
    "抵制食堂",
    "粤语",
    "温馨",
    "양아치",
    "야화",
    "암컷",
    "알바비",
    "食堂涨价",
    "시바",
    "스왑",
    "쉐리",
    "不건전",
    "등신",
    "꼰대",
    "깝친",
    "글레머",
    "글래머",
    "게이",
    "제노니아",
    "zenonia",
    "운영자",
    "CM",
    "관잦",
    "관좆",
    "군무새",
    "군쾅이",
    "근친충",
    "김아재",
    "김치남",
    "꽁치남",
    "냄적냄",
    "냄져",
    "네다보",
    "노탄절",
    "느개비",
    "두부외상",
    "레이디가카",
    "번탈남",
    "보혐",
    "빠가남",
    "살남",
    "슨삭절",
    "슨탄절",
    "싸튀충",
    "씹치남",
    "애비충",
    "애호박남",
    "엑엑윽엑",
    "엑윽엑엑",
    "우빨",
    "웅앵웅",
    "워마드",
    "일게이",
    "일베",
    "자댕이",
    "자들자들",
    "자르셋",
    "자릉내",
    "자적자",
    "자트릭스",
    "자혐",
    "좌빨",
    "통탄절",
    "투명애비",
    "팥죽남",
    "한남",
    "한남또",
    "한남유충",
    "한남충",
    "함흥자지",
    "핵슨상",
    "핵펭귄",
    "허버허버",
    "허수애비",
    "후전깨",
    "힘조",
    "메갈리아",
    "메갈",
    "Megalia",
    "gm",
    "zuan石",
    "大量",
    "버지",
    "삽니다",
    "우녕자",
    "망가",
    "고알",
    "공짜로드려요",
    "공짜로드립니다",
    "교성",
    "국뽕",
    "그거잘해",
    "그거하고파",
    "그거하자",
    "그거할남",
    "그거할녀",
    "그거할년",
    "그거할놈",
    "그거할사람",
    "그거할여",
    "그거해",
    "그거해줘",
    "까발려",
    "까발리",
    "까보전",
    "까뷸",
    "까블",
    "깟네",
    "꽁까",
    "꿀어",
    "난자",
    "노릇노릇",
    "노시계",
    "니뽕",
    "니뿡",
    "던주꾸마",
    "던준다",
    "던필요해",
    "동침",
    "뒤지",
    "듀거",
    "듀글래",
    "망사",
    "매드피아",
    "매매",
    "미린",
    "박큐",
    "발바버",
    "발바부",
    "발바브",
    "벌바",
    "변방",
    "빙탁",
    "빙틴",
    "뻑가",
    "뻑꺼",
    "뻘노",
    "뼈다구",
    "뼈다귀",
    "뼉다구",
    "뼉따구",
    "뽀사",
    "뽈노",
    "뽈로노",
    "사시미",
    "삼돌이",
    "삼룡이",
    "성방",
    "스와",
    "식팍",
    "식팔",
    "알르바",
    "알바구함",
    "알바맨",
    "알바쪽지",
    "야항",
    "어벙",
    "옹녀",
    "옹주골",
    "와래즈",
    "와레즈",
    "ㅈㅈ",
    "쭈욱",
    "찌거",
    "처리반",
    "처리부"
];
