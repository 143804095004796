import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import i18n from "i18next";

import { setInfo as setUserInfo, setIsLogin } from "reducers/User";

import { checkIsEmail } from "helpers/Reg.helper";
import { removeAgencyId, setAccessToken, setRefreshToken } from "helpers/Auth.helper";
import { getUserType } from "helpers/Common.helper";

import { getMyInfo, login, saveAppPushInfo, setPushActivate } from "api/common/Auth.api";
import * as AgencyApi from "api/agency/Agency.api";
import * as SchoolApi from "api/school/School.api";
import * as StudentApi from "api/student/Student.api";
import { requestNotificationPermission, listenForForegroundMessages } from "helpers/Firebase.helper";
import { getIsAppInitData, setAppAccessToken, setAppRefreshToken, setIsAppInitData } from "helpers/appLocalStorageHelper";

const LoginComponent = (props: any) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const idRef = useRef<HTMLInputElement>(null);
    const pwRef = useRef<HTMLInputElement>(null);
    const { isPrev, path } = props;
    const { isApp } = useSelector((state: any) => state.common);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false)

    const doLogin = async () => {
        setIsButtonDisabled(true)
        const username: string = idRef?.current?.value || "";
        const password: string = pwRef?.current?.value || "";

        if (checkIsEmail(username)) {
            const result = await login({ username, password });

            if (result) {
                setAccessToken(result?.data?.data?.jwt);
                setRefreshToken(result?.data?.data?.refreshToken);
                
                const myInfoResult = await getMyInfo();

                if (myInfoResult) {
                    const myInfo = myInfoResult.info;
                    if (myInfoResult.info.accountTypeCode === "accountType:student") {
                        const result = await StudentApi.getInfoForStudent();
                        if (result) {
                            myInfoResult.info.profileImage = result.info.profileImageInfo ? result.info.profileImageInfo.url : null;
                        } else {
                            myInfoResult.info.profileImage = null;
                        }
                    }

                    if (myInfoResult.info.accountTypeCode === "accountType:agency") {
                        // cookie의 agencyId 삭제
                        removeAgencyId();

                        console.log(myInfoResult);

                        const result = await AgencyApi.getInfo(myInfoResult.info.userAgencyId);
                        if (result) {
                            myInfoResult.info.logoImageInfo = result.info.logoImageInfo ? result.info.logoImageInfo : null;
                            myInfoResult.info.agencyName = result.info.agencyName;
                        } else {
                            myInfoResult.info.logoImageInfo = null;
                        }
                    }

                    if (myInfoResult.info.accountTypeCode === "accountType:school") {
                        const result = await SchoolApi.getInfo(myInfoResult.info.userSchoolId);
                        if (result) {
                            myInfoResult.info.logoImageInfo = result.schoolInfo.logoImageInfo ? result.schoolInfo.logoImageInfo : null;
                        } else {
                            myInfoResult.info.logoImageInfo = null;
                        }
                    }
                    dispatch(setIsLogin(true));
                    dispatch(setUserInfo(myInfo));

                    // 웹인 경우에만 web push 허용 팝업 노출
                    if (!isApp) {
                        await requestNotificationPermission();
                    } else {
                        // push 정보 저장
                        await saveAppPushInfo();
                        // device의 push 세팅값 가져오기
                        const hasPushPermission = await (window as any).flutter_inappwebview.callHandler("hasPushPermission");
                        // device의 push 세팅값 db에 저장
                        await setPushActivate({ isActivated: hasPushPermission });
                        await setAppAccessToken(result?.data?.data?.jwt);
                        await setAppRefreshToken(result?.data?.data?.refreshToken);
                    }

                    let nextUrl = "";
                    // 로그인하지 않은 상태에서 private 페이지 접근 후 로그인페이지 redirect 한 경우
                    if (location.state?.nextUrl) {
                        nextUrl = location.state.nextUrl;
                    } else {
                        // account type에 따라 next url 분기처리
                        nextUrl = `/${getUserType(myInfo?.accountTypeCode)}`;

                        // 에이전시의 경우 언어 설정이 한글일 경우 제외 영문 처리
                        if (getUserType(myInfo?.accountTypeCode) === "agency") {
                            const lang = i18n.language === "ko" ? "ko" : "en";
                            i18n.changeLanguage(lang);
                        }
                    }

                    if (isPrev) {
                        navigate(path, { replace: true });
                    } else {
                        navigate(nextUrl, { replace: true });
                    }
                }
            }
        }
        setIsButtonDisabled(false)
    };

    useEffect(() => {
        if (idRef.current) {
            idRef.current.focus();
        }
    }, []);

    return (
        <main className="login">
            <div className="con">
                <h2>LOGIN</h2>
                <div className="input-box">
                    <input
                        type="text"
                        placeholder="Email"
                        ref={idRef}
                        onKeyDown={(evt) => {
                            if (evt.key === "Enter") doLogin();
                        }}
                    />
                </div>
                <div className="input-box">
                    <input
                        type="password"
                        placeholder="Password"
                        ref={pwRef}
                        onKeyDown={(evt) => {
                            if (evt.key === "Enter") doLogin();
                        }}
                    />
                </div>
                <div className="reset">
                    <button
                        className="reset-btn"
                        onClick={(e) => {
                            navigate("/password/reset");
                        }}>
                        Reset Password
                    </button>
                </div>
                <div className="login">
                    <button className="login-btn" disabled={isButtonDisabled} onClick={doLogin}>
                        LOGIN
                    </button>
                </div>
            </div>
        </main>
    );
};

export default LoginComponent;
