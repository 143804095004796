import { PAGE_TYPE } from "enums";
import { getLanguageValue } from "locales/i18n";
import React from "react";

const StudentNewRegisterComponent = (props: any) => {
  const { email } = props;
  const {
    id,
    navigate,
    setEmail,
    handleSendLink,
    handleDirectRegister,
    pageParam,
    searchParam,
    genderParam,
    verifiedParam,
    isButtonDisabled
  } = props;

  return (
    <main>
      <div className="school-new-student">
        <div
          className="back-btn"
          onClick={() => {
            navigate(
              `/school/student?page=${pageParam}&search=${searchParam}&gender=${genderParam}&verified=${verifiedParam}`
            );
          }}></div>
        <div className="con">
          <h2>
            {id
              ? getLanguageValue(
                  PAGE_TYPE.SCHOOL,
                  "studentEditRegister.txtTitle"
                )
              : getLanguageValue(
                  PAGE_TYPE.SCHOOL,
                  "studentNewRegister.txtTitle"
                )}
          </h2>
          <div className="input-box">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="email@domain.com"
            />
            {id ? (
              //   <button className="next-btn" onClick={handleSendLink}>
              //     {getLanguageValue(
              //       PAGE_TYPE.SCHOOL,
              //       "studentEditRegister.txtSendLink"
              //     )}
              //   </button>
              <></>
            ) : (
              <button className="next-btn" onClick={handleSendLink} disabled={isButtonDisabled}>
                {getLanguageValue(
                  PAGE_TYPE.SCHOOL,
                  "studentNewRegister.txtSendLink"
                )}
              </button>
            )}
          </div>
          {id ? (
            <button onClick={handleDirectRegister}>
              {id
                ? getLanguageValue(
                    PAGE_TYPE.SCHOOL,
                    "studentEditRegister.txtDirectRegister"
                  )
                : getLanguageValue(
                    PAGE_TYPE.SCHOOL,
                    "studentNewRegister.txtDirectRegister"
                  )}
            </button>
          ) : null}
        </div>
      </div>
    </main>
  );
};
export default StudentNewRegisterComponent;
