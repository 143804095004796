import axios from "axios";
import { getAccessToken, getNewAccessTokenValue } from "helpers/Auth.helper";
import { platform } from "os";

const AUTH_API_URL = process.env.REACT_APP_AUTH_URL;
const languageCode = navigator.language;

const getAxios = () => {
    const headers: any = {
        accesskey: process.env.REACT_APP_AUTH_ACCESS_KEY,
        secretkey: process.env.REACT_APP_AUTH_SECRET_KEY,

        "language-code": languageCode,
    };

    const accessToken = getAccessToken();
    if (accessToken) {
        headers["Authorization"] = `Bearer ${accessToken}`;
    }

    return axios.create({ headers });
};

// 공통코드 요청
export const getAllCodeList = async () => {
    const url = "/app/code";

    return getAxios()
        .get(`${AUTH_API_URL}${url}`)
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data;
            }
        })
        .catch(errorHandler);
};

export const getTermList = async (params?: any) => {
    const url = "/app/term";

    return getAxios()
        .get(`${AUTH_API_URL}${url}`, { params })
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data;
            }
        })
        .catch(errorHandler);
};

export const getAgencyList = async (params?: any) => {
    const url = "/app/agency";

    return getAxios()
        .get(`${AUTH_API_URL}${url}`, { params })
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data;
            }
        })
        .catch(errorHandler);
};

export const getHomeTermList = async () => {
    const url = "/app/term/home";

    return getAxios()
        .get(`${AUTH_API_URL}${url}`)
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data;
            }
        })
        .catch(errorHandler);
};

export const getTermInfo = async (id: number) => {
    const url = `/app/term/info/${id}`;

    return getAxios()
        .get(`${AUTH_API_URL}${url}`)
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data;
            }
        })
        .catch(errorHandler);
};

export const getTermListByType = async (params?: any) => {
    const url = "/app/term/signup";

    return getAxios()
        .get(`${AUTH_API_URL}${url}`, { params })
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data;
            }
        })
        .catch(errorHandler);
};

export const signupByEmail = async (params: any) => {
    const url = "/auth/email/signup";

    return getAxios()
        .post(`${AUTH_API_URL}${url}`, params)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

export const schoolSignupByEmail = async (params: any) => {
    const url = "/auth/email/school/signup";

    return getAxios()
        .post(`${AUTH_API_URL}${url}`, params)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

export const createInfo = async (accountType: string, params: any) => {
    const url = `/my-info/${accountType}`;

    return getAxios()
        .post(`${AUTH_API_URL}${url}`, params)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

export const createStudentInfoBySchool = async (accountType: string, params: any) => {
    const url = `/my-info/school/${accountType}`;

    return getAxios()
        .post(`${AUTH_API_URL}${url}`, params)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

export const login = async (params: any) => {
    const url = "/auth/email/login";

    return getAxios()
        .post(`${AUTH_API_URL}${url}`, params)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

export const getMyInfo = async () => {
    const url = "/my-info";

    return getAxios()
        .get(`${AUTH_API_URL}${url}`)
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data;
            }
        })
        .catch(errorHandler);
};

export const checkPassword = async (params: any) => {
    const url = "/my-info/check-password";

    return getAxios()
        .post(`${AUTH_API_URL}${url}`, params)
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data.result;
            }
        })
        .catch(errorHandler);
};

export const updatePassword = async (params: any) => {
    const url = "/my-info/password";

    return getAxios()
        .put(`${AUTH_API_URL}${url}`, params)
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data.result;
            }
        })
        .catch(errorHandler);
};

export const updateEmail = async (params: any) => {
    const url = "/my-info/username";

    return getAxios()
        .put(`${AUTH_API_URL}${url}`, params)
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data.result;
            }
        })
        .catch(errorHandler);
};

export const deleteAccount = async (params: any) => {
    const url = "/my-info/withdrawal";

    return getAxios()
        .delete(`${AUTH_API_URL}${url}`, { params })
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data.result;
            }
        })
        .catch(errorHandler);
};

export const deleteAccountBySchool = async (params: any) => {
    const url = "/auth/email/withdrawal";

    return getAxios()
        .delete(`${AUTH_API_URL}${url}`, { params })
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

export const checkExistsSlug = async (params: any) => {
    const url = `/my-info/student/slug/exists`;

    return getAxios()
        .get(`${AUTH_API_URL}${url}`, { params })
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data.result;
            }
        })
        .catch(errorHandler);
};

export const verifyEmail = async (code: string) => {
    const url = "/verification/email/signup";

    return getAxios()
        .get(`${AUTH_API_URL}${url}/${code}`)
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data.result;
            }
        })
        .catch(errorHandler);
};

export const verifyParentEmail = async (code: string) => {
    const url = "/verification/email/parent";

    return getAxios()
        .get(`${AUTH_API_URL}${url}/${code}`)
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data;
            }
        })
        .catch(errorHandler);
};

export const verifyParentEmailAndSaveParentInfo = async (code: string, params: any) => {
    const url = "/verification/email/parent";

    return getAxios()
        .put(`${AUTH_API_URL}${url}/${code}`, params)
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data;
            }
        })
        .catch(errorHandler);
}; 

export const sendVerifyEmail = async (params: any) => {
    const url = "/auth/email/send/email-signup";

    return getAxios()
        .post(`${AUTH_API_URL}${url}`, params)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

export const sendVerifyParentEmail = async (params: any) => {
    const url = "/auth/email/send/email-parent";

    return getAxios()
        .post(`${AUTH_API_URL}${url}`, params)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

export const sendEmailSignUp = async (params: any) => {
    const url = "/auth/email/send/signup";

    return getAxios()
        .post(`${AUTH_API_URL}${url}`, params)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

export const sendEmailForEmailUpdate = async (params: any) => {
    const url = "/auth/email/send/email-update";

    return getAxios()
        .post(`${AUTH_API_URL}${url}`, params)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

export const createFcmTokenInfo = async (params: any) => {
    const url = "/auth/token/notification";
    return getAxios()
        .post(`${AUTH_API_URL}${url}`, params)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

export const deleteFcmTokenInfo = async (params: any) => {
    const url = "/auth/token/notification";
    console.log(params);
    return getAxios()
        .delete(`${AUTH_API_URL}${url}`, { params })
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

export const sendEmailForResetPassword = async (params: any) => {
    const url = "/verification/email/reset-password";

    return getAxios()
        .post(`${AUTH_API_URL}${url}`, params)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

export const verifyResetPasswordEmail = async (token: string) => {
    const url = "/verification/email/reset-password";

    return getAxios()
        .get(`${AUTH_API_URL}${url}/${token}`)
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data.result;
            }
        })
        .catch(errorHandler);
};

export const verifyResetPassword = async (params: any) => {
    const url = "/verification/email/reset-password";

    return getAxios()
        .put(`${AUTH_API_URL}${url}`, params)
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data.result;
            }
        })
        .catch(errorHandler);
};

export const updateStudentContactInfo = async (params: any) => {
    const url = "/my-info/student/contact";

    return getAxios()
        .put(`${AUTH_API_URL}${url}`, params)
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data.result;
            }
        })
        .catch(errorHandler);
};

export const saveAppPushInfo = async () => {
    const deviceUuid = await (window as any).flutter_inappwebview.callHandler("getDeviceId");
    const token = await (window as any).flutter_inappwebview.callHandler("getPushToken");

    await createFcmTokenInfo({ deviceUuid, token, platform: "app" });
};

// push 활성화 여부 확인
export const getAppPushActivatedInfo = async () => {
    console.log("Auth.service:getAppPushActivatedInfo");
    const deviceUuid = await (window as any).flutter_inappwebview.callHandler("getDeviceId");
    const url = `/auth/token/notification/is-activated/${deviceUuid}`;

    return getAxios()
        .get(`${AUTH_API_URL}${url}`)
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data;
            }
        })
        .catch(errorHandler);
};

// push 활성화 업데이트
export const setPushActivate = async (params: any) => {
    console.log("Auth.service:setPushActivate");
    const deviceUuid = await (window as any).flutter_inappwebview.callHandler("getDeviceId");
    const url = `/auth/token/notification/is-activated/${deviceUuid}`;

    return getAxios()
        .patch(`${AUTH_API_URL}${url}`, params)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

export const sendEmailForAgencyVeification = async (params: any) => {
    const url = "/verification/email/agency";

    return getAxios()
        .post(`${AUTH_API_URL}${url}`, params)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

export const verifyAgencyEmail = async (params: any) => {
    const url = "/verification/email/agency";

    return getAxios()
        .put(`${AUTH_API_URL}${url}`, params)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

export const getNewAccessToken = async (params: any) => {
    const url = "/auth/email/refresh-token";

    return getAxios()
        .post(`${AUTH_API_URL}${url}`, params)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

const errorHandler = async ({ response }: any) => {
    console.log(response);
    if (response?.status === 401) {
        try {
            // 토큰 갱신
            const newAccessToken = await getNewAccessTokenValue();

            if (newAccessToken) {
                // 기존 요청 정보 가져오기
                const config = response.config;
                config.headers["Authorization"] = `Bearer ${newAccessToken}`;
                config.headers["Accesskey"] = process.env.REACT_APP_AUTH_ACCESS_KEY;
                config.headers["Secretkey"] = process.env.REACT_APP_AUTH_SECRET_KEY;
                config.headers["Language-Code"] = languageCode;

                const _axios = getAxios()

                const retry = await _axios(config);

                // 갱신된 토큰으로 API 재호출
                if (config.method === 'get') {
                    return retry.data.data;
                }

                return _axios(config);
            }
        } catch (refreshError) {
            console.error("Error during token refresh:", refreshError);
            throw refreshError; // Refresh 실패 시 오류 전달
        }
    } else {
        alert(response.data.message);
    }
};
