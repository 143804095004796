import React, { useState } from "react";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import * as AuthApi from "api/common/Auth.api";

import CheckPasswordComponent from "./CheckPassword.component";

const ContactContainer = (props: any) => {
  const { setIsCheckedPassword } = props;
  const [password, setPassword] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const checkPassword = async () => {
    setIsButtonDisabled(true)
    const result = await AuthApi.checkPassword({ password });

    if (result?.status === 200) {
      setIsCheckedPassword(true);
    } else {
      alert(
        getLanguageValue(PAGE_TYPE.COMMON, "checkPassword.errPasswordMatch")
      );
    }
    setIsButtonDisabled(false)
  };

  return (
    <CheckPasswordComponent
      password={password}
      setPassword={setPassword}
      onClickBtnCheckPassword={checkPassword}
      isButtonDisabled={isButtonDisabled}
    />
  );
};

export default ContactContainer;
