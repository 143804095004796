import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

// import * as commonData from "data/common.data";
// import { getCurrentLangName } from "helpers/String.helper";

const DeleteAccountComponent = (props: any) => {
    const navigate = useNavigate();
    const location = useLocation();

    const {
        onClickBtnDeleteAccount,
        // setWithdrawalReasonCode,
        setWithdrawalOtherReason,
        isButtonDisabled,
    } = props;

    return (
        <main className="delete-account">
            <div className="con">
                <h2>DELETE ACCOUNT</h2>
                <div className="wrap">
                    <div className="text">
                        <strong>{getLanguageValue(PAGE_TYPE.COMMON, "deleteAccount.txtDeleteAccount1")}</strong>
                        <p>・{getLanguageValue(PAGE_TYPE.COMMON, "deleteAccount.txtDeleteAccount2")}</p>
                        <p>・{getLanguageValue(PAGE_TYPE.COMMON, "deleteAccount.txtDeleteAccount3")}</p>
                    </div>
                    {location.pathname.includes("student") ? (
                        <form>
                            {/* {commonData
                .getCommonCodeListByParent("withdrawalReason")
                .map((item: any, idx: number) => (
                  <div className="input-box" key={`reason-${idx}`}>
                    <label>
                      <input
                        type="radio"
                        value={item.code}
                        onChange={(evt) =>
                          setWithdrawalReasonCode(evt.target.value)
                        }
                        name="report-reason"
                      />
                      {getCurrentLangName(item.names)}
                    </label>
                  </div>
                ))} */}
                            <textarea
                                onChange={(evt) => setWithdrawalOtherReason(evt.target.value)}
                                placeholder={getLanguageValue(PAGE_TYPE.COMMON, "deleteAccount.txtEnterReason")}></textarea>
                        </form>
                    ) : null}
                    <div className="btn">
                        <div className="cancel-btn">
                            <button onClick={(evt) => navigate(location.pathname.replace("/delete-account", ""))}>
                                {getLanguageValue(PAGE_TYPE.COMMON, "deleteAccount.btnCancel")}
                            </button>
                        </div>
                        <div className="delete-btn">
                            <button disabled={isButtonDisabled} onClick={(evt) => onClickBtnDeleteAccount()}>
                                {getLanguageValue(PAGE_TYPE.COMMON, "deleteAccount.btnDelete")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default DeleteAccountComponent;
